import { FloorRoomModel } from "../models/floor-room-area.model";

export const RoomsData: FloorRoomModel[] = [
    {
        roomId: 201,
        roomName: 201,
        roomArea: {
            x1: 0, y1: 0,
            x2: 124.45, y2: 90.05
        },
        lightStatus: true
    },
    {
        roomId: 202,
        roomName: 202,
        roomArea: {
            x1: 199.49, y1: 0,
            x2: 323.94, y2: 90.05
        },
        lightStatus: false
    },
    {
        roomId: 203,
        roomName: 203,
        roomArea: {
            x1: 0, y1: 90.05,
            x2: 124.45, y2: 180.10
        },
        lightStatus: false
    },
    {
        roomId: 204,
        roomName: 204,
        roomArea: {
            x1: 199.49, y1: 90.05,
            x2: 323.94, y2: 180.10
        },
        lightStatus: false
    },
    {
        roomId: 205,
        roomName: 205,
        roomArea: {
            x1: 0, y1: 180.10,
            x2: 124.45, y2: 270.14
        },
        lightStatus: true
    },
    {
        roomId: 206,
        roomName: 206,
        roomArea: {
            x1: 199.49, y1: 180.10,
            x2: 323.94, y2: 270.14
        },
        lightStatus: true
    },
    {
        roomId: 207,
        roomName: 207,
        roomArea: {
            x1: 0, y1: 273.39,
            x2: 124.45, y2: 273.39 + 90.05
        },
        lightStatus: false
    },
    {
        roomId: 208,
        roomName: 208,
        roomArea: {
            x1: 199.49, y1: 273.39,
            x2: 323.94, y2: 273.39 + 90.05
        },
        lightStatus: false
    },
    {
        roomId: 209,
        roomName: 209,
        roomArea: {
            x1: 0, y1: 363.44,
            x2: 124.45, y2: 363.44 + 90.05
        },
        lightStatus: true
    },
    {
        roomId: 210,
        roomName: 210,
        roomArea: {
            x1: 199.49, y1: 363.44,
            x2: 323.94, y2: 363.44 + 77.21
        },
        lightStatus: false
    },
    {
        roomId: 1,
        roomName: 'Rec Room (North-East)',
        roomArea: {
            x1: 0, y1: 450.49,
            x2: 124.45, y2: 663.67
        },
        lightStatus: true
    },
    {
        roomId: 2,
        roomName: 'Shower Room (North-East)',
        roomArea: {
            x1: 199.26, y1: 437.65,
            x2: 323.73, y2: 562.1
        },
        lightStatus: false
    },
    {
        roomId: 3,
        roomName: 'Bathroom (North-East)',
        roomArea: {
            x1: 323.73, y1: 437.65,
            x2: 445.95, y2: 562.1
        },
        lightStatus: false
    },
    {
        roomId: 4,
        roomName: 'Nurses Station (North-East)',
        roomArea: {
            x1: 199.28, y1: 637.46,
            x2: 323.73, y2: 761.91
        },
        lightStatus: true
    },
    {
        roomId: 5,
        roomName: 'Office 1 (North-East)',
        roomArea: {
            x1: 323.73, y1: 637.46,
            x2: 447.06, y2: 761.91
        },
        lightStatus: false
    },
    {
        roomId: 6,
        roomName: 'Office 2 (North-East)',
        roomArea: {
            x1: 447.06, y1: 637.46,
            x2: 571.51, y2: 761.91
        },
        lightStatus: true
    },
    {
        roomId: 7,
        roomName: 'Office 3 (North-East)',
        roomArea: {
            x1: 571.51, y1: 637.46,
            x2: 695.96, y2: 761.91
        },
        lightStatus: false
    },
    {
        roomId: 8,
        roomName: 'Storage 1 (center)',
        roomArea: {
            x1: 695.96, y1: 637.46,
            x2: 859.96, y2: 761.91
        },
        lightStatus: false
    },
    {
        roomId: 9,
        roomName: 'Office 1 (center)',
        roomArea: {
            x1: 708.06, y1: 440.65,
            x2: 801.98, y2: 565.1
        },
        lightStatus: true
    },
    {
        roomId: 10,
        roomName: 'Office 2 (center)',
        roomArea: {
            x1: 859.96, y1: 637.46,
            x2: 966.11, y2: 761.91
        },
        lightStatus: true
    },
    {
        roomId: 11,
        roomName: 'Storage 2 (center)',
        roomArea: {
            x1: 801.98, y1: 440.65,
            x2: 891.9, y2: 565.1
        },
        lightStatus: false
    },
    {
        roomId: 211,
        roomName: 211,
        roomArea: {
            x1: 0, y1: 673.8,
            x2: 124.45, y2: 673.8 + 90.05
        },
        lightStatus: true
    },
    {
        roomId: 213,
        roomName: 213,
        roomArea: {
            x1: 0, y1: 763.63,
            x2: 124.45, y2: 763.63 + 90.05
        },
        lightStatus: false
    },
    {
        roomId: 214,
        roomName: 214,
        roomArea: {
            x1: 199.28, y1: 774.59,
            x2: 323.73, y2: 774.59 + 88.27
        },
        lightStatus: false
    },
    {
        roomId: 215,
        roomName: 215,
        roomArea: {
            x1: 0, y1: 858.34,
            x2: 124.45, y2: 858.34 + 90.05
        },
        lightStatus: false
    },
    {
        roomId: 216,
        roomName: 216,
        roomArea: {
            x1: 199.28, y1: 864.53,
            x2: 323.73, y2: 864.53 + 88.27
        },
        lightStatus: false
    },
    {
        roomId: 217,
        roomName: 217,
        roomArea: {
            x1: 0, y1: 955.3,
            x2: 124.45, y2: 955.3 + 90.04
        },
        lightStatus: false
    },
    {
        roomId: 218,
        roomName: 218,
        roomArea: {
            x1: 199.28, y1: 955.7,
            x2: 323.73, y2: 955.7 + 88.27
        },
        lightStatus: false
    },
    {
        roomId: 219,
        roomName: 219,
        roomArea: {
            x1: 0, y1: 1045.25,
            x2: 124.45, y2: 1045.25 + 132.73
        },
        lightStatus: false
    },
    {
        roomId: 220,
        roomName: 220,
        roomArea: {
            x1: 199.28, y1: 1045.88,
            x2: 323.73, y2: 1045.88 + 130.1
        },
        lightStatus: false
    },
    {
        roomId: 12,
        roomName: 'Office big (South West)',
        roomArea: {
            x1: 1083.58, y1: 621.83,
            x2: 1325.16, y2: 764.9
        },
        lightStatus: true
    },
    {
        roomId: 221,
        roomName: 221,
        roomArea: {
            x1: 901.89, y1: 440.65,
            x2: 990.81, y2: 568.1
        },
        lightStatus: false
    },
    {
        roomId: 222,
        roomName: 222,
        roomArea: {
            x1: 990.81, y1: 440.65,
            x2: 1081.72, y2: 568.1
        },
        lightStatus: false
    },
    {
        roomId: 223,
        roomName: 223,
        roomArea: {
            x1: 1081.72, y1: 440.65,
            x2: 1171.46, y2: 568.1
        },
        lightStatus: false
    },
    {
        roomId: 224,
        roomName: 224,
        roomArea: {
            x1: 1171.46, y1: 440.65,
            x2: 1265.19, y2: 568.1
        },
        lightStatus: true
    },
    {
        roomId: 13,
        roomName: 'Nurses Station (South)',
        roomArea: {
            x1: 1251.19, y1: 440.65,
            x2: 1449.6, y2: 568.1
        },
        lightStatus: true
    },
    {
        roomId: 14,
        roomName: 'Rest Room (South-West)',
        roomArea: {
            x1: 1325.16, y1: 621.83,
            x2: 1449.61, y2: 711.69
        },
        lightStatus: false
    },
    {
        roomId: 225,
        roomName: 225,
        roomArea: {
            x1: 1342.16, y1: 0,
            x2: 1466.61, y2: 90.05
        },
        lightStatus: false
    },
    {
        roomId: 226,
        roomName: 226,
        roomArea: {
            x1: 1543.64, y1: 0,
            x2: 1668.09, y2: 90.05
        },
        lightStatus: false
    },
    {
        roomId: 227,
        roomName: 227,
        roomArea: {
            x1: 1345.16, y1: 93.29,
            x2: 1469.61, y2: 183.34
        },
        lightStatus: false
    },
    {
        roomId: 228,
        roomName: 228,
        roomArea: {
            x1: 1545.64, y1: 93.29,
            x2: 1669.09, y2: 183.34
        },
        lightStatus: false
    },
    {
        roomId: 229,
        roomName: 229,
        roomArea: {
            x1: 1345.16, y1: 183.34,
            x2: 1469.61, y2: 273.39
        },
        lightStatus: false
    },
    {
        roomId: 230,
        roomName: 230,
        roomArea: {
            x1: 1545.64, y1: 183.34,
            x2: 1669.09, y2: 273.39
        },
        lightStatus: false
    },
    {
        roomId: 231,
        roomName: 231,
        roomArea: {
            x1: 1345.16, y1: 273.39,
            x2: 1469.61, y2: 363.44
        },
        lightStatus: false
    },
    {
        roomId: 232,
        roomName: 232,
        roomArea: {
            x1: 1545.64, y1: 273.39,
            x2: 1669.09, y2: 363.44
        },
        lightStatus: false
    },
    {
        roomId: 233,
        roomName: 233,
        roomArea: {
            x1: 1345.16, y1: 363.44,
            x2: 1469.61, y2: 440.65
        },
        lightStatus: false
    },
    {
        roomId: 234,
        roomName: 234,
        roomArea: {
            x1: 1545.64, y1: 363.44,
            x2: 1669.09, y2: 440.65
        },
        lightStatus: false
    },
    {
        roomId: 15,
        roomName: 'Shower Room (West)',
        roomArea: {
            x1: 1545.64, y1: 621.83,
            x2: 1669.09, y2: 711.69
        },
        lightStatus: false
    },
    {
        roomId: 235,
        roomName: 235,
        roomArea: {
            x1: 1345.16, y1: 721.69,
            x2: 1469.61, y2: 811.55
        },
        lightStatus: false
    },
    {
        roomId: 236,
        roomName: 236,
        roomArea: {
            x1: 1545.64, y1: 721.69,
            x2: 1669.09, y2: 811.55
        },
        lightStatus: true
    },
    {
        roomId: 237,
        roomName: 237,
        roomArea: {
            x1: 1345.16, y1: 811.55,
            x2: 1469.61, y2: 901.41
        },
        lightStatus: false
    },
    {
        roomId: 238,
        roomName: 238,
        roomArea: {
            x1: 1545.64, y1: 811.55,
            x2: 1669.09, y2: 901.41
        },
        lightStatus: false
    },
    {
        roomId: 239,
        roomName: 239,
        roomArea: {
            x1: 1345.16, y1: 901.41,
            x2: 1469.61, y2: 995.27
        },
        lightStatus: true
    },
    {
        roomId: 240,
        roomName: 240,
        roomArea: {
            x1: 1545.64, y1: 901.41,
            x2: 1669.09, y2: 995.27
        },
        lightStatus: true
    },
    {
        roomId: 241,
        roomName: 241,
        roomArea: {
            x1: 1345.16, y1: 995.27,
            x2: 1469.61, y2: 1091.13
        },
        lightStatus: false
    },
    {
        roomId: 242,
        roomName: 242,
        roomArea: {
            x1: 1545.16, y1: 995.27,
            x2: 1669.09, y2: 1091.13
        },
        lightStatus: false
    },
    {
        roomId: 17,
        roomName: 'IT Closet',
        roomArea: {
            x1: 1359.18, y1: 1091.13,
            x2: 1449.61, y2: 1158.98
        },
        lightStatus: false
    }
];