import { Color, ScaleType } from '@swimlane/ngx-charts';
import * as barColor from './bar-color.constant';
export const colorSchemeTemp: Color = {
  domain: [
    barColor.gTemp
  ],
  group: ScaleType.Time,
  selectable: true,
  name: 'Customer Usage Temp Chart',
};

export const colorSchemeLineChart: Color = {
  domain: [
    barColor.offline,
  ],
  group: ScaleType.Time,
  selectable: true,
  name: 'Customer Usage Line Chart',
};

export const colorSchemeBarChart: Color = {
  domain: [
    barColor.warning,
  ],
  group: ScaleType.Time,
  selectable: true,
  name: 'Customer Usage Bar Chart',
};

export const colorSchemeAreaChart: Color = {
  domain: [
    barColor.warning,
  ],
  group: ScaleType.Time,
  selectable: true,
  name: 'Customer Usage Area Chart',
};