import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  pageChange = new Subject<any>();
  reportsPageChange = new Subject<any>();
  private selectedFacility: Subject<FacilityModel> =
    new Subject<FacilityModel>();

  constructor(private storageService: StorageService) { }

  changeFacility(selectedFacility: FacilityModel) {
    this.selectedFacility.next(selectedFacility);
  }

  getFacility() {
    return this.selectedFacility.asObservable();
  }

  /**
   * Get current facility
   */
  get getCurrentFacility(): FacilityModel {
    return (
      this.storageService.getSessionStorage(
        StorageEnum.selectedFacility,
        true
      ) || new FacilityModel()
    );
  }

  /**
   * Get facility ID
   */
  getFacilityId(): string {
    return this.getCurrentFacility.facilityId;
  }

  /**
   * Get facility Name
   */
  getFacilityName(): string {
    return this.getCurrentFacility.facilityName;
  }

  /**
   * Get facility ID
   */
  getCasparSiteId(): string {
    return this.getCurrentFacility.caspar_siteid;
  }
}
