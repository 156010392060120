import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, MenuController } from '@ionic/angular';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import { StorageEnum } from '../../enums/storage.enum';
import { StorageService } from 'src/app/core/services/storage.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { Subscription } from 'rxjs';
import { FacilityService } from 'src/app/features/settings/facility-management/services/facility.service';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule],
})
export class HeaderComponent implements OnInit, OnDestroy {
  availableFacilities: any[] = [];
  selectedFacility: FacilityModel = new FacilityModel();
  @Output() onOpenSummary = new EventEmitter<any>();
  isSettingModalOpen: boolean = false;
  isMenuOpen: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private headerService: HeaderService,
    private menuCtrl: MenuController,
    private uiUtilityService: UiUtilityService,
    private facilityService: FacilityService
  ) { }

  ngOnInit() {
    const sortedFacilities = this.uiUtilityService.sortList(this.authService.getLoginData?.facility, 'facilityName');
    this.availableFacilities = sortedFacilities;
    if (this.availableFacilities?.length) {
      if (this.headerService.getCurrentFacility?.facilityId) {
        this.selectedFacility = this.headerService.getCurrentFacility;
      } else {
        this.selectedFacility = this.availableFacilities[0];
      }

      this.onFacilityChange();
    }
    this.subscriptions.push(
      this.uiUtilityService.isPageModalOpen.subscribe((isOpen: boolean) => {
        this.isSettingModalOpen = isOpen;
      })
    );
    this.subscriptions.push(
      this.uiUtilityService.isMenuOpen.subscribe((isOpen: boolean) => {
        this.isMenuOpen = isOpen;
      })
    );
    this.facilityService.facilityData.subscribe(() => {
      const sortedFacilities = this.uiUtilityService.sortList(this.authService.getLoginData?.facility, 'facilityName');
      this.availableFacilities = sortedFacilities;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  compareWith(o1: FacilityModel, o2: FacilityModel) {
    return o1 && o2 ? o1.facilityId === o2.facilityId : o1 === o2;
  }

  onFacilityChange() {
    this.storageService.setSessionStorage(
      StorageEnum.selectedFacility,
      this.selectedFacility,
      true
    );
    this.storageService.removeSessionStorage(StorageEnum.filteredGroups);
    this.headerService.changeFacility(this.selectedFacility);
  }

  toggleMenu() {
    this.menuCtrl.isOpen().then((isOpen) => {
      if (isOpen) {
        this.isMenuOpen = false;
        this.dismissNavModal();
      } else {
        this.isMenuOpen = true;
        this.menuCtrl.open();
      }
    });
  }

  dismissNavModal() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    } else {
      this.menuCtrl.close();
    }
  }

  openSummary() {
    this.onOpenSummary.emit();
  }
}
