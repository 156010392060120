<div class="population" [style.maxHeight.px]="expandHeight" [class.collapsed]="!expanded" (swipe)="onSwipePS($event)">
  <div class="population-header">
    <div id="modal-close">
      <ion-buttons slot="start" (click)="cancel()" class="modal-btn">
        <!-- <ion-button (click)="cancel()">Close</ion-button> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
          <path fill="#ccc"
            d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z" />
        </svg>
      </ion-buttons>
    </div>
    <div class="brand-logo">
      <img src="assets/svg/logo.svg" alt="Joerns Healthcare">
    </div>
    <!-- <ion-row class="web-logo">
      <ion-col class="ion-no-padding">
      </ion-col>
    </ion-row> -->
  </div>
  <div class="ps-container">
    <div>
      <!-- *ngIf="currentStatusSummary?.Residents?.length || currentStatusSummary?.Caregivers?.length" -->
      <div class="population-summary-scroll">
        <p class="card-caption">Population Summary</p>
        <ion-card class="ps-card-conatiner">
          <div class="chart-container" #pieContainerRef *ngIf="currentStatusSummary?.Residents?.length">
            <ion-row *ngIf="pieContainerRef.offsetWidth">
              <ion-col class="pie-chart-data ion-no-padding">
                <p class="pie-chart-sub-data-head">
                  <ion-icon class="pie-chart-sub-data-icon" src="assets/icon/wearer.svg" />
                  <span class="pie-chart-sub-data">Residents: {{residentsCount}}</span>
                </p>
                <ng-container *ngFor="let entry of currentStatusSummary?.Residents">
                  <p class="pie-chart-sub-data {{convertToKebabCase(entry.name)}}"> {{entry.name}}: {{entry.value}}</p>
                </ng-container>
              </ion-col>
              <ion-col class="ion-no-padding">
                <div class="total-val">{{residentsCount < 10 ? '0' + residentsCount : residentsCount}}</div>
                    <ngx-charts-pie-chart [view]="view" [customColors]="colorScheme"
                      [results]="currentStatusSummary?.Residents" [labels]="showLabels" [margins]="[10, 0, 10, 0]"
                      [animations]="false" [doughnut]="isDoughnut" [arcWidth]=".5" [maxLabelLength]="15">
                      <ng-template #tooltipTemplate let-model="model">
                        <p style="color: #4D4D4D; margin-bottom: -8px;">
                          {{model.name}} {{model.value}}
                        </p>
                      </ng-template>
                    </ngx-charts-pie-chart>
              </ion-col>
              <ion-col size="1">
                <div class="interactive" (click)="showPieChartInfo()">
                  <ion-icon class="summary-card-header-right-icon" [src]="'assets/icon/icons_Hint.svg'"></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </div>
          <ion-card-content *ngIf="!currentStatusSummary?.Residents?.length">
            No wearers found.
          </ion-card-content>
        </ion-card>
        <ion-card class="ps-card-conatiner" *ngIf="isCasparEnabled">
          <ion-card-content>
            <div class="alerts-header">
              <div class="alerts-header-left">
                <ion-icon class="icon" src="assets/icon/info-warning.svg" />
                <span class="header">Active Vital Alerts</span>
              </div>
              <div class="alerts-header-right">
                <p class="app-link" (click)="launchCaspar()">See more....</p>
              </div>
            </div>
            <div class="alerts-content" *ngIf="activeAlerts?.length">
              <ion-grid class="alerts-summary-table summary-table">
                <ion-row class="row row-head">
                  <ion-col class="col" size="4">Alert</ion-col>
                  <ion-col class="col" size="5">Time</ion-col>
                  <ion-col class="col" size="3">Wearer</ion-col>
                </ion-row>
                <ion-row class="row" *ngFor="let alert of activeAlerts">
                  <ion-col class="col alert-name" size="4">{{alert.displayName || 'NA'}}</ion-col>
                  <ion-col class="col" size="5">{{alert.alertTime}}</ion-col>
                  <ion-col class="col" size="3" [class.app-link]="alert.patient_id"
                    (click)="openWearerSummary(alert.wearerId)">{{alert.wearerName ||
                    'NA'}}</ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </ion-card-content>
          <ion-card-content class="p-t-0" *ngIf="!activeAlerts?.length">
            No alerts found.
          </ion-card-content>
        </ion-card>
        <ion-card class="ps-card-conatiner">
          <div class="chart-container" #pieContainerRef *ngIf="currentStatusSummary?.Caregivers?.length">
            <ion-row *ngIf="pieContainerRef.offsetWidth">
              <ion-col class="pie-chart-data ion-no-padding">
                <p class="pie-chart-sub-data-head">
                  <ion-icon class="pie-chart-sub-data-icon" src="assets/icon/Caregiver.svg" />
                  <span class="pie-chart-sub-data">Caregivers: {{caregiversCount}}</span>
                </p>
                <ng-container *ngFor="let entry of currentStatusSummary?.Caregivers">
                  <p class="pie-chart-sub-data {{convertToKebabCase(entry.name)}}"> {{entry.name}}: {{entry.value}}</p>
                </ng-container>
              </ion-col>
              <ion-col class="ion-no-padding">
                <div class="total-val">{{caregiversCount < 10 ? '0' + caregiversCount : caregiversCount}}</div>
                    <ngx-charts-pie-chart [view]="view" [customColors]="colorScheme"
                      [results]="currentStatusSummary?.Caregivers" [labels]="showLabels" [margins]="[10, 0, 10, 0]"
                      [animations]="false" [doughnut]="isDoughnut" [arcWidth]=".5" [maxLabelLength]="15">
                      <ng-template #tooltipTemplate let-model="model">
                        <p style="color: #4D4D4D; margin-bottom: -8px;">
                          {{model.name}}: {{model.value}}
                        </p>
                      </ng-template>
                    </ngx-charts-pie-chart>
              </ion-col>
              <ion-col size="1">
                <div class="interactive" (click)="showPieChartInfo()">
                  <ion-icon class="summary-card-header-right-icon" [src]="'assets/icon/icons_Hint.svg'"></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </div>
          <ion-card-content *ngIf="!currentStatusSummary?.Caregivers?.length">
            No caregivers found.
          </ion-card-content>
        </ion-card>
        <div>
          <p class="card-caption">Asset Summary</p>
          <ion-card class="ps-card-conatiner">
            <ion-card-header *ngIf="assetSummary.list?.length">
              <div class="summary-card-header">
                <div class="left-content">
                  <ion-icon class="summary-card-header-icon" [src]="'assets/icon/Assets.svg'" slot="start"></ion-icon>
                  <h6>Assets: {{assetSummary.total}}</h6>
                </div>
                <!-- <div>
                  <ion-icon class="summary-card-header-right-icon" [src]="'assets/icon/icons_Hint.svg'"></ion-icon>
                </div> -->
              </div>
            </ion-card-header>
            <ion-card-content *ngIf="assetSummary.list?.length">
              <ion-grid class="summary-table">
                <ion-row class="row row-head">
                  <ion-col class="col" size="3.5">Type</ion-col>
                  <ion-col class="col ion-text-center" size="2.5">In Use</ion-col>
                  <ion-col class="col ion-text-center" size="3">Available</ion-col>
                  <ion-col class="col ion-text-center" size="3">Status</ion-col>
                </ion-row>
                <ion-row class="row" *ngFor="let asset of assetSummary.list">
                  <ion-col class="col" size="3.5">{{asset.assetTypeName}}</ion-col>
                  <ion-col class="col ion-text-center" size="2.5">{{asset.inUse}}</ion-col>
                  <ion-col class="col ion-text-center" size="3">{{asset.available}}</ion-col>
                  <ion-col class="col ion-text-center" size="3"
                    [style.color]="asset.colorCode">{{asset.status}}</ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
            <ion-card-content *ngIf="!assetSummary.list?.length">
              <div>No assets found.</div>
            </ion-card-content>
          </ion-card>
        </div>
        <div>
          <p class="card-caption">Bed Summary</p>
          <ion-card class="ps-card-conatiner">
            <ion-card-header *ngIf="bedSummary.list?.length">
              <div class="summary-card-header">
                <div class="left-content">
                  <ion-icon class="summary-card-header-icon" [src]="'assets/icon/bed_transparent.svg'"
                    slot="start"></ion-icon>
                  <h6>Beds: {{bedSummary.total}}</h6>
                </div>
                <!-- <div>
                  <ion-icon class="summary-card-header-right-icon" [src]="'assets/icon/icons_Hint.svg'"></ion-icon>
                </div> -->
              </div>
            </ion-card-header>
            <ion-card-content *ngIf="bedSummary.list?.length">
              <ion-grid class="summary-table">
                <ion-row class="row row-head">
                  <ion-col class="col" size="3.5">Type</ion-col>
                  <ion-col class="col ion-text-center" size="2.5">In Use</ion-col>
                  <ion-col class="col ion-text-center" size="3">Available</ion-col>
                  <ion-col class="col ion-text-center" size="3">Status</ion-col>
                </ion-row>
                <ion-row class="row" *ngFor="let bed of bedSummary.list">
                  <ion-col class="col" size="3.5">{{bed.bedType}}</ion-col>
                  <ion-col class="col ion-text-center" size="2.5">{{bed.inUse}}</ion-col>
                  <ion-col class="col ion-text-center" size="3">{{bed.available}}</ion-col>
                  <ion-col class="col ion-text-center" size="3" [style.color]="bed.colorCode">{{bed.status}}</ion-col>
                </ion-row>
              </ion-grid>
            </ion-card-content>
            <ion-card-content *ngIf="!bedSummary.list?.length">
              <div>No beds found.</div>
            </ion-card-content>
          </ion-card>
        </div>


        <div>
          <p class="card-caption">30-Day Notable Event Chart</p>
          <ion-card class="ps-card-conatiner">
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col class="ion-no-margin filters">
                  <div class="filter-container">
                    <div class="filter-box">
                      <div class="filter-elem" *ngFor="let pgroup of populationGroup; index as i"
                        [ngClass]="{'active': pgroup === selectedPGroup}" (click)="toggleCharts(pgroup)">
                        {{ pgroup }}</div>
                    </div>
                  </div>
                  <!-- <ion-icon size="large" src="assets/svg/filter.svg" color="secondary"></ion-icon> -->
                </ion-col>
              </ion-row>
            </ion-grid>
            <div *ngIf="detectionChartData.length" class="chart-container bar-chartx" id="vartical-chart" #notableChart>
              <ng-container>
                <ngx-charts-bar-vertical #barChart [view]="[notableChart.offsetWidth, 120]" [roundEdges]="roundEdges"
                  [animations]="animations" [results]="detectionChartData" [noBarWhenZero]="false"
                  [scheme]="colorSchemeBarChart" [customColors]="barCustomColors" [gradient]="gradient"
                  [showYAxisLabel]="showYAxisLabel" [showDataLabel]="showDataLabel" [showXAxisLabel]="false"
                  [barPadding]="barPadding" [tooltipDisabled]="false" [xAxis]="false" [yScaleMin]="0" [yScaleMax]="100">
                  <ng-template #tooltipTemplate let-model="model">
                    <ng-container [ngSwitch]="model.value">
                      <p class="ps-p" *ngSwitchCase="''">{{model.name}}: No notable events detected</p>
                      <p class="ps-p" *ngSwitchCase="'1'">{{model.name}} <br> 1</p>
                      <p class="ps-p" *ngSwitchDefault>{{model.name}} <br> {{model.value || ''}}</p>
                    </ng-container>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container [ngSwitch]="model[0].value">
                      <p class="ps-p" *ngSwitchCase="''">{{model[0].name}}: No notable events detected</p>
                      <p class="ps-p" *ngSwitchCase="'1'">{{model[0].name}} <br> 1 </p>
                      <p class="ps-p" *ngSwitchDefault>{{model[0].name}} <br> {{model[0].value || ''}} </p>
                    </ng-container>
                  </ng-template>

                </ngx-charts-bar-vertical>

              </ng-container>
              <div class="xAxis-label">
                <div class="xaxis-tick first">{{startDate}}</div>
                <div class="xaxis-tick last">Today</div>
              </div>
            </div>
            <div class="no-data-notable" *ngIf="!detectionChartData.length"> No data found for the selected facility.
            </div>
          </ion-card>
        </div>
      </div>

    </div>
  </div>
  <div>

  </div>
  <div class="powered-by">
    <div class="footer-images">
      <div>
        <!-- <img src="assets/svg/settings.svg" /> -->
      </div>
      <div>
        <img src="assets/svg/powered_by.svg" />
      </div>
    </div>
    <div class="version-text">
      <ion-text><sub>Version: {{version}}</sub></ion-text>
    </div>
  </div>
</div>