export enum TitleEnum {
    main = ' | Gundersen Health',
    report = 'Reports',
    help = 'Help',
    adminTools = 'Admin Tools',
    version = 'Version',
    forbiddenAccess = 'Forbidden Access',
    about = 'About',
    dashboard = 'Dashboard',
    management = 'Management',
    wearer = 'Wearer',
    facility = 'Facility',
    userAccounts = 'User Accounts',
    forgot = 'Forgot',
    userid = 'User ID',
    password = 'Password',
    login = 'Sign In',
    reset = 'Reset',
    simulator = 'Simulator'
}