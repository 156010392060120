import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  AlertController,
  IonicModule,
  PopoverController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { Subscription, catchError, interval, of, startWith, switchMap } from 'rxjs';
import { FilterService } from 'src/app/core/services/filter.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { notableLocationStates } from 'src/app/shared/enums/location-states.enum';
import {
  DashboardData,
} from 'src/app/shared/models/dashboard.model';
import { WearerGroupModel } from 'src/app/shared/models/wearer-group.model';
import { REFRESH_INTERVAL } from '../../../shared/config/report.constant';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { META_ASSETS } from 'src/app/shared/config/app.constant';
import { HeaderService } from 'src/app/core/services/header.service';
import { CASPAR_ICON_ORDER } from 'src/app/shared/config/caspar.contant';

@Component({
  standalone: true,
  selector: 'app-wearer-list',
  templateUrl: './wearer-list.component.html',
  styleUrls: ['./wearer-list.component.scss'],
  imports: [CommonModule, IonicModule, NgxChartsModule],
})
export class WearerListComponent implements OnInit, OnDestroy {
  showSpinner = true;
  footerText: string = '';
  dashBoardData: DashboardData[] = [];
  pollingIntervalTime: number = REFRESH_INTERVAL;
  private subscriptions: Subscription[] = [];
  deviceId: any = null;
  readonly barPadding: number = 3.5;
  wearerGroups: WearerGroupModel[] = [];
  notableLocationStates = notableLocationStates;
  currentWearerId: any = null;
  sortType: any;
  selectedGroup: any;
  noMsg = 'No wearers or assets found in the selected facility.';
  private isAppInBackground = false;
  private intervalSubscription: Subscription | undefined;
  casparData: any = {};
  alertsIconOrder: { [key: string]: any } = CASPAR_ICON_ORDER;

  constructor(
    private dashboardService: DashboardService,
    public alertController: AlertController,
    private uiUtilityService: UiUtilityService,
    private filterService: FilterService,
    public popOverController: PopoverController,
    public modalController: ModalController,
    private headerService: HeaderService,
    private platform: Platform,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.startInterval();
    this.platform.pause.subscribe(() => {
      this.isAppInBackground = true;
      this.stopInterval();
    });
    this.platform.resume.subscribe(() => {
      this.isAppInBackground = false;
      this.ngZone.run(() => {
        this.startInterval();
      });
    });
    this.sortDashboard();
    this.filterDashboard();
    this.subscriptions.push(
      this.dashboardService.getCurrentWearer().subscribe((currentWearer) => {
        if (currentWearer) {
          this.currentWearerId = currentWearer.wearerId;
        } else {
          this.currentWearerId = null;
        }
      })
    );
    this.subscriptions.push(
      this.headerService.getFacility().subscribe(() => {
        this.resetPage();
      })
    );
    this.subscriptions.push(
      this.dashboardService.refreshDashboardWithData.subscribe((data: any) => {
        this.ngZone.run(() => {
          this.startInterval(data?.event);
        });
      })
    )
    this.subscriptions.push(
      this.dashboardService.getCasparDataSub.subscribe((data: any) => {
        this.casparData = data;
      })
    )
  }

  startInterval(event?: any) {
    if (this.isAppInBackground) {
      return;
    }
    this.stopInterval();
    this.intervalSubscription = interval(this.pollingIntervalTime)
      .pipe(
        startWith(0),
        switchMap(() => {
          this.showSpinner = true;
          return this.dashboardService.formDashboardData().pipe(
            catchError(err => {
              return of([]);
            })
          );
        })
      )
      .subscribe({
        next: () => {
          this.getData();
          if (event?.target?.complete) {
            event.target.complete();
          }
          this.dashboardService.notifyDashBoardRefresh();
        }, error: () => { }
      });
  }

  stopInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  resetPage(event?: any) {
    this.dashboardService.dashboardData = [];
    this.dashboardService.requestId = '';
    this.dashboardService.formDashboardData().subscribe({
      next: () => {
        this.getData();
        if (event?.target?.complete) {
          event.target.complete();
        }
        this.dashboardService.notifyDashBoardRefresh();
      }, error: () => {

      }
    });
  }

  /**
   * Sort dashboard data
   */
  sortDashboard() {
    this.subscriptions.push(
      this.filterService.getSortingType().subscribe((sortType) => {
        this.sortType = sortType;
        this.dashBoardData = this.dashboardService.sortDashboardData(
          this.dashBoardData,
          sortType
        );
      })
    );
  }

  /**
   * Filter dashboard data
   */
  filterDashboard() {
    // console.debug('filterDashboard');
    this.subscriptions.push(
      this.filterService
        .getFilteredWearerGroup()
        .subscribe((wearerGroups: WearerGroupModel[]) => {
          this.wearerGroups = wearerGroups;
          this.selectedGroup = wearerGroups.find((item: any) => item.isOn);
          const filterVal = this.selectedGroup?.name?.toLowerCase();
          if (this.selectedGroup?.filterValue === 'Assets') {
            this.noMsg = 'No assets found in the selected facility.';
          } else if (filterVal.includes('resident') || filterVal.includes('caregiver')) {
            this.noMsg = 'No wearers found in the selected facility.';
          } else {
            this.noMsg = 'No wearers or assets found in the selected facility.';
          }
          this.getData();
        })
    );
  }

  getData() {
    this.dashBoardData = this.dashboardService.filterDashboardData(
      this.wearerGroups,
      this.sortType
    );
    this.showSpinner = false;
  }

  ionViewWillLeave(): void {
    this.dashBoardData = [];
    this.uiUtilityService.closeModal();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  getUserName(data: DashboardData) {
    const isResident = (data.wearerGroupName?.toLowerCase() === 'resident' || data.wearerGroupName?.toLowerCase() === 'residents');
    if (isResident && data.localId) {
      return data.localId;
    }
    return data.displayName || data.localId;
  }

  async showWearerSummary(data: DashboardData, clickEvent: any) {
    if (!data.staticWearer) {
      this.currentWearerId = data.wearerId;
    } else {
      this.currentWearerId = null;
    }
    this.dashboardService.currentWearer.next(data);
    if (this.currentWearerId) {
      this.dashboardService.onEntityListClick.next(data);
    }
  }

  async showAssetSummary(data: DashboardData) {
    if (data.assetId) {
      this.dashboardService.onEntityListClick.next(data);
    }
  }

  showSummary(clickEvent: any, data: DashboardData) {
    clickEvent.stopPropagation();
    if (data.metaData === META_ASSETS) {
      this.showAssetSummary(data);
    } else {
      this.showWearerSummary(data, clickEvent);
    }
  }

  ngOnDestroy() {
    this.stopInterval();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
