import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  IonAccordionGroup,
  IonicModule,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { MenuItemModel } from '../../models/menu.model';
import { RouterEnum } from '../../enums/router.enum';
import { UserRolesEnum } from '../../enums/user-roles.enum';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { ModalParams, NavModalInput } from '../../models/modal-params.model';
import {
  enterAnimation,
  leaveAnimation,
} from '../../animations/modal-animation';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { WearerListPage } from 'src/app/features/wearer-management/pages/wearer-list/wearer-list.page';
import { AssetListPage } from 'src/app/features/asset-management/pages/asset-list/asset-list.page';
import { BedListPage } from 'src/app/features/bed-management/pages/bed-list/bed-list.page';
import { BandIntroPage } from 'src/app/features/band/pages/band-intro/band-intro.page';
import { CorporateAccountPage } from 'src/app/features/settings/corporate-account/corporate-account.page';
import { FacilityManagementPage } from 'src/app/features/settings/facility-management/facility-management.page';
import { UserAccountsPage } from 'src/app/features/settings/user-accounts/user-accounts.page';
import { RoomListPage } from 'src/app/features/settings/room-management/room-list/room-list.page';
import { AboutPage } from 'src/app/features/settings/about/about.page';
import { LoginPage } from 'src/app/features/user/pages/login/login.page';
import { AuthService } from 'src/app/core/services/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HelpPage } from 'src/app/features/help/help.page';
import { MAIN_MENU, MODAL_PAGE_TYPE, REPORTS_MENU, menuItemsReports } from '../../config/app.constant';
import { BridgeListPage } from 'src/app/features/bridge-management/pages/bridge-list/bridge-list.page';
import { BandPageFlowEnum } from '../../enums/band.enum';
import { EndPointsEnum } from '../../enums/end-points';
import { Subscription } from 'rxjs';
import { AddFacilityPage } from 'src/app/features/settings/facility-management/add-facility/add-facility.page';
import { HeaderService } from 'src/app/core/services/header.service';
import { CorporateListPage } from 'src/app/features/corporate-list/corporate-list.page';
import { NotificationSettingsPage } from 'src/app/features/notification-settings/notification-settings.page';
import { WearerReportsPage } from 'src/app/features/reports/wearer-reports/wearer-reports.page';
import { DashboardPage } from 'src/app/features/dashboard/dashboard.page';
import { ReportsPage } from 'src/app/features/reports/reports.page';
import { Capacitor } from '@capacitor/core';

@Component({
  standalone: true,
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  imports: [IonicModule, CommonModule, RouterModule],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @ViewChild('accordionGroup') accordionGroup: IonAccordionGroup | undefined;
  modalParams: NavModalInput = { rootPage: null, rootParam: null };
  private menuItemsMain: MenuItemModel[] = [
    {
      navigationUrl: '/wearer-list',
      name: 'Wearer Management',
      iconUrl: 'icons_Wearer.svg',
      confirm: false,
      component: WearerListPage,
      id: 'wearer-list',
      permission: 'getWearerList'
    },
    {
      navigationUrl: '/asset-list',
      name: 'Asset Management',
      iconUrl: 'icons_asset.svg',
      confirm: false,
      component: AssetListPage,
      id: 'asset-list',
      permission: 'getAssetList'
    },
    {
      navigationUrl: '/bed-list',
      name: 'Bed Management',
      iconUrl: 'icons_bed.svg',
      confirm: false,
      component: BedListPage,
      id: 'bed-list',
      permission: 'getBedList'
    },
    {
      navigationUrl: '/band-intro',
      name: 'Band/Tag Lookup',
      iconUrl: 'icons_Lookup.svg',
      confirm: false,
      component: BandIntroPage,
      id: 'band-intro',
      modalData: {
        flow: BandPageFlowEnum.BandLookup
      },
      permission: 'getBandLookup'
    },
    {
      navigationUrl: '',
      name: 'Settings',
      iconUrl: 'menu-settings.svg',
      id: 'settings',
      ignoreActive: true,
      subItems: [
        {
          navigationUrl: RouterEnum.corporateAccount,
          name: 'Corporate Account',
          iconUrl: 'menu-corporate.svg',
          desc: 'View and edit your corporate account information and settings',
          userRoles: [UserRolesEnum.Corporate_Admin],
          confirm: false,
          component: CorporateAccountPage,
          id: 'corporate-account',
          permission: EndPointsEnum.getCorpAccount
        },
        {
          navigationUrl: RouterEnum.facilityManagement,
          name: 'Facility Management',
          iconUrl: 'menu-facility.svg',
          desc: 'Add and edit your facilities and settings',
          userRoles: [
            UserRolesEnum.Corporate_Admin,
            UserRolesEnum.Facility_Admin,
          ],
          confirm: false,
          component: FacilityManagementPage,
          id: 'facility-management',
          permission: `get${EndPointsEnum.manageFacility}`
        },
        {
          navigationUrl: RouterEnum.userAccounts,
          name: 'User Accounts',
          iconUrl: 'menu-user.svg',
          desc: 'Add and edit the user accounts (this is NOT the Wearers who wear the ONDO Band)',
          userRoles: [
            UserRolesEnum.Corporate_Admin,
            UserRolesEnum.Facility_Admin,
          ],
          confirm: false,
          component: UserAccountsPage,
          id: 'user-accounts',
          permission: 'getUserList'
        },
        {
          navigationUrl: RouterEnum.bridgeList,
          name: 'Bridge Management',
          iconUrl: 'menu-bridge.svg',
          desc: 'Add, edit and troubleshoot your ONDO Bridges',
          userRoles: [
            UserRolesEnum.Corporate_Admin,
            UserRolesEnum.Facility_Admin,
          ],
          confirm: false,
          component: BridgeListPage,
          id: 'bridge-list',
          permission: 'getBridgeList'
        },
        {
          navigationUrl: RouterEnum.roomList,
          name: 'Room Management',
          iconUrl: 'menu-room.svg',
          desc: 'Add, edit and troubleshoot your ONDO Bridges',
          userRoles: [
            UserRolesEnum.Corporate_Admin,
            UserRolesEnum.Facility_Admin,
          ],
          confirm: false,
          component: RoomListPage,
          id: 'room-list',
          permission: 'getRoomList'
        },
        {
          navigationUrl: RouterEnum.notificationSettings,
          name: 'Notifications',
          iconUrl: 'menu-notifications.svg',
          desc: 'Notifications',
          confirm: false,
          component: NotificationSettingsPage,
          id: 'notification-settings'
        },
        {
          navigationUrl: RouterEnum.aboutOndo,
          name: 'About Joerns Healthcare',
          iconUrl: 'menu-joerns.svg',
          desc: 'View company info, legal documents, app version and  edit app settings',
          confirm: false,
          component: AboutPage,
          id: 'about',
        },
        {
          navigationUrl: RouterEnum.login,
          name: 'Sign Out',
          iconUrl: 'menu-signout.svg',
          desc: 'Sign out from the mobile app',
          confirm: true,
          component: LoginPage,
          id: 'logout',
          ignoreActive: true,
        },
      ],
    },
    {
      navigationUrl: '/help',
      name: 'Help',
      iconUrl: 'icons_Help.svg',
      confirm: false,
      component: HelpPage,
      id: 'help',
    },
  ];
  destroyRef = inject(DestroyRef);
  selectedMenuItem: string = '';
  menuItems: any[] = [];
  private subscriptions: Subscription[] = [];
  private reportsMenuItem = {
    navigationUrl: RouterEnum.reports,
    name: 'Reports',
    iconUrl: 'menu-reports.svg',
    desc: 'Reports',
    userRoles: [
      UserRolesEnum.Corporate_Admin,
      UserRolesEnum.Facility_Admin,
    ],
    confirm: false,
    component: ReportsPage,
    id: 'reports',
    defaultId: 'wearer-reports',
    permission: 'getReports'
  };
  private dashboardMenuItem = {
    navigationUrl: RouterEnum.dashboard,
    name: 'Dashboard',
    iconUrl: 'menu-dashboard.svg',
    desc: 'Dashboard',
    userRoles: [
      UserRolesEnum.Corporate_Admin,
      UserRolesEnum.Facility_Admin,
    ],
    confirm: false,
    component: DashboardPage,
    id: 'dashboard',
    defaultId: ''
  };
  footerMenuItem: any = this.reportsMenuItem;
  currentMenu: 'main' | 'reports' = MAIN_MENU;
  isReportsEnabled = false;
  isNative = Capacitor.isNativePlatform();

  constructor(
    public router: Router,
    private authService: AuthService,
    private platform: Platform,
    private uiUtilityService: UiUtilityService,
    public modalController: ModalController,
    private menuCrl: MenuController,
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  constructMenu() {
    const enabledMenuItems: any[] = [];
    const menuItemsGrp = this.currentMenu === REPORTS_MENU ? menuItemsReports : this.menuItemsMain;
    for (const menuItemBase of menuItemsGrp) {
      const menuItem = { ...menuItemBase };
      if (menuItem.subItems?.length) {
        menuItem.subItems = menuItem.subItems.filter((item: any) => {
          const permission = item.permission;
          if (!permission) {
            return true;
          }
          return this.authService.isFeatureEnabled(permission);
        });
      }
      if (menuItem.permission) {
        const enable = this.authService.isFeatureEnabled(menuItem.permission);
        if (enable) {
          enabledMenuItems.push(menuItem);
        }
      } else {
        enabledMenuItems.push(menuItem);
      }
    }
    if (this.uiUtilityService.isNativePlatform()) {
      this.menuItems = enabledMenuItems;
    } else {
      this.menuItems = enabledMenuItems.filter(
        (item: any) => item.id !== 'band-intro'
      );
    }
    this.isReportsEnabled = this.authService.isFeatureEnabled('getReports');
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const childRoute = this.getChild(this.activatedRoute);
        this.currentMenu = childRoute.snapshot.data['menu'] || MAIN_MENU;
        this.selectedMenuItem = childRoute.snapshot.data['defaultId'] || '';
        const isReports = this.currentMenu === REPORTS_MENU;
        this.footerMenuItem = isReports ? this.dashboardMenuItem : this.reportsMenuItem;
        this.constructMenu();
      } else {
        this.constructMenu();
      }
    });
    this.subscriptions.push(
      this.uiUtilityService.updateMenu.subscribe((isOpen: boolean) => {
        this.constructMenu();
      })
    );
    this.uiUtilityService.menuClicked
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pageData) => {
        if (pageData === null) {
          this.modalParams = { rootPage: null, rootParam: null };
          this.selectedMenuItem = '';
        }
        if (pageData?.pageType === MODAL_PAGE_TYPE) {
          this.menuCrl.open().then(() => {
            this.menuClick(pageData);
          });
        }
      });
    this.uiUtilityService.menuId.subscribe((id) => {
      this.selectedMenuItem = id;
      const nativeEl = this.accordionGroup;
      if (nativeEl) {
        const accMenuItems = this.menuItems.filter(
          (item: any) => item.subItems?.length
        );
        let accMenuItem: any;
        for (const accItem of accMenuItems) {
          const selected = accItem.subItems?.find(
            (item: any) => item.id === id
          );
          if (selected) {
            accMenuItem = accItem.id;
            break;
          }
        }
        if (accMenuItem && nativeEl.value !== accMenuItem) {
          nativeEl.value = accMenuItem;
        }
      }
    });
  }

  private getChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  menuClick(menuItem: MenuItemModel) {
    if (
      !menuItem.component ||
      this.modalParams.rootPage === menuItem.component
    ) {
      return;
    }
    if (!menuItem.confirm) {
      if (this.uiUtilityService.pageModal) {
        this.uiUtilityService.pageModal
          .dismiss()
          .then(() => this.openMenuControls(menuItem));
      } else {
        this.openMenuControls(menuItem);
      }
    } else {
      this.logout(RouterEnum.login);
    }
  }

  async openMenuControls(menuItem: any) {
    if (menuItem.id === this.selectedMenuItem) {
      return;
    }
    const userRole = this.authService.getUserRole;
    if (userRole === UserRolesEnum.Facility_Admin && menuItem.id === 'facility-management') {
      this.modalParams = {
        rootPage: AddFacilityPage,
        rootParam: { confirm: menuItem.confirm, facilityId: this.headerService.getFacilityId(), isRootPage: true },
      };
    } else {
      this.modalParams = {
        rootPage: menuItem.component,
        rootParam: { confirm: menuItem.confirm, ...menuItem.modalData },
      };
    }
    if (menuItem.navigate) {
      this.selectedMenuItem = menuItem.id;
      return;
    }
    this.uiUtilityService.menuClicked.next(this.modalParams);
    if (!this.uiUtilityService.pageModal) {
      const option: ModalParams = {
        component: BaseModalComponent,
        cssClass: 'unified-nav-modal',
        canDismiss: true,
        presentingElement: await this.modalController.getTop(),
        enterAnimation,
        leaveAnimation,
        data: this.modalParams,
        mode: 'ios',
      };
      this.uiUtilityService.pageModal = await this.uiUtilityService.createModal(
        option
      );
      if (!menuItem.ignoreActive) {
        this.selectedMenuItem = menuItem.id;
      }
      this.uiUtilityService.pageModal.onDidDismiss().then(() => {
        this.uiUtilityService.pageModal = null;
        this.uiUtilityService.menuClicked.next(null);
        this.uiUtilityService.isPageModalOpen.next(false);
      });
      this.uiUtilityService.isPageModalOpen.next(true);
      this.uiUtilityService.pageModal.present();
    }
  }

  logout(route: RouterEnum) {
    const header = 'Sign Out';
    const message = 'Are you sure you want to sign out?';
    const buttons = [
      {
        text: 'Cancel',
      },
      {
        text: 'Sign Out',
        handler: () => {
          this.authService.logout();
          this.dismiss();
          this.authService.clearStorage();
          this.router.navigate([route], { replaceUrl: true });
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.dismissMainMenu());
      this.uiUtilityService.pageModal = null;
      this.selectedMenuItem = '';
      this.uiUtilityService.isPageModalOpen.next(false);
    } else {
      this.dismissMainMenu();
    }
  }

  dismissMainMenu() {
    this.menuCrl.close();
  }

  onMenuOpen() {
    this.uiUtilityService.isMenuOpen.next(true);
  }

  onMenuClose() {
    this.uiUtilityService.isMenuOpen.next(false);
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
      this.uiUtilityService.pageModal = null;
      this.selectedMenuItem = '';
      this.uiUtilityService.isPageModalOpen.next(false);
    }
  }

  navToPage(menuItem: any) {
    this.dismissMainMenu();
    this.selectedMenuItem = menuItem.defaultId;
    if (menuItem.id === 'reports') {
      this.selectedMenuItem = menuItemsReports[0]?.id || '';
    }
    this.router.navigate([menuItem.navigationUrl], { replaceUrl: true });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
