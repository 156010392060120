import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import {
  defaultTempUnit,
  tempUnit,
  tempUnitIds,
} from 'src/app/shared/config/temp-unit.constant';
import {
  DashboardData,
  LastUpdatedTime,
} from 'src/app/shared/models/dashboard.model';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { colorScheme, dashboardWearerColorScheme } from 'src/app/shared/config/color-scheme.constant';
import { FloorRoomModel } from 'src/app/shared/models/floor-room-area.model';
import { RoomsData } from 'src/app/shared/data/rooms.data';
import { RoomSummaryComponent } from '../dashboard/room-summary/room-summary.component';
import { colorSchemeBarChart, colorSchemeAreaChart, colorSchemeLineChart } from 'src/app/shared/config/chart-color-scheme.constant';
import { RouterModule } from '@angular/router';
import { notableEventsStringParts } from 'src/app/shared/enums/notable-state-message';
import { SettingsService } from '../settings/services/settings.service';
import { WearerDetailsModel } from 'src/app/shared/models/wearer.model';
import { notableLocationStates } from 'src/app/shared/enums/location-states.enum';
import { bandStates } from 'src/app/shared/enums/band-states';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { MODAL_PAGE_TYPE } from 'src/app/shared/config/app.constant';
import { WearerDetailsPage } from '../wearer-management/pages/wearer-details/wearer-details.page';
import { AuthService } from 'src/app/core/services/auth.service';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { CasparFrameComponent } from 'src/app/shared/components/caspar-frame/caspar-frame.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { CasparService } from '../dashboard/services/caspar.service';
import { BED_IN, BED_OUT, CASPAR_ICON_ORDER } from 'src/app/shared/config/caspar.contant';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  standalone: true,
  selector: 'app-dashboard-wearer-summary',
  templateUrl: './dashboard-wearer-summary.component.html',
  styleUrls: ['./dashboard-wearer-summary.component.scss'],
  imports: [IonicModule, CommonModule, NgxChartsModule, RouterModule, AccessControlDirective],
})
export class DashboardWearerSummaryComponent implements OnInit, OnDestroy {

  barData: any[] = [];
  lastUpdatedTime: LastUpdatedTime = new LastUpdatedTime();
  readonly barPadding: number = 3.5;
  colorScheme = colorScheme;
  wearerColorSchema = dashboardWearerColorScheme;
  tempUnitId = defaultTempUnit;
  tempUnit = tempUnit;
  tempUnitIds = tempUnitIds;
  data: any = new DashboardData();
  activeRoom: boolean = false;
  roomAssignedToWearer: FloorRoomModel = new FloorRoomModel();
  detectionChartData: any = [];
  readonly showDataLabel = true;
  readonly showXAxisLabel = true;
  readonly showYAxisLabel = false;
  readonly showXAxis =
    true; /* By default, this will show all labels, will need some workaround to only show first and last*/
  readonly showYAxis = false;
  readonly animations = false;
  readonly roundEdges = false;
  readonly gradient = false;
  readonly showLegend = false;
  barCustomColors: any = [];
  colorSchemeLineChart = colorSchemeLineChart;
  colorSchemeBarChart = colorSchemeBarChart;
  colorSchemeAreaChart = colorSchemeAreaChart;
  threeColors = ['#FBB03B', '#1F6E88', 'red'];
  wearerNotableEvents: any;
  eventStrings = notableEventsStringParts;
  notableLocationStates = notableLocationStates;
  bandStates = bandStates
  notableEventsDetected = false;
  residentDetails: any;
  wearerDetails: WearerDetailsModel | null = null;
  bedName: any;
  bedErrorState = '#cccccc';
  wearerGroupName: string = '';
  isRoomSummaryEnabled = true;
  private subscriptions: Subscription[] = [];
  casparData: any = {};
  isActiveVitalAlerts = false;
  alertsIconOrder: { [key: string]: any } = CASPAR_ICON_ORDER;
  bedInType = BED_IN;
  bedOutType = BED_OUT;
  isResident = false;
  isAlertsPresent = false;
  isLoading = false;
  wearerAlerts: any[] = [];
  isCasparEnabled = false;

  constructor(
    public modalController: ModalController,
    public dashboardService: DashboardService,
    private navParams: NavParams,
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private authService: AuthService,
    private casparService: CasparService,
    private headerService: HeaderService
  ) { }

  getFormattedActiveAlerts(data: any) {
    const arr = Object.values(data);
    return arr.map((item: any) => Object.values(item)).flat().filter((item: any) => item.alert_id && (item.patient_id === this.data.wearerId));
  }

  ngOnInit() {
    const selectedFacility = this.headerService.getCurrentFacility;
    this.isCasparEnabled = selectedFacility.enableCaspar;
    const { data } = this.navParams;
    //this.data = data['data'];
    if (data['data']) {
      this.data = data['data'];
    } else {
      this.data = data;
    }
    if (this.data == undefined) {
      this.data = this.navParams.data;
    }
    this.tempUnitId = this.authService.getLoginData?.tempUnit;
    this.isResident = (this.data.wearerGroupName?.toLowerCase() === 'resident' || this.data.wearerGroupName?.toLowerCase() === 'residents');
    this.casparData = this.dashboardService.casparData;
    this.isActiveVitalAlerts = this.getFormattedActiveAlerts(this.casparData)?.length ? true : false;
    this.wearerAlerts = this.authService.getLoginData?.wearerAlertsMetadata;

    // if (this.data.graphDisplay?.length) {
    //   this.data.graphDisplay[0].valueInC = '34.8';
    //   this.data.graphDisplay[0].valueInF = '35.8';
    // }
    this.isRoomSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');

    this.detectionChartData = [
      { name: '1 AM', value: '3' },
      { name: '2 AM', value: '4' },
      { name: '3 AM', value: '4' },
      { name: '4 AM', value: '6' },
      { name: '5 AM', value: '3' },
      { name: '6 AM', value: '7' },
      { name: '7 AM', value: '3' },
      { name: '8 AM', value: '4' },
      { name: '9 AM', value: '9' },
      { name: '10 AM', value: '5' },
      { name: '11 AM', value: '4' },
      { name: '12 PM', value: '7' },
      { name: '1 PM', value: '3' },
      { name: '2 PM', value: '4' },
      { name: '3 PM', value: '9' },
      { name: '4 PM', value: '5' },
      { name: '5 PM', value: '4' },
      { name: '6 PM', value: '7' },
      { name: '7 PM', value: '3' },
      { name: '8 PM', value: '3' },
      { name: '9 PM', value: '4' },
      { name: '10 PM', value: '3' },
      { name: '11 PM', value: '5' },
      { name: '12 AM', value: '4' },
    ];
    this.lastUpdatedTime = this.dashboardService.convertLTSOffset(
      this.data.lastTempTime
    );
    this.roomAssignedToWearer =
      RoomsData.find((room) => room.roomName === this.data.roomName) ||
      new FloorRoomModel();
    this.activeRoom = this.roomAssignedToWearer != undefined;
    this.wearerGroupName = this.data.wearerGroupName?.replace(/s\s*$/, "");
    this.getWearerNotableEvents();
    this.getResidentDetails();

    this.subscriptions.push(
      this.dashboardService.getCasparDataSub.subscribe((data: any) => {
        this.casparData = data;
        this.isActiveVitalAlerts = this.getFormattedActiveAlerts(this.casparData)?.length ? true : false;
      })
    )
  }

  getTooltipText(time: any, temp: any) {
    const hourString = this.getTime(time) + this.getTemp(temp);
    return `<span>${hourString}</span>`;
  }

  getTrendTooltipText(bar: any) {
    return `${this.tempUnitId === 1 ? bar.valueInC : bar.valueInF}${this.tempUnitId === 1 ? '&deg;C' : '&deg;F'}`;
  }

  getTime(timeString: any, addColon = true) {
    // timeStamp: "09-01-2022-11:00 PM"
    if (timeString) {
      const times = timeString.split("-");
      const hourString = times[times.length - 1]
        .replace(":00 ", "")
        .replace(/^0*(.+)/, "$1");
      return `${hourString}${addColon ? ":" : ""} `;
    } else return "";
  }

  getTemp(temp: any) {
    // console.log(`!isNaN(+${ temp }) is ${ !isNaN(+temp) } `);
    return !isNaN(+temp) && +temp > 30
      ? `${parseFloat(temp).toFixed(1)}&#176;${this.tempUnit[this.tempUnitId]} `
      : " — ";
  }

  xAxisTickFormatting = (xLabel: any) => {
    const places = [1, 6, 12, 18, 24];
    const timeStamp = this.getTime(
      this.data.graphDisplay[xLabel - 1].timeStamp,
      false
    );
    const index = places.indexOf(+xLabel);
    if (index != -1) {
      return timeStamp;
    }
    return "";
  };

  dismiss() {
    this.uiUtilityService.dismissOverlays();
  }


  async showRoomSummary() {
    if (!this.isRoomSummaryEnabled) {
      return;
    }
    this.dismiss();
    // temp patch for common area info
    // if (+this.roomAssignedToWearer.roomId < 200) return;
    if (this.data.roomName) {
      this.roomAssignedToWearer.roomId = this.data.roomId;
      this.roomAssignedToWearer.roomName = this.data.roomName;
    }
    const roomSummaryPopup = await this.modalController.create({
      component: RoomSummaryComponent,
      componentProps: { ...this.data, ...this.roomAssignedToWearer },
      cssClass: 'room-summary-modals',
      mode: 'ios',
      showBackdrop: true,
      animated: false,
    });

    await roomSummaryPopup.present();
  }

  navigateToWearerDetailsPage() {
    this.dismiss();
    if (this.data.metaData == null) {
      // this.router.navigate([RouterEnum.wearerDetails, this.data.wearerId]);
      const menuItem = { component: WearerDetailsPage, id: 'wearer-list', confirm: false, pageType: MODAL_PAGE_TYPE, modalData: { wearerId: this.data.wearerId, isRootPage: true } };
      this.uiUtilityService.menuClicked.next(menuItem);
    } else if (this.data.metaData == 'Assets') {

    }
  }

  getWearerNotableEvents() {
    this.dashboardService.getWearerNotableEvents(this.data.wearerId).subscribe((res) => {
      if (res.success) {
        this.wearerNotableEvents = res.data.wearerNotableEvents;
        if (this.wearerNotableEvents.length > 0) {
          this.notableEventsDetected = true;
        }
      }
    });
  }

  getResidentDetails() {
    this.isLoading = true;
    this.settingsService.getWearerResidentDetails(this.data.wearerId).subscribe((response) => {
      this.isLoading = false;
      if (response.data) {
        this.wearerDetails = response.data;
        if (this.wearerDetails?.beds.length && this.wearerDetails.beds[0].bedName) {
          const bed = this.wearerDetails.beds[0];
          this.bedErrorState = bed.bedMetaData?.bedColor;
          this.bedName = this.wearerDetails.beds[0].bedName;
        }
        if (this.wearerDetails?.residentDetail?.alertSettings && this.wearerAlerts) {
          for (const alrt of this.wearerAlerts) {
            if (this.wearerDetails.residentDetail.alertSettings[alrt.key]) {
              this.isAlertsPresent = true;
            }
          }
        }
      }
    });
  }

  async showNotableStatMsg(bandData: any) {
    if (bandData.state === 7) {
      return;
    }
    this.uiUtilityService.showAlert(bandData.description, this.uiUtilityService.capitalize(bandData.stateMsg));
  }

  async showLocationStateInfo(location: any) {
    this.uiUtilityService.showAlert(location.description, this.uiUtilityService.capitalize(location.label));
  }

  getUserName(data: DashboardData) {
    const isResident = (data.wearerGroupName?.toLowerCase() === 'resident' || data.wearerGroupName?.toLowerCase() === 'residents');
    if (isResident && data.localId) {
      return data.localId;
    }
    return data.displayName || data.localId;
  }

  async launchCaspar(wearer?: any) {
    const iframeSrc = this.casparService.getCasparIFrameParams({ patientId: wearer?.patientId || this.data.wearerId });
    const modal = await this.modalController.create({
      component: CasparFrameComponent,
      componentProps: {
        iframeSrc
      },
    });
    return await modal.present();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}