import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ChangeDetectorRef, AfterContentChecked,
  NgZone
} from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { EMPTY, Subject, Subscription, catchError, debounceTime, fromEvent, interval, of, startWith, switchMap, takeUntil } from 'rxjs';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { RoomsData } from '../../../shared/data/rooms.data';
import { DashboardData } from '../../../shared/models/dashboard.model';
import { FloorRoomModel } from '../../../shared/models/floor-room-area.model';
import { RoomSummaryComponent } from '../room-summary/room-summary.component';
import { register } from 'swiper/element/bundle';
import { CommonModule } from '@angular/common';
import { HeaderService } from 'src/app/core/services/header.service';
import { WearerGroupEnum } from 'src/app/shared/enums/wearer-group.enum';
import { ContextMenuComponent } from 'src/app/shared/components/context-menu/context-menu.component';
import { DashboardWearerSummaryComponent } from '../../dashboard-wearer-summary/dashboard-wearer-summary.component';
import { DashboardAssetSummaryComponent } from '../dashboard-asset-summary/dashboard-asset-summary.component';
import { META_ASSETS, MOBILE_BREAKPOINT_WIDTH } from 'src/app/shared/config/app.constant';
import { locationStates } from 'src/app/shared/enums/location-states.enum';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { wearerStates } from 'src/app/shared/enums/wearer-states.enum';
import { notableColors } from 'src/app/shared/enums/color-classes';
import { MapMultiple } from './map-pins/floor-map-multiple.component';
import { MapResident } from './map-pins/floor-map-resident.component';
import { MapCaregiver } from './map-pins/floor-map-caregiver.component';
import { MapAsset } from './map-pins/floor-map-asset.component';
import { assetStates } from 'src/app/shared/enums/asset-states.enum';
import { REFRESH_INTERVAL } from 'src/app/shared/config/report.constant';
import { FilterService } from 'src/app/core/services/filter.service';

const MIN_ZOOM = 1;

register();
@Component({
  standalone: true,
  selector: 'app-floor-dashboard',
  templateUrl: './floor-dashboard.component.html',
  styleUrls: ['./floor-dashboard.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, MapMultiple, MapResident, MapCaregiver, MapAsset],
})
export class FloorDashboardComponent implements AfterViewInit, OnDestroy, AfterContentChecked {
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  @ViewChildren('plotElements') plotElements!: QueryList<ElementRef>;
  private mapZoomSubject = new Subject<any>();
  private unsubscribe$ = new Subject<void>();

  staticFloorID = 2;
  floorMaps: any[] = [];
  subscriptions: Subscription[] = [];
  summaryPopup: any = null;
  clickTriggeredFromCard = false;
  allWearers: DashboardData[] = [];
  windowResizeWidthRatio = 1;
  windowResizeHeightRatio = 1;
  bedPointImageHalf = 5;

  floorRooms: FloorRoomModel[] = JSON.parse(JSON.stringify(RoomsData));
  floorRoomsCopy: FloorRoomModel[] = JSON.parse(JSON.stringify(RoomsData));

  // Flag for checking the room summary popup is open or not.
  isRoomSummaryPopupActive = false;
  bedPoints: any[] = [];
  locationData: any = {};
  isZoomIn = false;
  isLocationPinsUpdated = false;
  pollingIntervalTime: number = REFRESH_INTERVAL;
  zoomScale = MIN_ZOOM;
  selectedGroup: any;
  isLocationDataInitiated = false;
  isLocationDataRefreshed = false;
  isPlotLocationDataInitiated = false;
  lastRefreshed = '';
  isLastRefreshLoading = false;
  private isAppInBackground = false;
  private intervalSubscription: Subscription | undefined;

  constructor(
    private dashboardService: DashboardService,
    public popoverController: PopoverController,
    private headerService: HeaderService,
    public modalController: ModalController,
    private uiUtilityService: UiUtilityService,
    private changeDetector: ChangeDetectorRef,
    private filterService: FilterService,
    private platform: Platform,
    private ngZone: NgZone
  ) { }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    this.startInterval(true);
    this.platform.pause.subscribe(() => {
      this.isAppInBackground = true;
      this.isLastRefreshLoading = false;
      this.isLocationDataRefreshed = false;
      this.stopInterval();
    });
    this.platform.resume.subscribe(() => {
      this.isAppInBackground = false;
      this.isLastRefreshLoading = false;
      this.ngZone.run(() => {
        this.startInterval(true);
      });
    });

    this.subscriptions.push(
      this.dashboardService.getCurrentWearer().subscribe((currentWearer) => {
        this.clickTriggeredFromCard = currentWearer ? true : false;
        if (currentWearer) {
          document
            .getElementById('wearerPin' + currentWearer.wearerId)
            ?.click();
        }
      })
    );

    // Calculate the resize offset of floor2 image on window resize on correct position.
    this.subscriptions.push(
      fromEvent(window, 'resize')
        .pipe(debounceTime(300))
        .subscribe(() => {
          // this.calculateFloorOffsets();
          this.calculateWindowResizeRatio();
        })
    );

    // this.didFacilityChange();

    this.subscriptions.push(this.headerService.getFacility().subscribe(() => {
      this.floorMaps = [];
      this.isZoomIn = false;
      this.dashboardService.isFloorPlanZoomIn = false;
      if (this.uiUtilityService.isMobileBreakpoint()) {
        this.dashboardService.requestId = '';
        this.dashboardService.formDashboardData().subscribe({
          next: () => {
            this.didFacilityChange();
            if (!this.isLocationDataInitiated) {
              this.isLocationDataInitiated = true;
              this.didFacilityChange();
            }
            setTimeout(() => {
              this.isLocationDataInitiated = false;
            }, 1000);
          }, error: () => {
            this.isLocationDataInitiated = false;
          }
        });
      }
    }));

    this.subscriptions.push(this.dashboardService.plotFloorDashboard.subscribe(() => {
      this.didPlot();
    }));

    this.subscriptions.push(this.dashboardService.onEntityListClick.subscribe((entity: any) => {
      if (this.isZoomIn) {
        this.toggleZoom();
        setTimeout(() => {
          this.didPlotEntityFromList(entity);
        }, 300);
      } else {
        this.didPlotEntityFromList(entity);
      }
    }));

    this.subscriptions.push(
      this.dashboardService.getDashBoardRefreshEvent().subscribe(() => {
        if (!this.isLocationDataRefreshed && !this.isLastRefreshLoading) {
          this.isLocationDataRefreshed = true;
          this.didFacilityChange(true);
        }
        setTimeout(() => {
          this.isLocationDataRefreshed = false;
        }, 1000);
      })
    );

    this.subscriptions.push(
      this.dashboardService.refreshDashboardWithData.subscribe((data: any) => {
        this.ngZone.run(() => {
          this.startInterval(data?.isFloorCmp);
        });
        if (this.uiUtilityService.isMobileBreakpoint() && data?.isFloorCmp) {
          this.didFacilityChange(true, data?.event);
        }
      })
    )

    this.mapZoomSubject
      .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.setZoomStatus(value);
      });

    this.filterLocationData();
  }

  startInterval(isFloorCmp = false) {
    if (this.isAppInBackground) {
      return;
    }
    this.stopInterval();
    this.intervalSubscription = interval(this.pollingIntervalTime).pipe(
      startWith(0),
      switchMap(() => {
        return this.uiUtilityService.isMobileBreakpoint() && isFloorCmp ? this.dashboardService.formDashboardData().pipe(
          catchError(() => {
            return of([]);
          })
        ) : EMPTY;
      })
    ).subscribe({
      next: (data: any) => {
        if (data) {
          this.dashboardService.notifyDashBoardRefresh();
        }
      }, error: () => { }
    })
  }

  stopInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  filterLocationData() {
    // console.debug('filterDashboard');
    this.subscriptions.push(
      this.filterService
        .getFilteredWearerGroup()
        .subscribe((wearerGroups: any[]) => {
          this.selectedGroup = wearerGroups.find((item: any) => item.isOn);
          if (Object.keys(this.locationData)?.length) {
            this.plotLocationData();
          }
        })
    );
  }

  didPlotEntityFromList(entity: any) {
    let entityId = entity.wearerId;
    let selectedEntity: any;
    let locPin: any;
    if (entity.metaData === META_ASSETS) {
      entityId = entity.assetId;
    }
    for (const floor of this.floorMaps) {
      if (!floor.locationPins?.length) {
        continue;
      }
      for (const locationPin of floor.locationPins) {
        const selected = locationPin.entities?.find(((item: any) => item.id === entityId));
        if (selected) {
          selectedEntity = selected;
          locPin = locationPin;
          break;
        }
      }
    }
    if (selectedEntity) {
      if (this.uiUtilityService.isMobileBreakpoint()) {
        this.dashboardService.onDashboardTabsSwitch.next(true);
        setTimeout(() => {
          this.didShowSummaryPopover(selectedEntity, locPin);
        }, 300);
      } else {
        this.didShowSummaryPopover(selectedEntity, locPin);
      }

    } else if (entity.metaData === META_ASSETS) {
      this.showAssetSummary(entity);
    } else {
      this.showWearerSummary(entity);
    }
  }

  didPlot() {
    this.plotBeds();
    this.plotLocationData();
    this.calculateWindowResizeRatio();
  }

  didFacilityChange(isRefresh = false, event?: any) {
    this.isLastRefreshLoading = true;
    this.getFloors(isRefresh, event);
  }

  getFloors(isRefresh: boolean, event?: any) {
    this.dashboardService.getFloorDataForDashBoard().subscribe({
      next: (res: any) => {
        if (res?.success && res.data?.floors) {
          const floorMaps = res.data.floors.filter((item: any) => item.floorPlanImage);
          this.floorMaps = floorMaps.map((item: any) => {
            const locPins = this.floorMaps.find((item1: any) => item1.floorId === item.floorId)?.locationPins;
            return isRefresh && locPins?.length ? { ...item, locationPins: locPins } : { ...item, locationPins: [] };
          });
          setTimeout(() => {
            this.swiperRef?.nativeElement.swiper.update();
          }, 100);
          this.plotBeds();
          this.calculateWindowResizeRatio();
          this.getLocationData();
          if (event?.target?.complete) {
            event.target.complete();
          }
        } else {
          this.isLastRefreshLoading = false;
        }
      },
      error: () => {
        this.isLastRefreshLoading = false;
      },
    });
  }

  getLocationData() {
    this.isLocationPinsUpdated = true;
    this.dashboardService.getLocationDataForFacility().subscribe({
      next: (res: any) => {
        this.isLastRefreshLoading = false;
        if (res.success) {
          this.lastRefreshed = this.dashboardService.getCurrentTimeInTimezone();
        }
        if (res.success && res.data && Object.keys(res.data)?.length) {
          this.locationData = res.data;
          this.plotLocationData(true);
        } else {
          this.clearLocationData();
        }
      }, error: () => {
        this.isLastRefreshLoading = false;
      }
    });
  }

  calculateWindowResizeRatio() {
    setTimeout(() => {
      const index = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
      const floor = this.floorMaps[index];
      if (floor) {
        const img = document.getElementById(`floor-img-${floor.floorId}`);
        if (img?.clientWidth && img?.clientHeight) {
          this.windowResizeWidthRatio = img.clientWidth / 300;
          this.windowResizeHeightRatio = img.clientHeight / 300;
        }
        if (img && img.clientWidth <= 480) {
          this.bedPointImageHalf = 5;
        } else if (img && img.clientWidth > 480 && img.clientWidth <= 768) {
          this.bedPointImageHalf = 6;
        } else {
          this.bedPointImageHalf = 7;
        }
      }
    }, 300);
  }

  setZoomStatus(isZoomedIn: boolean) {
    if (isZoomedIn) {
      this.isZoomIn = true;
      this.dashboardService.isFloorPlanZoomIn = true;
    } else {
      this.isZoomIn = false;
      this.dashboardService.isFloorPlanZoomIn = false;
    }
  }

  onZoomChange(ev: any) {
    if (ev?.detail?.length && Array.isArray(ev.detail)) {
      this.zoomScale = ev.detail[1];
      const isZoomedIn = this.zoomScale > 1;
      this.setZoomStatus(isZoomedIn);
    }
  }

  onDoubleClick(ev: any) {
    const isZoomedIn = this.swiperRef?.nativeElement.swiper?.zoom.scale > 1;
    this.setZoomStatus(isZoomedIn);
  }

  onWheel(wheelEvent: any) {
    wheelEvent.preventDefault();
    if (wheelEvent.wheelDelta > 0) {
      this.isZoomIn = true;
      this.dashboardService.isFloorPlanZoomIn = true;
      this.swiperRef?.nativeElement.swiper?.zoom.in();
    } else if (wheelEvent.wheelDelta < 0) {
      this.isZoomIn = false;
      this.dashboardService.isFloorPlanZoomIn = false;
      this.swiperRef?.nativeElement.swiper?.zoom.out();
    }
  }

  refreshMap() {
    if (this.isLastRefreshLoading) {
      return;
    }
    if (this.uiUtilityService.isMobileBreakpoint()) {
      this.isLastRefreshLoading = true;
      this.dashboardService.formDashboardData().subscribe({
        next: () => {
          this.didFacilityChange(true);
        }, error: () => {
          this.isLastRefreshLoading = false;
        }
      });
    } else {
      this.dashboardService.refreshDashboardWithData.next({});
    }
  }

  onSlideChanged(ev: any) {
    this.isZoomIn = false;
    this.dashboardService.isFloorPlanZoomIn = false;
    this.didPlot();
  }

  setFloorMapPinsClass(entity: any) {
    const wearerState = +entity.wearerState;
    const locationState = +entity.locationState;
    const isAsset = entity.metaData === META_ASSETS || entity.isTag;
    const className = { temp: 'offline-temp', loc: 'offline-location' };
    if (isAsset) {
      const bandState = +entity.bandState;
      const assetState = +entity.assetState;
      //  && locationState === locationStates.ok
      if (assetState === assetStates.online) {
        className.temp = 'ok-asset';
      } else if (bandState === wearerStates.lowBattery) {
        className.temp = 'caution-temp';
      }
    } else {
      if (wearerState === wearerStates.tempWarning || entity.isCasparNotableState) {
        className.temp = 'warning-temp';
      }
      if (wearerState === wearerStates.tempCaution || wearerState === wearerStates.tempIndeterminate) {
        className.temp = 'caution-temp';
      }
      if (wearerState === wearerStates.unworn) {

      }
      if (wearerState === wearerStates.healthy) {
        className.temp = 'ok-temp';
      }
    }
    if (locationState === locationStates.warning) {
      className.loc = 'warning-location';
    }
    if (locationState === locationStates.caution) {
      className.loc = 'caution-location';
    }
    //  || (entity.location && !entity.locationAlert)
    if (locationState === locationStates.ok || locationState === locationStates.locon) {
      className.loc = 'ok-location';
    }
    return className;
  }

  findEntityWithClass(entities: any, type: any, className: string) {
    return entities.find((item: any) => item[item.id]?.[type] === className);
  }

  getEntities(entities: any[], floorId: string) {
    let result: any;
    if (!floorId || !entities?.length) {
      return result;
    }
    const filteredEntities = entities.filter((item: any) => item.floorId === floorId);
    const temp = { wearer: 0, caregiver: 0, asset: 0, room: 0 };
    for (const entity of filteredEntities) {
      switch (entity.type) {
        case 'Room': {
          temp.room = temp.room + 1;
          entity.order = 1;
          entity.icon = 'assets/icon/room-location.svg';
          entity.iconColor = notableColors.stateless;
        }
          break;
        case WearerGroupEnum.resident: {
          temp.wearer = temp.wearer + 1;
          entity.order = 2;
          entity.icon = 'assets/icon/icons_resident.svg';
          const entities = this.dashboardService.dashboardData;
          const wearer: any = entities.find((item: any) => item.wearerId === entity.id);
          if (wearer) {
            entity.iconColor = wearer.entityIconColor;
            const res = this.setFloorMapPinsClass(wearer);
            entity[entity.id] = res;
            entity.label = wearer.shortDisplayName || wearer.displayName;
          }
        }
          break;
        case WearerGroupEnum.caregiver: {
          temp.caregiver = temp.caregiver + 1;
          entity.order = 3;
          entity.icon = 'assets/icon/icons_caregiver.svg';
          const entities = this.dashboardService.dashboardData;
          const wearer = entities.find((item: any) => item.wearerId === entity.id);
          if (wearer) {
            entity.iconColor = wearer.entityIconColor;
            const res = this.setFloorMapPinsClass(wearer);
            entity[entity.id] = res;
            entity.label = wearer.shortDisplayName || wearer.displayName;
          }
        }
          break;
        case WearerGroupEnum.asset: {
          temp.asset = temp.asset + 1;
          entity.order = 4;
          entity.icon = 'assets/icon/icons_asset.svg';
          const entities = this.dashboardService.dashboardData;
          const asset = entities.find((item: any) => item.metaData === META_ASSETS && item.assetId === entity.id);
          if (asset) {
            entity.iconColor = asset.entityIconColor;
            const res = this.setFloorMapPinsClass(asset);
            entity[entity.id] = res;
            entity.label = asset.displayName;
          }
        }
          break;
        default:
      }
    }
    let multiple = 0;
    if (temp.wearer) {
      multiple = multiple + 1;
    }
    if (temp.caregiver) {
      multiple = multiple + 1;
    }
    if (temp.asset) {
      multiple = multiple + 1;
    }
    if (multiple > 1 || temp.wearer > 1 || temp.caregiver > 1 || temp.asset > 1) {
      let loc = '';
      let tem = '';
      const warningLoc = this.findEntityWithClass(filteredEntities, 'loc', 'warning-location');
      if (warningLoc) {
        loc = 'warning-location';
      }
      if (loc === '') {
        const cautionLoc = this.findEntityWithClass(filteredEntities, 'loc', 'caution-location');
        if (cautionLoc) {
          loc = 'caution-location';
        }
      }
      if (loc === '') {
        const okLoc = this.findEntityWithClass(filteredEntities, 'loc', 'ok-location');
        if (okLoc) {
          loc = 'ok-location';
        }
      }
      if (temp.asset === filteredEntities.length) {
        const assetOk = this.findEntityWithClass(filteredEntities, 'temp', 'ok-asset');
        if (assetOk) {
          tem = 'ok-asset';
        }
        if (tem === '') {
          const assetOkGrey = this.findEntityWithClass(filteredEntities, 'temp', 'caution-temp');
          if (assetOkGrey) {
            tem = 'caution-temp';
          }
        }
      } else {
        const warnTemp = this.findEntityWithClass(filteredEntities, 'temp', 'warning-temp');
        if (warnTemp) {
          tem = 'warning-temp';
        }
        if (tem === '') {
          const cautionTemp = this.findEntityWithClass(filteredEntities, 'temp', 'caution-temp');
          if (cautionTemp) {
            tem = 'caution-temp';
          }
        }
        if (tem === '') {
          const okTemp = this.findEntityWithClass(filteredEntities, 'temp', 'ok-temp');
          if (okTemp) {
            tem = 'ok-temp';
          }
        }
        if (tem === '') {
          const okAsset = this.findEntityWithClass(filteredEntities, 'temp', 'ok-asset');
          if (okAsset) {
            tem = 'ok-asset';
          }
        }
      }
      result = {
        className: {
          variant: 'multiple',
          loc: loc || 'offline-location', temp: tem || 'offline-temp'
        }, floorPin: 'floor-map-multiple', count: temp.wearer + temp.caregiver + temp.asset
      };
    } else if (temp.wearer || temp.caregiver || temp.asset) {
      const tempFilteredEntities = filteredEntities.filter((item: any) => item.type?.toLowerCase() !== 'room');
      if (temp.wearer && tempFilteredEntities.length) {
        const entity = tempFilteredEntities[0];
        result = { className: entity[entity.id], floorPin: 'floor-map-resident', count: 1 };
      } else if (temp.caregiver && tempFilteredEntities.length) {
        const entity = tempFilteredEntities[0];
        result = { className: entity[entity.id], floorPin: 'floor-map-caregiver', count: 1 };
      } else if (temp.asset && tempFilteredEntities.length) {
        const entity = tempFilteredEntities[0];
        result = { className: entity[entity.id], floorPin: 'floor-map-asset', count: 1 };
      }
    }
    return { ...result, entities: filteredEntities.sort((a, b) => a.order < b.order ? -1 : 1) };
  }

  plotLocationData(init = false) {
    const index = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
    const floor = this.floorMaps[index];
    // if (floor) {
    //   this.clearLocationData(floor);
    // }
    if (init) {
      if (!this.isPlotLocationDataInitiated) {
        this.isPlotLocationDataInitiated = true;
        for (const floor of this.floorMaps) {
          this.didPlotLocationData(floor);
        }
      }
      setTimeout(() => {
        this.isPlotLocationDataInitiated = false;
      }, 1000);
    } else if (floor) {
      this.didPlotLocationData(floor);
    }
  }

  getFilteredLocationData(values: any = []) {
    const type = this.selectedGroup?.wearerGroupName || '';
    let filterKey = '';
    if (type.toLowerCase() === 'residents') {
      filterKey = 'Resident';
    } else if (type.toLowerCase() === 'caregivers') {
      filterKey = 'Caregiver';
    } else if (type.toLowerCase() === 'asset') {
      filterKey = 'Asset';
    }
    return values.filter((item: any) => item.type === filterKey || item.type?.toLowerCase() === 'room');
  }

  didPlotLocationData(floor: any) {
    floor.locationPins = [];
    const pins: any[] = [];
    for (const [point, values] of Object.entries(this.locationData)) {
      const entities: any = this.selectedGroup?.wearerGroupName ? this.getFilteredLocationData(values) : values;
      const result = this.getEntities(entities, floor.floorId);
      if (result) {
        const loc = point.split('_');
        pins.push({ left: +loc[0], top: +loc[1], floorId: floor.floorId, ...result });
      }
    }
    setTimeout(() => {
      floor.locationPins = pins;
    });
    setTimeout(() => {
      this.isLocationPinsUpdated = false;
    }, 5000);
  }

  plotBeds() {
    this.clearPlotBeds();
    const index = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
    const floor = this.floorMaps[index];
    if (floor?.rooms) {
      const floorRooms = floor.rooms.filter((room: any) => room.beds?.length);
      const pins: any[] = [];
      for (const room of floorRooms) {
        const bedError = room.beds.find((item: any) => item.bedStateData?.errorState);
        let bedState = 'active';
        let bedStateColor = '';
        if (bedError) {
          bedState = 'bed-error';
        } else {
          const bedAlertWarning = room.beds.find((item: any) => item.bedStateData?.bedHeightAlertState === 'WARNING_RED' || item.bedStateData?.bedAngleAlertState === 'WARNING_RED');
          if (bedAlertWarning) {
            bedState = 'bed-error';
          } else {
            const bedAlertCaution = room.beds.find((item: any) => item.bedStateData?.bedHeightAlertState === 'CAUTION_YELLOW' || item.bedStateData?.bedAngleAlertState === 'CAUTION_YELLOW');
            if (bedAlertCaution) {
              bedState = 'bed-caution';
            } else {
              const bedAlertGreen = room.beds.find((item: any) => item.bedStateData?.bedHeightAlertState === 'OKAY_GREEN' || item.bedStateData?.bedAngleAlertState === 'OKAY_GREEN');
              if (bedAlertGreen) {
                bedState = 'bed-green';
              }
            }
          }
        }
        if (bedState === 'active') {
          const allSameStatus = room.beds.every((item: any) => item.bedColor === room.beds[0].bedColor);
          if (allSameStatus) {
            bedStateColor = room.beds[0].bedColor;
          }
        }
        pins.push({ left: room.bedXYCordinate?.xValue || room.xValue, top: room.bedXYCordinate?.yValue || room.yValue, roomId: room.roomId, floorId: floor.floorId, bedState, bedStateColor });
      }
      this.bedPoints = pins;
    }
  }

  clearLocationData() {
    for (const floor of this.floorMaps) {
      floor.locationPins = [];
    }
  }

  clearPlotBeds() {
    this.bedPoints = [];
  }

  onBedPoint(ev: any, bedPoint: any) {
    const floor = this.floorMaps.find((item: any) => item.floorId === bedPoint.floorId);
    if (floor?.rooms) {
      const room = floor.rooms.find((room: any) => room.roomId === bedPoint.roomId);
      if (room) {
        // this.showRoomSummary(room);
        this.onRoomContext(ev, room);
      }
    }
  }

  getWearer(id: string) {
    const entities = this.dashboardService.dashboardData;
    const wearer = entities.find((item: any) => item.wearerId === id);
    return wearer;
  }

  getAsset(id: string) {
    const entities = this.dashboardService.dashboardData;
    const asset = entities.find((item: any) => item.assetId === id && item.metaData === META_ASSETS);
    return asset;
  }

  async onPinPoint(ev: any, locationPin: any, entity?: any) {
    // if (locationPin.entities?.length > 1) {
    // }
    const entityId = entity ? (entity.metaData === META_ASSETS ? 'assetId' : 'wearerId') : '';
    const contextList = entityId ? locationPin.entities.filter((item: any) => item.id === entity[entityId] || item.type?.toLowerCase() === 'room') : locationPin.entities;
    const popover = await this.popoverController.create({
      component: ContextMenuComponent,
      event: ev,
      mode: 'ios',
      componentProps: {
        list: contextList
      },
      cssClass: 'context-menu'
    });

    await popover.present();
    const { role, data } = await popover.onDidDismiss();
    if (data) {
      switch (data.type) {
        case 'Room': {
          this.showRoomSummary({ roomName: data.label, roomId: data.id });
        }
          break;
        case WearerGroupEnum.resident:
        case WearerGroupEnum.caregiver: {
          const wearer = this.getWearer(data.id);
          if (wearer) {
            this.showWearerSummaryPopover(wearer, ev);
          }
        }
          break;
        case WearerGroupEnum.asset: {
          const asset = this.getAsset(data.id);
          if (asset) {
            this.showAssetSummaryPopover(asset, ev);
          }
        }
          break;
        default:
      }
    }
  }

  nearestValidPoint(point: any, points: any[]): number {
    const { x, y, radius } = point;
    let res = -1;
    let midDif = Infinity;
    points.forEach((pt, i) => {
      const { xValue: px, yValue: py } = pt;
      // if (px != x && py != y) {
      //   return;
      // }
      const dif = Math.abs(px - x) + Math.abs(py - y);
      if (dif < midDif && Math.abs(px - x) <= radius && Math.abs(py - y) <= radius) {
        midDif = dif;
        res = i;
      }
    });
    return res;
  }

  onPoint(point: any, dim: any, floor: any) {
    let x = point.x;
    let y = point.y;
    let radius = 10;
    if (dim?.width > 300 && !isNaN(point.x)) {
      x = (300 / dim.width) * point.x;
      y = (300 / dim.height) * point.y;
      radius = (dim.width / 300) * radius;
    }
    if (floor?.rooms?.length) {
      const res = this.nearestValidPoint({ x, y, radius }, floor.rooms);
      if (res !== -1) {
        // this.showRoomSummary(floor.rooms[res]);
        const activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
        const ele: any = this.plotElements?.toArray().length && this.plotElements.toArray()[activeIndex] as ElementRef;
        if (ele) {
          this.clearPlots();
          let pt = document.createElement('div');
          pt.classList.add('plot-point');
          pt.style.top = point.y + "px";
          pt.style.left = point.x + "px";
          pt.addEventListener('click', (event) => {
            this.onRoomContext(event, floor.rooms[res]);
          });
          ele.nativeElement.appendChild(pt);
          setTimeout(() => {
            pt.click();
          });
        }
      }
    }
  }

  clearPlots() {
    document.querySelectorAll(".plot-point").forEach(el => el.remove());
  }

  onMapLocation(e: any, floor: any) {
    if (e) {
      const rect = e.target.getBoundingClientRect();
      const width = e.target.clientWidth;
      const height = e.target.clientHeight;
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      this.onPoint({ x: x / this.zoomScale, y: y / this.zoomScale }, { width, height }, floor);
    }
  }

  onRoomContext(ev: any, room: any) {
    const label = room?.roomName;
    const id = room?.roomId;
    const type = 'Room';
    const icon = 'assets/icon/room-location.svg';
    const iconColor = notableColors.stateless;
    this.onPinPoint(ev, { entities: [{ label, id, type, icon, iconColor }] });
  }

  dismiss() {
    this.uiUtilityService.dismissOverlays();
  }

  async showRoomSummary(room: any, ev?: any) {
    this.dismiss();
    const roomSummaryPopup = await this.modalController.create({
      component: RoomSummaryComponent,
      // event: ev,
      componentProps: { ...room },
      cssClass: 'room-summary-modals', //  dashboard-summary-popover
      mode: 'ios',
      showBackdrop: true,
      animated: false,
    });

    await roomSummaryPopup.present();
  }

  didShowSummaryPopover(data: any, locPin: any) {
    const entity = data.type === WearerGroupEnum.asset ? this.getAsset(data.id) : this.getWearer(data.id);
    if (!entity) {
      return;
    }
    const activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
    const selectedFloor = this.floorMaps[activeIndex];
    const floorIndex = this.floorMaps.findIndex(item => item.floorId === data.floorId);
    let isDiffSlide = false;
    if (selectedFloor?.floorId && selectedFloor.floorId !== data.floorId) {
      this.swiperRef?.nativeElement.swiper.slideTo(floorIndex);
      isDiffSlide = true;
    }
    const ele: any = this.plotElements?.toArray().length && this.plotElements.toArray()[floorIndex] as ElementRef;
    if (ele) {
      this.clearPlots();
      let pt = document.createElement('div');
      pt.classList.add('plot-point');
      const img = document.getElementById(`floor-img-${data.floorId}`);
      if (img?.clientWidth && img?.clientHeight) {
        this.windowResizeWidthRatio = img.clientWidth / 300;
        this.windowResizeHeightRatio = img.clientHeight / 300;
      }
      pt.style.top = ((locPin.top * this.windowResizeHeightRatio) - 10) + "px";
      pt.style.left = ((locPin.left * this.windowResizeWidthRatio) - 10) + "px";
      pt.addEventListener('click', (event) => {
        // if (data.type === WearerGroupEnum.asset) {
        //   this.showAssetSummaryPopover(entity, event);
        // } else {
        //   this.showWearerSummaryPopover(entity, event);
        // }
        this.onPinPoint(event, locPin, entity);
      });
      ele.nativeElement.appendChild(pt);
      if (isDiffSlide) {
        setTimeout(() => {
          pt.click();
        }, 500);
      } else {
        pt.click();
      }
    }
  }

  summaryPopoverOnRefresh(data: any, isAsset = false) {
    const activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
    const floor = this.floorMaps[activeIndex];
    let locPin: any;
    for (const locationPin of floor.locationPins) {
      const selectedEntity = locationPin.entities?.find(((item: any) => item.id === (isAsset ? data.assetId : data.wearerId)));
      if (selectedEntity) {
        locPin = locationPin;
        break;
      }
    }
    const ele: any = this.plotElements?.toArray().length && this.plotElements.toArray()[activeIndex] as ElementRef;
    if (ele) {
      this.clearPlots();
      let pt = document.createElement('div');
      pt.classList.add('plot-point');
      pt.style.top = (locPin.top * this.windowResizeWidthRatio) + "px";
      pt.style.left = ((locPin.left * this.windowResizeWidthRatio) - 10) + "px";
      pt.addEventListener('click', (event) => {
        if (isAsset) {
          this.didShowAssetSummaryPopover(data, event);
        } else {
          this.didShowWearerSummaryPopover(data, event);
        }
      });
      ele.nativeElement.appendChild(pt);
      pt.click();
    }
  }

  async didShowWearerSummaryPopover(data: any, ev?: any) {
    const popover = await this.popoverController.create({
      component: DashboardWearerSummaryComponent,
      event: ev,
      mode: 'ios',
      componentProps: {
        data
      },
      cssClass: 'dashboard-summary-modals dashboard-summary-popover',
    });
    await popover.present();
    const { role } = await popover.onWillDismiss();
  }

  async showWearerSummaryPopover(data: any, ev?: any) {
    this.dismiss();
    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH) {
      this.showWearerSummary(data);
      return;
    }
    if (this.isLocationPinsUpdated) {
      this.summaryPopoverOnRefresh(data);
    } else {
      this.didShowWearerSummaryPopover(data, ev);
    }
  }

  async didShowAssetSummaryPopover(data: any, ev?: any) {
    const popover = await this.popoverController.create({
      component: DashboardAssetSummaryComponent,
      event: ev,
      mode: 'ios',
      componentProps: {
        data
      },
      cssClass: 'dashboard-summary-modals dashboard-summary-popover',
    });
    await popover.present();
    const { role } = await popover.onWillDismiss();
  }

  async showAssetSummaryPopover(data: any, ev?: any) {
    this.dismiss();
    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH) {
      this.showAssetSummary(data);
      return;
    }
    if (this.isLocationPinsUpdated) {
      this.summaryPopoverOnRefresh(data, true);
    } else {
      this.didShowAssetSummaryPopover(data, ev);
    }
  }

  async showWearerSummary(data: any) {
    this.dismiss();
    const wearerSummaryPopup = await this.modalController.create({
      component: DashboardWearerSummaryComponent,
      componentProps: { data },
      cssClass: 'dashboard-summary-modals',
      mode: 'ios',
      showBackdrop: true,
      animated: false
    });
    await wearerSummaryPopup.present();
  }

  async showAssetSummary(data: any) {
    this.dismiss();
    if (data.assetId) {
      const assetSummaryPopup = await this.modalController.create({
        component: DashboardAssetSummaryComponent,
        componentProps: { data },
        cssClass: 'dashboard-summary-modals',
        mode: 'ios',
        showBackdrop: true,
        animated: false
      });

      await assetSummaryPopup.present();
    }
  }

  toggleZoom() {
    this.isZoomIn = !this.isZoomIn;
    this.dashboardService.isFloorPlanZoomIn = this.isZoomIn;
    if (this.isZoomIn) {
      this.swiperRef?.nativeElement.swiper.zoom.in();
    } else {
      this.swiperRef?.nativeElement.swiper.zoom?.out();
    }
  }

  trackFloorMap(index: number, floorMap: any) {
    return floorMap.floorId;
  }

  getFloorPlanPrevNext(isNext = false) {
    const activeIndex = this.swiperRef?.nativeElement.swiper.activeIndex || 0;
    const index = isNext ? (activeIndex + 1) : (activeIndex - 1);
    return (index >= 0 && index < this.floorMaps.length) ? this.floorMaps[index]?.floorPlanImage : '';
  }

  ngOnDestroy() {
    this.stopInterval();
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
