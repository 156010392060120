import { ScaleType } from '@swimlane/ngx-charts';

export const colorScheme = [
  { name: 'Warning', value: '#D34626' },
  { name: 'Caution', value: '#FFAA00' },
  { name: 'Okay', value: '#00A980' },
  { name: 'Ok', value: '#00A980' },
  { name: 'Offline', value: '#BBBBBB' },
  { name: 'No Status', value: '#BBBBBB' },
  { name: 'Unworn', value: '#BBBBBB' },
  { name: 'Indeterminate', value: '#FFAA00' },
  { name: 'Online', value: '#009DDC' },
];

export const dashboardWearerColorScheme = {
  name: 'ondo',
  selectable: true,
  group: ScaleType.Time,
  domain: ['#1F6E88', '#FBB03B', 'red'],
};
