<ion-content class="floor-dashboard-ion-content">
  <div class="floor-dashboard-content" *ngIf="floorMaps.length">
    <div class="actions">
      <div class="action-item"><img [src]="isZoomIn ? 'assets/svg/icon-maxzoom.svg' : 'assets/svg/icon-minzoom.svg'"
          (click)="toggleZoom()" /></div>
    </div>
    <div class="plan-image top" *ngIf="!swiperRef?.nativeElement.swiper.isBeginning">
      <div class="plan-image-hint">
        <img [src]="getFloorPlanPrevNext()" />
      </div>
    </div>

    <swiper-container [direction]="'vertical'" pagination="true" navigation="true" pagination-clickable="true"
      zoom="true" [slidesPerView]="1" [initialSlide]="0" [centeredSlides]="true"
      (swiperslidechange)="onSlideChanged($event)" (swiperzoomchange)="onZoomChange($event)"
      (swiperdoubleclick)="onDoubleClick($event)" #swiper>

      <swiper-slide *ngFor="let map of floorMaps; trackBy: trackFloorMap">
        <div class="floor">
          <p class="floor-name" *ngIf="map.floorName">{{map.floorName}}</p>
          <div class="swiper-zoom-container" (wheel)="onWheel($event)">
            <div class="floor-image swiper-zoom-target" [id]="'floor-map'+ map.floorId" #plotElements>
              <img class="clickable-image" [id]="'floor-img-'+map.floorId" [src]="map.floorPlanImage"
                (click)="onMapLocation($event, map)" />

              <div class="floor-dashboard-bed-point interactive" [ngClass]="bedPoint.bedState"
                [style.top.px]="(bedPoint.top * windowResizeHeightRatio)-bedPointImageHalf"
                [style.left.px]="(bedPoint.left * windowResizeWidthRatio)-bedPointImageHalf"
                [style.color]="bedPoint.bedStateColor" (click)="onBedPoint($event, bedPoint)"
                [style.transform]="'scale('+(zoomScale > 1 ? (1/zoomScale)+0.1 : 1)+')'"
                *ngFor="let bedPoint of bedPoints">
              </div>

              <div [style.top.px]="locationPin.top * windowResizeHeightRatio" class="map-pin interactive"
                [ngClass]="locationPin.className?.variant || ''"
                [style.left.px]="locationPin.left * windowResizeWidthRatio" (click)="onPinPoint($event, locationPin)"
                [style.transform]="'translate(-50%,-50%) scale('+(zoomScale > 1 ? (1/zoomScale)+0.1 : 1)+')'"
                *ngFor="let locationPin of map.locationPins; let i = index;">
                <span class="count">{{locationPin.count > 1 ? locationPin.count : ''}}</span>
                <app-map-multiple *ngIf="locationPin.floorPin === 'floor-map-multiple'" [locationPin]="locationPin"
                  [uid]="'m'+i"></app-map-multiple>
                <app-map-resident *ngIf="locationPin.floorPin === 'floor-map-resident'" [locationPin]="locationPin"
                  [uid]="'r'+i"></app-map-resident>
                <app-map-caregiver *ngIf="locationPin.floorPin === 'floor-map-caregiver'" [locationPin]="locationPin"
                  [uid]="'c'+i"></app-map-caregiver>
                <app-map-asset *ngIf="locationPin.floorPin === 'floor-map-asset'" [locationPin]="locationPin"
                  [uid]="'a'+i"></app-map-asset>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="actions bottom" [ngClass]="{'bottom-0': floorMaps.length === 1}">
      <div class="action-item ripple"><img
          [src]="isLastRefreshLoading ? 'assets/svg/action-bg.svg' : 'assets/svg/refresh.svg'" (click)="refreshMap()" />
        <ion-spinner name="lines-sharp" color="light" *ngIf="isLastRefreshLoading"></ion-spinner>
      </div>
      <div class="refresh-note" *ngIf="isLastRefreshLoading">Loading...</div>
      <div class="refresh-note" *ngIf="lastRefreshed !== '' && !isLastRefreshLoading">Dashboard Last Refreshed at
        {{lastRefreshed}}</div>
    </div>
    <div class="plan-image bottom" *ngIf="!swiperRef?.nativeElement.swiper.isEnd">
      <div class="plan-image-hint">
        <img [src]="getFloorPlanPrevNext(true)" />
      </div>
    </div>
  </div>
</ion-content>