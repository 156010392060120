import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor(
    private dataService: DataService<any>
  ) { }

  getDetectionChartData(): Observable<ApiResponseModel<any>> {
    const url = `${environment.baseUrl}${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.monthlyDetection}?timeZone=GMT`;
    return this.dataService.getData(url);
  }

  getMonthlyUsagesChartData(): Observable<ApiResponseModel<any>> {
    const url = `${environment.baseUrl}${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.monthlyUsage}?timeZone=GMT`;
    return this.dataService.getData(url);
  }

  getDetectionChartV2(params: any): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.ps30DaysDetection}/${params.days}?wearerGroup=${params.wearerGroups}`;
    return this.dataService.getData(url);
  }

  getMonthlyUsagesChartV2(params: any): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.ps30DaysUsage}/${params.days}?wearerGroup=${params.wearerGroups}`;
    return this.dataService.getData(url);
  }

  getLocationAlertChartV2(params: any): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.ps30DaysLocationEvents}/${params.days}?wearerGroup=${params.wearerGroups}`;
    return this.dataService.getData(url);
  }
}
