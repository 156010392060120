<svg [ngClass]="[locationPin.className?.loc || '', locationPin.className?.temp || '']" width="102" height="102"
  viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.7"
    d="M50.872 101.48C78.7701 101.48 101.386 78.8643 101.386 50.9661C101.386 23.068 78.7701 0.452148 50.872 0.452148C22.9739 0.452148 0.357971 23.068 0.357971 50.9661C0.357971 78.8643 22.9739 101.48 50.872 101.48Z"
    [attr.fill]="'url(#paint0_radial_15_3015'+uid+')'" />
  <path opacity="0.7"
    d="M50.872 86.8721C70.7023 86.8721 86.778 70.7964 86.778 50.9661C86.778 31.1357 70.7023 15.0601 50.872 15.0601C31.0417 15.0601 14.966 31.1357 14.966 50.9661C14.966 70.7964 31.0417 86.8721 50.872 86.8721Z"
    [attr.fill]="'url(#paint1_radial_15_3015'+uid+')'" />
  <path class="circle"
    d="M50.872 74.2342C63.7226 74.2342 74.14 63.8168 74.14 50.9662C74.14 38.1157 63.7226 27.6982 50.872 27.6982C38.0214 27.6982 27.604 38.1157 27.604 50.9662C27.604 63.8168 38.0214 74.2342 50.872 74.2342Z"
    fill="#BBBBBB" />
  <path
    d="M62.134 47.0353L51.001 51.7673C50.9602 51.7846 50.9163 51.7936 50.872 51.7936C50.8277 51.7936 50.7838 51.7846 50.743 51.7673L39.61 47.0353C39.5511 47.0147 39.488 47.0091 39.4265 47.0191C39.3649 47.0292 39.3068 47.0546 39.2575 47.0929C39.2083 47.1312 39.1694 47.1812 39.1445 47.2384C39.1196 47.2956 39.1095 47.3582 39.115 47.4203V57.8813C39.106 58.2019 39.1906 58.5182 39.3584 58.7915C39.5262 59.0648 39.77 59.2833 40.06 59.4203L50.355 63.7953C50.5183 63.8656 50.6942 63.9018 50.872 63.9018C51.0498 63.9018 51.2257 63.8656 51.389 63.7953L61.689 59.4203C61.9789 59.2833 62.2227 59.0648 62.3906 58.7915C62.5584 58.5182 62.643 58.2019 62.634 57.8813V47.4203C62.6399 47.3576 62.6298 47.2944 62.6046 47.2366C62.5795 47.1788 62.5401 47.1283 62.4902 47.0899C62.4402 47.0514 62.3814 47.0263 62.3191 47.0168C62.2568 47.0072 62.1931 47.0136 62.134 47.0353Z"
    fill="white" />
  <path
    d="M40.02 44.8003L50.355 49.1923C50.5183 49.2625 50.6942 49.2988 50.872 49.2988C51.0498 49.2988 51.2257 49.2625 51.389 49.1923L61.724 44.8003C61.795 44.7641 61.8546 44.709 61.8963 44.6411C61.938 44.5731 61.96 44.495 61.96 44.4153C61.96 44.3356 61.938 44.2574 61.8963 44.1895C61.8546 44.1215 61.795 44.0664 61.724 44.0303L51.392 39.6383C51.2287 39.568 51.0528 39.5317 50.875 39.5317C50.6972 39.5317 50.5213 39.568 50.358 39.6383L40.023 44.0303C39.9518 44.0661 39.892 44.121 39.85 44.1888C39.8081 44.2566 39.7858 44.3347 39.7855 44.4144C39.7852 44.4941 39.8069 44.5723 39.8483 44.6404C39.8897 44.7085 39.9491 44.7639 40.02 44.8003Z"
    fill="white" />
  <defs>
    <radialGradient [id]="'paint0_radial_15_3015'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(50.872 50.9661) scale(50.514)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.252" class="rings" stop-color="#BBBBBB" stop-opacity="0.016" />
      <stop offset="0.455" class="rings" stop-color="#BBBBBB" stop-opacity="0.059" />
      <stop offset="0.641" class="rings" stop-color="#BBBBBB" stop-opacity="0.137" />
      <stop offset="0.816" class="rings" stop-color="#BBBBBB" stop-opacity="0.243" />
      <stop offset="0.982" class="rings" stop-color="#BBBBBB" stop-opacity="0.384" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
    <radialGradient [id]="'paint1_radial_15_3015'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(50.872 50.9661) scale(35.906)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.461" class="rings" stop-color="#BBBBBB" stop-opacity="0.01" />
      <stop offset="0.628" class="rings" stop-color="#BBBBBB" stop-opacity="0.039" />
      <stop offset="0.746" class="rings" stop-color="#BBBBBB" stop-opacity="0.094" />
      <stop offset="0.842" class="rings" stop-color="#BBBBBB" stop-opacity="0.169" />
      <stop offset="0.923" class="rings" stop-color="#BBBBBB" stop-opacity="0.267" />
      <stop offset="0.994" class="rings" stop-color="#BBBBBB" stop-opacity="0.388" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
  </defs>
</svg>