export class FloorRoomModel {
    roomId: number | string = 0;
    roomName: number | string = 0;
    roomArea: RoomArea = new RoomArea();
    lightStatus?: boolean = false;
    isActiveRoom?: boolean = false;
}
export class RoomArea {
    x1: number = 0;
    y1: number = 0;
    x2: number = 0;
    y2: number = 0;
}