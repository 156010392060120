<ion-content>
  <div class="container">
    <div class="wearer-summary">
      <ion-grid class="ion-no-padding title">
        <ion-row class="ion-justify-content-space-between padding-top">
          <ion-col size="9">
            <p class="wearer-summary-header">{{ data.displayName }}</p>
          </ion-col>
          <!-- <ion-row class="notable-state-row"> -->
          <!-- </ion-row> -->
          <ion-col size="3" class="edit-link">
            <!-- <ion-icon class="edit-link-icon"
              src="assets/icon/{{data?.metaData === null ? data?.wearerGroupName: 'Assets'}}.svg"></ion-icon> -->
            <span class="edit-btn" (click)="navigateToWearerDetailsPage()" [nAccess]="'getWearerDetail'">Edit</span>
          </ion-col>
        </ion-row>
        <ion-row class="wearer-summary-content">
          <p class="wearer-group-name">{{wearerGroupName}}</p>
          <ng-container class="notable-state-row"
            *ngIf="data.locationAlert && notableLocationStates.indexOf(data.locationState) !== -1; else bandState">
            <div class="notable-state" [ngClass]="data.locationInfo.class">
              {{data.locationInfo.label}}
              <ion-icon class="interactive" src="assets/icon/icons_Hint.svg" *ngIf="data.locationInfo.description"
                (click)="showLocationStateInfo(data.locationInfo)">
              </ion-icon>
            </div>
          </ng-container>
          <ng-template class="notable-state-row" #bandState>
            <div class="notable-state" *ngIf="data.band" [ngClass]="data.band.class">
              {{data.band.stateMsg}}
              <ion-icon class="interactive" src="assets/icon/icons_Hint.svg" *ngIf="data.band.description"
                (click)="showNotableStatMsg(data.band)">
              </ion-icon>
            </div>
          </ng-template>
        </ion-row>
        <ion-row>
          {{ data.category }}
        </ion-row>
      </ion-grid>
      <ion-grid class="ion-no-padding">
        <div class="resident-details-box">
          <ion-row class="room-name-row m-b-10" *ngIf="data.roomName">
            <ion-col size="1.0" class="room-location-icon">
              <ion-icon class="entity-icon entity-icon-stateless" src="assets/icon/room-location.svg"></ion-icon>
            </ion-col>
            <ion-col [ngClass]="{ 'route-link': activeRoom, 'no-link': !isRoomSummaryEnabled }"
              (click)="showRoomSummary()" class="room-name-col room-name-text">
              <span>{{ data.roomName }}</span>
            </ion-col>
          </ion-row>
          <ion-row class="bed-name-row m-b-10" *ngIf="wearerDetails?.beds?.length">
            <ion-col size="1.0" class="room-location-icon">
              <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                [style.color]="bedErrorState"></ion-icon>
            </ion-col>
            <ion-col [ngClass]="{ 'route-link': activeRoom }">
              <span>{{ bedName }}</span>
            </ion-col>
          </ion-row>
          <div *ngFor="let alert of alertsIconOrder | keyvalue" class="full-width">
            <ion-row class="bed-name-row m-b-10"
              *ngIf="((alert.key === bedInType && casparData[data.patientId]?.[alert.key]) || (alert.key === bedOutType && casparData[data.patientId]?.[alert.key]))">
              <ion-col size="1.0" class="room-location-icon">
                <ion-icon class="entity-icon" src="assets/icon/{{alert.value?.icon}}"></ion-icon>
              </ion-col>
              <ion-col>
                <span>{{alert.value?.displayName}}</span>
              </ion-col>
            </ion-row>
          </div>
          <ion-row *ngIf="data.lastTempTime !== null " class="bed-name-row m-b-10">
            <ion-col size="1.0" class="room-location-icon">
              <ion-icon class="entity-icon" [src]="!data.location ? 'assets/icon/pin-off.svg' : 'assets/icon/pin.svg'"
                [style.color]="data.mapPinColor"></ion-icon>
            </ion-col>
            <ion-col> Updated {{ lastUpdatedTime.value }} </ion-col>
          </ion-row>
          <ion-row class="bed-name-row m-b-10"
            *ngIf="!data.isTag && (data.runingHourAvgInF !== null || data.runingHourAvgInC !== null)">
            <ion-col size="1.0" class="room-location-icon">
              <ion-icon class="entity-icon" src="assets/icon/thermometer.svg"
                [style.color]="data.tempIconColor"></ion-icon>
            </ion-col>
            <ion-col>
              Current Temperature: {{ tempUnitId === 1 ? data.runingHourAvgInC : data.runingHourAvgInF }}{{
              tempUnitId === 1 ?'&deg;C' : '&deg;F' }}
            </ion-col>
          </ion-row>
          <ion-row class="bed-name-row m-b-10" *ngIf="data.bandState === 4">
            <ion-col size="1.0" class="room-location-icon">
              <ion-icon class="entity-icon" src="assets/icon/low-battery.svg"></ion-icon>
            </ion-col>
            <ion-col>
              {{data.isTag ? 'Tag' : 'Band'}} battery low
            </ion-col>
          </ion-row>
        </div>
        <div *ngIf="isResident && isCasparEnabled">
          <div *ngIf="isActiveVitalAlerts; else noActiveVitalAlerts;">
            <div *ngFor="let alert of alertsIconOrder | keyvalue">
              <div *ngIf="casparData[data.patientId]?.[alert.key] && casparData[data.patientId][alert.key]?.alert_id">
                <div class="divider"></div>
                <ion-grid class="ion-no-padding">
                  <ion-row class="deviation-row">
                    <ion-col size="1.0" class="room-location-icon">
                      <ion-icon class="entity-icon" src="assets/icon/{{alert.value?.icon}}"
                        [style.color]="'transparent'"></ion-icon>
                    </ion-col>
                    <ion-col>
                      <p class="app-link no-margin" (click)="launchCaspar(data)">{{alert.value?.displayName}}</p>
                    </ion-col>
                  </ion-row>
                  <ion-row class="deviation-row" *ngIf="casparData[data.patientId]?.[alert.key]?.insights">
                    <ion-col size="1.0">
                    </ion-col>
                    <ion-col>
                      <p class="no-margin m-t-10">{{casparData[data.patientId]?.[alert.key].insights}}</p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </div>
          <ng-template #noActiveVitalAlerts>
            <div class="divider"></div>
            <ion-grid class="ion-no-padding">
              <ion-row class="deviation-row">
                <ion-col size="1.0" class="room-location-icon">
                  <ion-icon class="entity-icon" src="assets/icon/ok-vitals.svg"
                    [style.color]="'transparent'"></ion-icon>
                </ion-col>
                <ion-col>
                  <p class="app-link no-margin" (click)="launchCaspar()">No active vital alerts</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </div>
        <div class="divider"></div>
        <!-- Hide the Notable event section if tagId or bandId does not present -->
        <ion-row class="details-row details-row-notable-events">
          <h3 class="notable-event-title">
            Notable Events
          </h3>
          <div *ngIf="notableEventsDetected; else noNotableEvents;" id="notable-events-content">
            <div class="notable-lines" *ngFor="let reading of wearerNotableEvents">
              {{reading.location ? eventStrings.locationLastSeenAt : eventStrings.tempAt }}
              {{ eventStrings.at }} {{ reading.notableTime }}
            </div>
          </div>
          <ng-template #noNotableEvents>
            <p id="notable-events-content">
              No notable events detected
            </p>
          </ng-template>
        </ion-row>
        <div class="divider" *ngIf="data.bandId && !data.isTag"></div>
        <ion-row class="details-row" *ngIf="data.bandId && !data.isTag">
          <ion-col>
            <!-- <div> -->
            <h3 class="body-temp-title">Body Temperature Trends</h3>
            <ngx-charts-bar-vertical [view]="[300, 100]" [scheme]="wearerColorSchema" [gradient]="false"
              [customColors]="data.customColorsTemp" [results]="data.graphDisplay" [barPadding]="barPadding"
              [roundEdges]="false" [yScaleMin]="10" [yScaleMax]="200" [yScaleMax]="100" [animations]="false"
              [tooltipDisabled]="false" [xAxis]="true" [xAxisTickFormatting]="xAxisTickFormatting">
              <ng-template #tooltipTemplate let-index="index" let-model="model">
                <div *ngFor="let d of data.graphDisplay; let i = index">
                  <div class="trend-tooltip"
                    *ngIf="d.name === model.name && d.valueInC !== 'NoData' && d.valueInF !== 'NoData'">
                    <span [innerHTML]="getTrendTooltipText(d)"></span>
                  </div>
                </div>
              </ng-template>
              <!-- <ng-template let-index="index" let-model="model">
                <div *ngFor="let d of data.graphDisplay; let i = index">
                  <div class="bar-tooltip" [ngStyle]="{
                        'background-color':
                          threeColors[data.customColors[i].value]
                      }" *ngIf="d.name === model.name">
                    <pre [innerHTML]="
                          getTooltipText(
                            d.timeStamp,
                            tempUnitId === 1 ? d.valueInF : d.valueInC
                          )
                        "></pre>
                  </div>
                </div>
              </ng-template> -->
              <!-- <ng-template #seriesTooltipTemplate let-index="index" let-model="model">
                <div *ngFor="let d of data.graphDisplay; let i = index">
                  <div class="bar-tooltip" [ngStyle]="{
                        'background-color': data.customColors[i].value
                      }" *ngIf="d.name === model[0].name">
                    <pre [innerHTML]="
                          getTooltipText(
                            d.timeStamp,
                            tempUnitId === 1 ? d.valueInF : d.valueInC
                          )
                        "></pre>
                  </div>
                </div>
              </ng-template> -->
            </ngx-charts-bar-vertical>
            <!-- <div [style.width.px]="trendChart.offsetWidth/24" class="fadeout-end"></div> -->
            <!-- <div class="trend-chart-bottom"></div> -->
            <!-- </div> -->
          </ion-col>
        </ion-row>
        <div class="divider" *ngIf="isResident"></div>
        <ion-row class="details-row details-row-notable-events" *ngIf="isResident">
          <h3 class="notable-event-title">
            Alert Settings
          </h3>
          <div *ngIf="isLoading" class="ion-text-center">
            <ion-spinner></ion-spinner>
          </div>
          <div *ngIf="isAlertsPresent && wearerDetails?.residentDetail?.alertSettings" id="notable-events-content">
            <div *ngFor="let alert of wearerAlerts">
              <div *ngIf="wearerDetails?.residentDetail?.alertSettings[alert.key]" class="m-b-10">
                <div class="notable-lines">
                  {{alert.label}}:
                </div>
                <div *ngFor="let alert1 of alert.alerts">
                  <div
                    *ngIf="wearerDetails?.residentDetail?.alertSettings[alert1.key] && wearerDetails?.residentDetail?.alertSettings[alert1.key] !== 'NA'"
                    class="notable-lines">
                    {{alert1.label}} {{alert1.dataset[wearerDetails?.residentDetail?.alertSettings[alert1.key]]}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isAlertsPresent && !isLoading">
            No alerts set.
          </div>
        </ion-row>
      </ion-grid>
      <!-- <div class="trend-container" *ngIf="data.graphDisplay && data.graphDisplay.length">
          <ngx-charts-bar-vertical [view]="[300,200]" [results]="data.graphDisplay"
            [customColors]="data.customColorsTemp" [barPadding]="2" [tooltipDisabled]="true" [roundEdges]="false"
            [yScaleMin]="0" [yScaleMax]="100" [animations]="false" [noBarWhenZero]="false">
          </ngx-charts-bar-vertical>
        </div> -->
      <!-- <div>
        <p>Body Temperature</p> -->

      <!-- </div> -->
      <hr />
    </div>
  </div>
</ion-content>