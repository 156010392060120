import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-map-caregiver',
  templateUrl: './floor-map-caregiver.component.html',
  styleUrls: ['../floor-dashboard.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class MapCaregiver implements OnInit {
  @Input() locationPin: any = {};
  @Input() uid = '400';

  constructor() {

  }

  ngOnInit(): void {
  }
}