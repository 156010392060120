import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IonicModule, ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-caspar-frame',
  templateUrl: './caspar-frame.component.html',
  styleUrls: ['./caspar-frame.component.scss'],
  imports: [IonicModule, CommonModule]
})
export class CasparFrameComponent implements OnInit {
  @Input() iframeSrc: string = '';
  safeIframeSrc!: SafeResourceUrl;
  loading = true;

  constructor(private modalController: ModalController, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    const url = `${environment.casparIframeURL}${this.iframeSrc}`;
    this.safeIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onIframeLoad() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}