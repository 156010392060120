import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import {
  DashboardData,
  LastUpdatedTime,
} from 'src/app/shared/models/dashboard.model';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { notableEventsStringParts } from 'src/app/shared/enums/notable-state-message';
import { RoomSummaryComponent } from '../room-summary/room-summary.component';
import { ALERT_BAND_STATES, ALERT_LOCATION_STATES, MODAL_PAGE_TYPE } from 'src/app/shared/config/app.constant';
import { STATES_INFO } from 'src/app/shared/data/filter.data';
import { AssetDetailsPage } from '../../asset-management/pages/asset-details/asset-details.page';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'app-dashboard-asset-summary',
  templateUrl: './dashboard-asset-summary.component.html',
  styleUrls: ['./dashboard-asset-summary.component.scss'],
  imports: [IonicModule, CommonModule, NgxChartsModule, RouterModule, AccessControlDirective],
})
export class DashboardAssetSummaryComponent implements OnInit {
  lastUpdatedTime: LastUpdatedTime = new LastUpdatedTime();
  assetDetails: any = new DashboardData();
  threeColors = ['#FBB03B', '#1F6E88', 'red'];
  eventStrings = notableEventsStringParts;
  residentDetails: any;
  bedName: any;
  assetNotableEvents: any[] = [];
  locationState: any = {};
  bandState: any = {};
  isRoomSummaryEnabled = true;

  constructor(
    public modalController: ModalController,
    public dashboardService: DashboardService,
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    const { data }: any = this.navParams;
    this.assetDetails = data.data;
    if (this.assetDetails.assetId) {
      this.getAssetNotableEvents();
    }
    if (this.assetDetails.lastTempTime) {
      this.lastUpdatedTime = this.dashboardService.convertLTSOffset(
        this.assetDetails.lastTempTime
      );
    }
    const locationState = this.assetDetails.locationState;
    if (locationState && ALERT_LOCATION_STATES.indexOf(locationState) !== -1) {
      this.locationState = STATES_INFO.find((item: any) => item.type === 'locationState' && item.state === locationState);
    }
    const bandState = this.assetDetails.bandState;
    if (bandState && ALERT_BAND_STATES.indexOf(bandState) !== -1) {
      this.bandState = STATES_INFO.find((item: any) => item.type === 'bandState' && item.state === bandState);
    }
    this.isRoomSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');
  }
  getTime(timeString: any, addColon = true) {
    if (timeString) {
      const times = timeString.split("-");
      const hourString = times[times.length - 1]
        .replace(":00 ", "")
        .replace(/^0*(.+)/, "$1");
      return `${hourString}${addColon ? ":" : ""}`;
    } else return "";
  }

  dismiss() {
    this.uiUtilityService.dismissOverlays();
  }

  async showRoomSummary() {
    if (!this.isRoomSummaryEnabled) {
      return;
    }
    this.dismiss();
    const roomSummaryPopup = await this.modalController.create({
      component: RoomSummaryComponent,
      componentProps: { ...this.assetDetails },
      cssClass: 'room-summary-modals',
      mode: 'ios',
      showBackdrop: true,
      animated: false,
    });
    await roomSummaryPopup.present();
  }

  navigateToDetailsPage() {
    this.dismiss();
    const menuItem = { component: AssetDetailsPage, id: 'asset-list', confirm: false, pageType: MODAL_PAGE_TYPE, modalData: { assetId: this.assetDetails.assetId, isRootPage: true } };
    this.uiUtilityService.menuClicked.next(menuItem);
    // this.router.navigate([RouterEnum.assetDetails, this.assetDetails.assetId, 'detail']);
  }

  getAssetNotableEvents() {
    this.dashboardService.getAssetNotableEvents(this.assetDetails.assetId).subscribe((res) => {
      if (res.success) {
        this.assetNotableEvents = res.data;
      }
    });
  }

  showLocStateInfo(tag: any) {
    this.uiUtilityService.showAlert(tag.assetDescription || tag.description, this.uiUtilityService.capitalize(tag.label));
  }

  async showTagStateInfo(tag: any) {
    this.uiUtilityService.showAlert(tag.description, this.uiUtilityService.capitalize(tag.label));
  }
}
