<svg [ngClass]="[locationPin.className?.loc || '', locationPin.className?.temp || '']" width="180" height="180"
  viewBox="0 0 180 180" fill="none">
  <path opacity="0.7"
    d="M90 180C139.706 180 180 139.706 180 90C180 40.2944 139.706 0 90 0C40.2944 0 0 40.2944 0 90C0 139.706 40.2944 180 90 180Z"
    [attr.fill]="'url(#paint0_radial_787_2840'+uid+')'" />
  <path opacity="0.7"
    d="M89.9999 153.973C125.331 153.973 153.973 125.331 153.973 90C153.973 54.6686 125.331 26.0268 89.9999 26.0268C54.6686 26.0268 26.0268 54.6686 26.0268 90C26.0268 125.331 54.6686 153.973 89.9999 153.973Z"
    [attr.fill]="'url(#paint1_radial_787_2840'+uid+')'" />
  <path class="circle"
    d="M90.2089 131.665C113.105 131.665 131.665 113.105 131.665 90.2089C131.665 67.3131 113.105 48.7524 90.2089 48.7524C67.3131 48.7524 48.7524 67.3131 48.7524 90.2089C48.7524 113.105 67.3131 131.665 90.2089 131.665Z"
    fill="#BBBBBB" />
  <defs>
    <radialGradient [id]="'paint0_radial_787_2840'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.5781 91.3549) scale(90.457)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.252" class="rings" stop-color="#BBBBBB" stop-opacity="0.016" />
      <stop offset="0.455" class="rings" stop-color="#BBBBBB" stop-opacity="0.059" />
      <stop offset="0.641" class="rings" stop-color="#BBBBBB" stop-opacity="0.137" />
      <stop offset="0.816" class="rings" stop-color="#BBBBBB" stop-opacity="0.243" />
      <stop offset="0.982" class="rings" stop-color="#BBBBBB" stop-opacity="0.384" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
    <radialGradient [id]="'paint1_radial_787_2840'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.579 91.3559) scale(64.298)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.461" class="rings" stop-color="#BBBBBB" stop-opacity="0.01" />
      <stop offset="0.628" class="rings" stop-color="#BBBBBB" stop-opacity="0.039" />
      <stop offset="0.746" class="rings" stop-color="#BBBBBB" stop-opacity="0.094" />
      <stop offset="0.842" class="rings" stop-color="#BBBBBB" stop-opacity="0.169" />
      <stop offset="0.923" class="rings" stop-color="#BBBBBB" stop-opacity="0.267" />
      <stop offset="0.994" class="rings" stop-color="#BBBBBB" stop-opacity="0.388" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
  </defs>
</svg>