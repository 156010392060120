<ion-content class="content">
  <ion-row class="ion-justify-content-between report-header">
    <ion-col>
      Report generated: {{reportData.generatedOn}}
    </ion-col>
    <ion-col class="ion-text-end">
      Report Data: <a class="app-link" [class.disabled]="loading" (click)="!loading && downloadPDF()">Download PDF</a>
      <!-- | <a
        class="app-link disabled">CSV</a> -->
    </ion-col>
  </ion-row>
  <div #contentToDownload>
    <div class="report-card">
      <h3 class="header">Population Summary</h3>
      <h4 class="sub-header">Overview</h4>
      <ion-row>
        <ion-col size="5.5" class="ion-no-padding">
          <ion-row class="ps-left-content">
            <ion-col class="ion-no-padding">
              <p>{{reportData.name}}</p>
              <p>Facility: {{facilityName}}</p>
              <p>Facility timezone: {{timeZone}}</p>
            </ion-col>
            <ion-col class="ion-no-padding">
              <p>Timeframe:</p>
              <p>{{reportData.timeframe}}</p>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col class="ion-no-padding kpi-block">
          <div class="kpi">
            <div class="kpi-detail" *ngIf="reportData.data?.length">
              <p>Population Size</p>
              <p class="value">{{reportData.data?.length}}</p>
              <p>{{wearerTitle}}</p>
            </div>
            <div class="kpi-detail" *ngIf="isBedPosition && reportData.data?.length">
              <p>Overall Back Angle Score <ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
                  (click)="onInfo(backAngleScoreInfo)"></ion-icon></p>
              <p class="value" [style.color]="reportData.overallBackAngleScoreColor">
                {{reportData.overallBackAngleScore}}
              </p>
              <p *ngIf="reportData.overallBackAngleDiff"><span class="average"
                  [style.color]="reportData.overallBackAngleDiffColor">{{reportData.overallBackAngleDiff}}</span>
                <span> {{reportData.overallBackAngleDiffState === 'WARNING' ? "worse than last week's average" : "better
                  than last week's average"}}</span>
              </p>
            </div>
            <div class="kpi-detail" *ngIf="isBedPosition && reportData.data?.length">
              <p>Overall Bed Height Score <ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
                  (click)="onInfo(bedHeightScoreInfo)"></ion-icon></p>
              <p class="value" [style.color]="reportData.overallBedHeightScoreColor">
                {{reportData.overallBedHeightScore}}
              </p>
              <p *ngIf="reportData.overallBedHeightDiff"><span class="average"
                  [style.color]="reportData.overallBedHeightDiffColor">{{reportData.overallBedHeightDiff}}</span>
                <span> {{reportData.overallBedHeightDiffState === 'WARNING' ? "worse than
                  last week's average" : "better than last week's average"}}</span>
              </p>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <div class="report-card report-wearer-summary">
      <div class="header-block">
        <h3 class="header">Wearer Summary</h3>
        <!-- <ion-item lines="none">
          <ion-label class="select-label">Show:</ion-label>
          <ion-select slot="end" aria-label="Choose" placeholder="Choose" interface="popover"
            [(ngModel)]="showSelectedValue" (ionChange)="onShowValChange($event)">
            <ion-select-option *ngFor="let pd of showValues" [value]="pd">{{pd.displayName}}</ion-select-option>
          </ion-select>
        </ion-item> -->
      </div>
      <table class="sortable-table" [ngClass]="graphCols">
        <thead>
          <tr>
            <th *ngFor="let config of tableData" [ngClass]="config.key" (click)="sortTable(config.key)">
              <div class="heading">
                {{config.name}}<ion-icon [name]="getSortIcon(config.key)" *ngIf="config.sort"></ion-icon>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="ion-text-center" *ngIf="loading">
            <td *ngFor="let config of tableData">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </td>
          </tr>
          <tr *ngFor="let row of sortedData" class="interactive" (click)="onRowClick(row)">
            <td *ngFor="let config of tableData" [ngClass]="config.key">
              <div *ngIf="!config.graph; else isGraph">
                <p [style.color]="row[config.key+'Color']"><span *ngIf="row[config.key+'Formatted']">{{
                    row[config.key+'Formatted']}}</span><span *ngIf="!row[config.key+'Formatted']">{{
                    row[config.key]}}</span></p>
                <p *ngIf="row[config.key+'Diff']" [style.color]="row[config.key+'DiffColor']" class="diff"><span>{{
                    row[config.key+'Diff']}}</span></p>
              </div>
              <ng-template #isGraph>
                <div class="table-graph" *ngIf="showGraph && config.key === 'tempTrendsGraph' && row.tempTrendsGraph">
                  <p class="graph-error" *ngIf="row.tempTrendsGraph.error; else isTempTrendsGraph">
                    {{row.tempTrendsGraph.errorTitle}}</p>
                  <ng-template #isTempTrendsGraph>
                    <canvas baseChart [data]="row.tempTrendsGraph" [options]="reportChartOptions['tempTrendsGraph']"
                      [type]="row.tempTrendsGraph.type">
                    </canvas>
                  </ng-template>
                </div>
                <div class="table-graph" *ngIf="showGraph && config.key === 'backAngleGraph' && row.backAngleGraph">
                  <p class="graph-error" *ngIf="row.backAngleGraph.error; else isBackAngleGraph">
                    {{row.backAngleGraph.errorTitle}}</p>
                  <ng-template #isBackAngleGraph>
                    <canvas baseChart [data]="row.backAngleGraph" [options]="reportChartOptions['backAngleGraph']"
                      [plugins]="row.backAngleGraph.category === 'stepped-line' ? lineChartPlugins : []"
                      [type]="row.backAngleGraph.type">
                    </canvas>
                  </ng-template>
                </div>
                <div class="table-graph" *ngIf="showGraph && config.key === 'bedHeightGraph' && row.bedHeightGraph">
                  <p class="graph-error" *ngIf="row.bedHeightGraph.error; else isBedHeightGraph">
                    {{row.bedHeightGraph.errorTitle}}</p>
                  <ng-template #isBedHeightGraph>
                    <canvas baseChart [data]="row.bedHeightGraph" [options]="reportChartOptions['bedHeightGraph']"
                      [plugins]="row.bedHeightGraph.category === 'stepped-line' ? lineChartPlugins : []"
                      [type]="row.bedHeightGraph.type">
                    </canvas>
                  </ng-template>
                </div>
              </ng-template>
            </td>
          </tr>
          <tr class="no-records" *ngIf="!loading && !sortedData.length">
            <p>No records</p>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="graph">
    <p>Stacked Bar Chart</p>
    <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
      [type]="barChartData.type">
    </canvas>
    <p>Line Chart</p>
    <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [plugins]="lineChartPlugins"
      [type]="lineChartData.type">
    </canvas>
    <p>Bar Chart</p>
    <canvas baseChart [data]="barChartData1" [options]="barChartOptions1" [plugins]="barChartPlugins1" type="bar">
    </canvas>
  </div> -->
</ion-content>