<svg [ngClass]="[locationPin.className?.loc || '', locationPin.className?.temp || '']" width="182" height="182"
  viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="circle"
    d="M90.5791 133.023C113.591 133.023 132.246 114.368 132.246 91.356C132.246 68.3439 113.591 49.689 90.5791 49.689C67.5671 49.689 48.9121 68.3439 48.9121 91.356C48.9121 114.368 67.5671 133.023 90.5791 133.023Z"
    fill="#BBBBBB" />
  <path opacity="0.7"
    d="M90.5781 181.812C140.536 181.812 181.035 141.313 181.035 91.3549C181.035 41.3969 140.536 0.897949 90.5781 0.897949C40.6201 0.897949 0.121094 41.3969 0.121094 91.3549C0.121094 141.313 40.6201 181.812 90.5781 181.812Z"
    [attr.fill]="'url(#paint0_radial_15_3113'+uid+')'" />
  <path opacity="0.7"
    d="M90.579 155.654C126.09 155.654 154.877 126.867 154.877 91.3559C154.877 55.8451 126.09 27.0579 90.579 27.0579C55.0682 27.0579 26.281 55.8451 26.281 91.3559C26.281 126.867 55.0682 155.654 90.579 155.654Z"
    [attr.fill]="'url(#paint1_radial_15_3113'+uid+')'" />
  <path
    d="M90.5791 94.723C98.4541 94.723 104.838 88.339 104.838 80.464C104.838 72.5889 98.4541 66.205 90.5791 66.205C82.704 66.205 76.3201 72.5889 76.3201 80.464C76.3201 88.339 82.704 94.723 90.5791 94.723Z"
    fill="white" />
  <path
    d="M73.1451 116.51H108.012C108.328 116.51 108.639 116.437 108.922 116.297C109.205 116.157 109.451 115.953 109.642 115.702C109.833 115.451 109.964 115.158 110.023 114.849C110.083 114.539 110.07 114.219 109.985 113.915L107.553 105.137C106.955 102.979 105.666 101.076 103.883 99.7207C102.1 98.3651 99.9227 97.631 97.6832 97.6309H83.474C81.2345 97.631 79.0569 98.3651 77.2743 99.7207C75.4917 101.076 74.2025 102.979 73.6041 105.137L71.172 113.915C71.0875 114.219 71.0747 114.539 71.1341 114.849C71.1936 115.158 71.324 115.451 71.515 115.702C71.706 115.953 71.9524 116.157 72.2352 116.297C72.518 116.437 72.8295 116.51 73.1451 116.51Z"
    fill="white" />
  <defs>
    <radialGradient [id]="'paint0_radial_15_3113'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.5781 91.3549) scale(90.457)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.252" class="rings" stop-color="#BBBBBB" stop-opacity="0.016" />
      <stop offset="0.455" class="rings" stop-color="#BBBBBB" stop-opacity="0.059" />
      <stop offset="0.641" class="rings" stop-color="#BBBBBB" stop-opacity="0.137" />
      <stop offset="0.816" class="rings" stop-color="#BBBBBB" stop-opacity="0.243" />
      <stop offset="0.982" class="rings" stop-color="#BBBBBB" stop-opacity="0.384" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
    <radialGradient [id]="'paint1_radial_15_3113'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.579 91.3559) scale(64.298)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.461" class="rings" stop-color="#BBBBBB" stop-opacity="0.01" />
      <stop offset="0.628" class="rings" stop-color="#BBBBBB" stop-opacity="0.039" />
      <stop offset="0.746" class="rings" stop-color="#BBBBBB" stop-opacity="0.094" />
      <stop offset="0.842" class="rings" stop-color="#BBBBBB" stop-opacity="0.169" />
      <stop offset="0.923" class="rings" stop-color="#BBBBBB" stop-opacity="0.267" />
      <stop offset="0.994" class="rings" stop-color="#BBBBBB" stop-opacity="0.388" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
  </defs>
</svg>