import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { ChartService } from 'src/app/features/dashboard/services/chart.service';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { colorSchemeBarChart } from '../../../shared/config/chart-color-scheme.constant';
import { colorScheme } from '../../../shared/config/color-scheme.constant';
import { StorageEnum } from '../../../shared/enums/storage.enum';
import {
  CurrentStatusSummary
} from '../../../shared/models/dashboard.model';
import { WearerGroupModel } from '../../../shared/models/wearer-group.model';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HeaderService } from 'src/app/core/services/header.service';
import { LoginResponseModel } from 'src/app/shared/models/login-response.model';
import { DIRECTION_RIGHT } from 'hammerjs';
import { CasparFrameComponent } from 'src/app/shared/components/caspar-frame/caspar-frame.component';
import { CasparService } from '../services/caspar.service';
import { DashboardWearerSummaryComponent } from '../../dashboard-wearer-summary/dashboard-wearer-summary.component';

//TODO: Incase of tab/phone, this component is getting loaded even though it not visible,
// And continuously making API calls with dashboard refresh. Need to handle this in future
@Component({
  standalone: true,
  selector: 'app-population-summary',
  templateUrl: './population-summary.component.html',
  styleUrls: ['./population-summary.component.scss'],
  imports: [CommonModule, IonicModule, NgxChartsModule],
})
export class PopulationSummaryComponent implements OnInit {
  private drawerExpanded = false;
  @Input()
  get expanded() {
    return this.drawerExpanded;
  }
  set expanded(expanded: boolean) {
    this.drawerExpanded = expanded;
  }
  @Input() expandHeight: any;

  private subscriptions: Subscription = new Subscription();

  // options
  showLabels = false;
  isDoughnut = true;

  // options for bar chart
  monthlyDetection: any = [];
  monthlyLocationEvents: any = [];
  detectionChartData: any = [];
  monthlyUsage: any = [];
  displayLocationChart = false;
  readonly animations = false;
  readonly roundEdges = false;
  readonly showXAxis =
    true; /* By default, this will show all labels, will need some workaround to only show first and last*/
  readonly showYAxis = false;
  readonly gradient = false;
  readonly showLegend = false;
  readonly showXAxisLabel = true;
  readonly showYAxisLabel = false;
  readonly showDataLabel = true;
  readonly barPadding: number = 6.0;

  startDate: any;
  barCustomColors: any = [];
  colorScheme = colorScheme;
  colorSchemeBarChart = colorSchemeBarChart;
  view: [number, number] = [100, 100];
  populationGroup = ['Temperature', 'Location'];
  wearerGroups = [1, 2, 3];
  chartParams: any;
  selectedPGroup = 'Temperature';
  notableWearerGroups: any;
  notableChartParams: any;
  residentsCount = 0;
  caregiversCount = 0;
  currentStatusSummary: CurrentStatusSummary | undefined = undefined;
  assetSummary: any = { total: 0, list: [], loading: false };
  bedSummary: any = { total: 0, list: [], loading: false };
  version = '';
  activeAlerts: any[] = [];
  isCasparEnabled = false;

  constructor(
    private dashboardService: DashboardService,
    private chartService: ChartService,
    private uiUtilityService: UiUtilityService,
    private storageService: StorageService,
    private headerService: HeaderService,
    private modalCtrl: ModalController,
    private casparService: CasparService
  ) { }

  getFormattedActiveAlerts(data: any) {
    const arr = Object.values(data);
    return arr.map((item: any) => Object.values(item)).flat().filter((item: any) => item.alert_id);
  }

  ngOnInit() {
    const selectedFacility = this.headerService.getCurrentFacility;
    this.isCasparEnabled = selectedFacility.enableCaspar;
    this.version = this.uiUtilityService.getVersion();
    this.didFacilityChange();
    this.displaySummaryChart();
    this.activeAlerts = this.getFormattedActiveAlerts(this.dashboardService.casparData);
    // Refresh the status summary chart as dashboard refresh.
    // This event get triggered even on facility change
    this.subscriptions.add(
      this.dashboardService.getDashBoardRefreshEvent().subscribe(() => {
        this.displaySummaryChart();
        // TODO: Need to discuss what needs regular refresh along with wearer list
        // This logic could be optimized
        this.getAssetSummary();
        this.getBedSummary();
      })
    );

    // Refresh the page on change of facility. In this case  except status summary chart,
    // everything is getting refreshed from here,  status summary chart has dependencies on dashboard data,
    // So refreshing it after dashboard refresh as this get triggers after fetching the latest data.
    this.subscriptions.add(
      this.headerService.getFacility().subscribe(() => {
        const selectedFacility = this.headerService.getCurrentFacility;
        this.isCasparEnabled = selectedFacility.enableCaspar;
        this.didFacilityChange();
      })
    );

    this.subscriptions.add(
      this.dashboardService.getCasparDataSub.subscribe((data: any) => {
        if (data) {
          this.activeAlerts = this.getFormattedActiveAlerts(data);
        }
      })
    )
  }

  getWearerGroups() {
    const loginData: any =
      this.storageService.getLocalStorage(StorageEnum.loginData, true) ||
      new LoginResponseModel();
    const groups =
      loginData.facility.find(
        (item: any) => item.facilityId === this.headerService.getFacilityId()
      )?.wearerGroupDefinition || [];
    return groups;
  }

  didFacilityChange() {
    //TODO: Need to discuss on wearer group logic for fetching notable event chart
    //This might be cleaned up too
    const wearerGroups =
      this.storageService.getSessionStorage(StorageEnum.filteredGroups, true) ||
      [];
    if (wearerGroups && wearerGroups.length) {
      this.wearerGroups = wearerGroups
        .filter((wearer: WearerGroupModel) => wearer.isOn === true)
        ?.map((wearer: WearerGroupModel) => wearer.value);
      if (this.wearerGroups.indexOf(0) != -1) {
        this.wearerGroups = wearerGroups
          .filter((wGroup: WearerGroupModel) => wGroup.id > 0)
          .map((wGroup: WearerGroupModel) => +wGroup.value); //  for all groups
      }
    } else {
      this.wearerGroups = [1, 2, 3];
    }
    const wearerGroupValues = this.getWearerGroups()?.map(
      (item: any) => item.id
    );
    const wearerGroupNameValues = this.getWearerGroups()?.map(
      (item: any) => item.id
    );
    this.notableWearerGroups = {
      wearerGroups: wearerGroupNameValues,
    };
    this.chartParams = { days: 30, wearerGroups: this.wearerGroups.toString() };
    this.notableChartParams = { days: 30, wearerGroups: wearerGroupValues };
    this.displayDetectionChart();
    this.getLocationAlertChartV2();
    this.getAssetSummary();
    this.getBedSummary();
  }

  getAssetSummary() {
    this.assetSummary.loading = true;
    this.dashboardService.getAssetSummary().subscribe((response: any) => {
      if (response && response.success && response.data?.length) {
        const total = response.data.reduce(
          (acc: any, item: any) => acc + item.total,
          0
        );
        this.assetSummary.total = total;
        this.assetSummary.list = response.data;
        this.assetSummary.loading = false;
      } else {
        this.assetSummary.total = 0;
        this.assetSummary.list = [];
        this.assetSummary.loading = false;
      }
    });
  }

  getBedSummary() {
    this.bedSummary.loading = true;
    this.dashboardService.getBedSummary().subscribe((response: any) => {
      if (response && response.success && response.data?.length) {
        const total = response.data.reduce(
          (acc: any, item: any) => acc + item.total,
          0
        );
        this.bedSummary.total = total;
        this.bedSummary.list = response.data;
        this.bedSummary.loading = false;
      } else {
        this.bedSummary.total = 0;
        this.bedSummary.list = [];
        this.bedSummary.loading = false;
      }
    });
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  /**
   * Forms the data needed for summary chart
   */
  displaySummaryChart() {
    this.currentStatusSummary = this.dashboardService.currentStatusSummary;
    if (this.currentStatusSummary?.Residents) {
      // We need to replace newWearer to offline entry, so story their index at the end of total count will do the replacement.
      let newWearerIndex = -1;
      let offlineIndex = -1;
      this.residentsCount =
        this.currentStatusSummary?.Residents?.reduce(
          (totalResidentsCount, statusEntry, index) => {
            if (statusEntry.name === 'New Wearer') {
              newWearerIndex = index;
            } else if (statusEntry.name === 'Healthy') {
              statusEntry.name = 'Okay';
            } else if (statusEntry.name === 'Offline') {
              offlineIndex = index;
            }
            return (totalResidentsCount += +statusEntry.value);
          },
          0
        ) || 0;

      // Increasing the count of offline Resident with new WearerResident
      if (newWearerIndex >= 0) {
        if (offlineIndex >= 0) {
          this.currentStatusSummary.Residents[offlineIndex].value = (
            parseInt(this.currentStatusSummary.Residents[offlineIndex].value) +
            parseInt(this.currentStatusSummary.Residents[newWearerIndex].value)
          ).toString();
          this.currentStatusSummary.Residents.splice(newWearerIndex, 1);
        } else {
          this.currentStatusSummary.Residents[newWearerIndex].name = 'Offline';
        }
      }
    }

    if (this.currentStatusSummary?.Caregivers) {
      // We need to replace newWearer to offline entry, so story their index at the end of total count will do the replacement.
      let newWearerIndex = -1;
      let offlineIndex = -1;
      this.caregiversCount =
        this.currentStatusSummary.Caregivers.reduce(
          (totalCareGiversCount, statusEntry, index) => {
            if (statusEntry.name === 'New Wearer') {
              newWearerIndex = index;
            } else if (statusEntry.name === 'Healthy') {
              statusEntry.name = 'Okay';
            } else if (statusEntry.name === 'Offline') {
              offlineIndex = index;
            }
            return (totalCareGiversCount += +statusEntry.value);
          },
          0
        ) || 0;

      // Increasing the count of offline CareGiver with new CareGiver
      if (newWearerIndex >= 0) {
        if (offlineIndex >= 0) {
          this.currentStatusSummary.Caregivers[offlineIndex].value = (
            parseInt(this.currentStatusSummary.Caregivers[offlineIndex].value) +
            parseInt(this.currentStatusSummary.Caregivers[newWearerIndex].value)
          ).toString();
          this.currentStatusSummary.Caregivers.splice(newWearerIndex, 1);
        } else {
          this.currentStatusSummary.Caregivers[newWearerIndex].name = 'Offline';
        }
      }
    }
  }

  getLocationAlertChartV2() {
    const wearerGroupValues = this.getWearerGroups()?.map(
      (item: any) => item.id
    );
    this.chartParams = { days: 30, wearerGroups: wearerGroupValues };
    this.chartService
      .getLocationAlertChartV2(this.chartParams)
      .subscribe((response) => {
        if (response.success) {
          this.monthlyLocationEvents = this.mergeSeriesForGroups(response.data, true);
          if (this.displayLocationChart) {
            this.detectionChartData = this.monthlyLocationEvents;
          }
        } else {
          const errorMsg: any = response.errorMessage;
          this.uiUtilityService.showAlert(errorMsg, 'Error');
        }
      });
  }

  /**
   * Forms the data needed for 30 days detection chart
   */
  displayDetectionChart() {
    if (this.displayLocationChart) {
      this.getLocationAlertChartV2();
    } else {
      this.chartService
        .getDetectionChartV2(this.notableChartParams)
        .subscribe((response) => {
          if (response.success) {
            // this.monthlyDetection = areaChartData[0].series;
            this.monthlyDetection = this.mergeSeriesForGroups(response.data);
            if (!this.displayLocationChart) {
              this.detectionChartData = this.monthlyDetection;
              // this.detectionChartData = response.data[0].series;
              this.startDate = this.detectionChartData[0].name;
            }
          } else {
            const errorMsg: any = response.errorMessage;
            this.uiUtilityService.showAlert(errorMsg, 'Error');
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /** show 30 days data with groups selected only
   * used to filter and merge values based on groups
   * @param data
   * @returns - array of json objects with Name and Value
   */
  mergeSeriesForGroups(data: any, allowAssets = false) {
    let series: any = [];
    const selectedGroups = this.notableWearerGroups.wearerGroups.toString();
    data.forEach((dataElem: any) => {
      const hasGroup = selectedGroups.includes(dataElem.wearerGroup) || (allowAssets && dataElem?.wearerGroup === 'assets');
      if (hasGroup) {
        series = [...series, ...dataElem.series];
      }
    });
    let result = series.reduce(
      (acc: any, curr: any) => {
        if (curr.name) {
          var fromMap = acc.map[curr.name];

          if (!fromMap) {
            acc.map[curr.name] = fromMap = {
              value: 0,
              name: curr.name,
            };
            acc.result.push(fromMap);
          }
          fromMap.value += parseInt(curr.value);
          fromMap.value = +fromMap.value ? fromMap.value : 0;
        } else {
          acc.result.push(curr);
        }
        return acc;
      },
      {
        map: {},
        result: [],
      }
    ).result;
    if (result.length) {
      result = result.map((item: any) => ({ ...item, value: item.value || '' }));
    }
    return result;
  }

  toggleCharts(type: string) {
    this.selectedPGroup = type;
    this.displayLocationChart = type === 'Temperature' ? false : true;
    this.detectionChartData = this.displayLocationChart
      ? this.monthlyLocationEvents
      : this.monthlyDetection;
    this.displayDetectionChart();
  }

  convertToKebabCase(stringToConvert: string) {
    return stringToConvert
      ? stringToConvert
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        ?.join('-')
        .toLowerCase()
      : stringToConvert;
  }

  onSwipePS(evt: any) {
    if (evt.offsetDirection !== DIRECTION_RIGHT) {
      return;
    }
    evt.srcEvent.stopPropagation();
    evt.srcEvent.preventDefault();
    if (this.uiUtilityService.isDesktopBreakpoint()) {
      return;
    }
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'right') {
      this.cancel();
    }
  }

  showPieChartInfo() {
    this.uiUtilityService.showAlert("A wearer with a band is Okay when the band is worn and the wearer's temperature is under their temperature threshold.<br><br>A wearer with a tag is Online when their tag location has been reported within the last 60 minutes.", "Info");
  }

  openWearerSummary(wearerId: string) {
    if (!wearerId) {
      return;
    }
    const entities = this.dashboardService.dashboardData;
    const wearer = entities.find((item: any) => item.wearerId === wearerId);
    this.showWearerSummary(wearer);
  }

  async launchCaspar(wearer?: any) {
    let params: any = {};
    if (wearer) {
      params = { patientId: wearer.patient_id };
    }
    const iframeSrc = this.casparService.getCasparIFrameParams(params);
    const modal = await this.modalCtrl.create({
      component: CasparFrameComponent,
      componentProps: {
        iframeSrc
      },
    });
    return await modal.present();
  }

  async showWearerSummary(data: any) {
    this.cancel();
    const wearerSummaryPopup = await this.modalCtrl.create({
      component: DashboardWearerSummaryComponent,
      componentProps: { data },
      cssClass: 'dashboard-summary-modals',
      mode: 'ios',
      showBackdrop: true,
      animated: false
    });
    await wearerSummaryPopup.present();
  }
}
