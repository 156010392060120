import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IonNav, IonicModule } from "@ionic/angular";
import { AuthService } from "src/app/core/services/auth.service";
import { HeaderService } from "src/app/core/services/header.service";
import { HeaderComponent } from "src/app/shared/components/header/header.component";
import { menuItemsReports } from "src/app/shared/config/app.constant";
import { ReportsService } from "./reports.service";
import { format, subDays } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { Subscription } from "rxjs";


@Component({
  standalone: true,
  selector: 'app-reports',
  templateUrl: './reports.page.html',
  styleUrls: ['./reports.page.scss'],
  imports: [CommonModule, FormsModule, IonicModule, RouterLink, ReactiveFormsModule, HeaderComponent],
})
export class ReportsPage implements OnInit, OnDestroy {
  timeZone = '';
  reportForm: FormGroup | null = null;
  wearerGroups: any = [];
  periods = [{
    name: 'day',
    displayName: 'Day'
  }];
  //   , {
  //   name: 'week',
  //     displayName: 'Week'
  // }, {
  //   name: 'month',
  //     displayName: 'Month'
  // }
  isDatePickerOpen = false;
  popoverEvent: any;
  @ViewChild(IonNav) ionNav: IonNav | undefined;
  rootPage: any;
  rootParam: any;
  @Input() data?: any;
  maxDate: any | undefined;
  minDate: any | undefined;
  private subscriptions: Subscription[] = [];

  constructor(private headerService: HeaderService, private formBuilder: FormBuilder, private authService: AuthService, private reportsService: ReportsService) {

  }

  ngOnInit() {
    this.rootPage = menuItemsReports[0].component;
    this.rootParam = {};
    this.reportsService.reportsPageChange.subscribe((data: any) => {
      this.rootPage = data.rootPage;
      this.rootParam = data.rootParam;
    });
    this.willCreateForm();
    this.subscriptions.push(this.headerService.getFacility().subscribe(() => {
      this.willCreateForm();
      this.updateFilter();
    }));
  }

  willCreateForm() {
    const selectedFacility = this.headerService.getCurrentFacility;
    this.timeZone = selectedFacility.timeZone;
    const loginData: any = this.authService.getLoginData;
    this.wearerGroups = loginData.facility.find((item: any) => item.facilityId === selectedFacility.facilityId)?.wearerGroupDefinition || [];
    this.createForm();
  }

  createForm() {
    this.reportsService.reportFilterData = {};
    const now = new Date();
    const zonedDate = toZonedTime(now, this.timeZone);
    const defaultDate1 = subDays(zonedDate, 1);
    this.maxDate = format(defaultDate1, 'yyyy-MM-dd');
    const defaultDate2 = subDays(defaultDate1, 89);
    this.minDate = format(defaultDate2, 'yyyy-MM-dd');
    this.reportForm = this.formBuilder.group({
      period: [this.periods[0].name],
      startFrom: [this.maxDate],
      wearerGroup: [this.wearerGroups?.length ? this.wearerGroups[0].id : null],
      isTemperature: [true],
      isBedPosition: [true],
    });
    this.reportsService.reportFilterData = this.reportForm?.getRawValue() || {};
  }

  get f() { return this.reportForm?.controls || {}; }

  openDatePicker(event: any) {
    this.popoverEvent = event;
    this.isDatePickerOpen = true;
  }

  closeDatePicker() {
    this.isDatePickerOpen = false;
  }

  onDateChange(event: any) {
    this.f['startFrom'].setValue(event.detail.value);
    this.closeDatePicker();
  }

  onWearerGroupChange(event: any) {
    const selected1 = event.target.value;
    const selected = this.wearerGroups.find((item: any) => item.id === selected1)?.wearerGroupName;
    const isCaregiver = selected?.toLowerCase() === 'caregiver' || selected?.toLowerCase() === 'caregivers';
    if (isCaregiver) {
      this.f['isTemperature'].setValue(true);
      this.f['isTemperature'].disable();
      this.f['isBedPosition'].setValue(false);
      this.f['isBedPosition'].disable();
    } else {
      this.f['isTemperature'].setValue(true);
      this.f['isTemperature'].enable();
      this.f['isBedPosition'].setValue(true);
      this.f['isBedPosition'].enable();
    }
  }

  updateFilter() {
    const val = this.reportForm?.getRawValue();
    this.reportsService.reportFilterData = val;
    this.reportsService.onUpdateFilter.next(val);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}