<app-header></app-header>

<ion-split-pane when="(min-width: 768px)" contentId="main-content" class="reports-pane">
  <!-- Left Pane (Menu) -->
  <ion-menu contentId="main-content">
    <ion-content>
      <div class="left-pane">
        <h3 class="heading">Wearer Report</h3>
        <div class="filter" *ngIf="reportForm">
          <form [formGroup]="reportForm">
            <h4 class="heading">Timeframe</h4>
            <p class="sub-heading">Facility time zone: {{timeZone}}</p>
            <ion-item lines="none">
              <ion-label class="select-label">Report period</ion-label>
              <ion-select slot="end" aria-label="Choose" placeholder="Choose" formControlName="period"
                interface="popover">
                <ion-select-option *ngFor="let pd of periods" [value]="pd.name">{{pd.displayName}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="none">
              <ion-label class="select-label">Starting from</ion-label>
              <ion-button fill="outline" class="date-btn" (click)="openDatePicker($event)">
                {{ f['startFrom']?.value ? (f['startFrom'].value | date: 'MMM d, y') : 'Select Date' }}
              </ion-button>
            </ion-item>
            <ion-popover [isOpen]="isDatePickerOpen" (didDismiss)="closeDatePicker()" [event]="popoverEvent">
              <ng-template>
                <ion-datetime mode="ios" (ionChange)="onDateChange($event)" [max]="maxDate" [min]="minDate"
                  [value]="f['startFrom']?.value" display-format="MM/DD/YYYY" placeholder="Select a Date"
                  presentation="date">
                </ion-datetime>
              </ng-template>
            </ion-popover>
            <hr class="divider">
            <ion-item lines="none">
              <ion-label class="select-label">Wearer group</ion-label>
              <ion-select slot="end" aria-label="Choose" placeholder="Choose" formControlName="wearerGroup"
                interface="popover" (ionChange)="onWearerGroupChange($event)">
                <ion-select-option *ngFor="let wG of wearerGroups" [value]="wG.id">{{wG.label}}</ion-select-option>
              </ion-select>
            </ion-item>
            <div class="checkbox-group">
              <ion-label class="checkbox-label">Report Data</ion-label>
              <ion-row>
                <ion-col>
                  <ion-checkbox color="controls" formControlName="isTemperature">Temperature</ion-checkbox>
                </ion-col>
                <ion-col>
                  <ion-checkbox color="controls" formControlName="isBedPosition">Bed
                    Positions</ion-checkbox>
                </ion-col>
              </ion-row>
            </div>
            <div class="update-filter">
              <ion-button (click)="updateFilter()"
                [disabled]="!f['isTemperature']?.value && !f['isBedPosition']?.value">Update</ion-button>
            </div>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-menu>

  <!-- Right Pane (Dynamic Content) -->
  <ion-nav #mainNav id="main-content" [root]="rootPage" [rootParams]="rootParam"></ion-nav>
</ion-split-pane>