export class WearerGroupModel {
  id = 0;
  name = '';
  value = 0;
  isOn = true;
  isDisabled = false;
  hasNotableWearer = false;
  filterKey = '';
  filterValue = '';
  wearerGroupName = '';
}

export interface WearerGroup {
  id: string;
  label: string;
  wearerGroupName: string;
}
