import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-map-resident',
  templateUrl: './floor-map-resident.component.html',
  styleUrls: ['../floor-dashboard.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class MapResident implements OnInit {
  @Input() locationPin: any = {};
  @Input() uid = '100';

  constructor() {

  }

  ngOnInit(): void {
  }
}