import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/core/services/filter.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { WG_OPTIONS_ALL, WG_OPTIONS_ASSETS } from '../../../shared/config/report.constant';
import { StorageEnum } from '../../../shared/enums/storage.enum';
import { FacilityModel } from '../../../shared/models/facility.model';
import { WearerGroupModel } from '../../../shared/models/wearer-group.model';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { UnifiedFilterComponent } from 'src/app/shared/components/unified-filter/unified-filter.component';
import { cloneDeep } from 'lodash-es';
import { dashboardFilterData } from 'src/app/shared/data/filter.data';
import { FILTER_SORT_TYPE } from 'src/app/shared/config/app.constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { HeaderService } from 'src/app/core/services/header.service';
import { DashboardService } from '../services/dashboard.service';

@Component({
  standalone: true,
  selector: 'app-wearer-group-filter',
  templateUrl: './wearer-group-filter.component.html',
  styleUrls: ['./wearer-group-filter.component.scss'],
  imports: [CommonModule, IonicModule],
})
export class WearerGroupFilterComponent implements OnInit {
  wearerGroups: WearerGroupModel[] = [];
  facility: FacilityModel = new FacilityModel();
  selectedGroup = this.dashboardService.selectedWearerGroup || 0;
  groups = cloneDeep(dashboardFilterData);
  subscriptions: Subscription[] = [];

  constructor(
    private storageService: StorageService,
    private filterService: FilterService,
    private modalCtrl: ModalController,
    private authService: AuthService,
    private headerService: HeaderService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.didOnInit();
    this.subscriptions.push(this.headerService.getFacility().subscribe(() => {
      this.dashboardService.selectedWearerGroup = 0;
      this.didOnInit();
    }));
  }

  didOnInit() {
    this.facility = this.storageService.getSessionStorage(
      StorageEnum.selectedFacility,
      true
    );
    this.storageService.removeSessionStorage(StorageEnum.filteredGroups);
    this.setGroups();
  }

  setGroups() {
    const storedGroups: WearerGroupModel[] =
      this.storageService.getSessionStorage(StorageEnum.filteredGroups, true) ||
      [];
    if (storedGroups?.length) {
      this.setWearerGroups(storedGroups);
    } else {
      const loginData: any = this.authService.getLoginData;
      const groups = loginData.facility.find((item: any) => item.facilityId === this.facility.facilityId)?.wearerGroupDefinition || [];
      const options: any[] = [];
      options.push(WG_OPTIONS_ALL);
      groups.forEach((item: any, index: number) => {
        options.push({ id: index + 1, name: item.label, filterKey: 'wearerGroup', filterValue: item.id, wearerGroupName: item.wearerGroupName });
      });
      options.push({ ...WG_OPTIONS_ASSETS, id: options.length });
      if (this.facility && this.facility.facilityId) {
        this.setWearerGroups(options);
      } else {
        this.setWearerGroups(options);
      }
    }
  }

  setWearerGroups(groups: any) {
    this.wearerGroups = [];
    groups.forEach((group: any) => {
      const wsGroup = { ...new WearerGroupModel(), ...group };
      wsGroup.name = group.name;
      wsGroup.value = group.id;
      this.wearerGroups.push(wsGroup);
    });
    this.setGroupDefaults();
  }

  setUpdatedGroups() {
    this.storageService.setSessionStorage(
      StorageEnum.filteredGroups,
      this.wearerGroups,
      true
    );
  }

  setGroupDefaults() {
    // console.debug('setGroupDefaults');
    setTimeout(() => {
      this.selectedGroup = this.dashboardService.selectedWearerGroup || 0;
      this.wearerGroups.forEach((g, i) => {
        g.isOn = this.selectedGroup === g.value;
      });
      this.filterService.setFilteredWearerGroup(this.wearerGroups);
      const filters = this.filterService.getFilterData(this.groups);
      this.filterService.setSortFilter(filters);
      this.setUpdatedGroups();
    }, 300);
  }

  applyFilter(value: number) {
    if (value >= 0 && value !== this.selectedGroup) {
      this.selectedGroup = value;
      this.dashboardService.selectedWearerGroup = value;
      this.wearerGroups.forEach((g, i) => {
        g.isOn = this.selectedGroup === g.value;
      });
      this.setUpdatedGroups();
      this.filterService.setFilteredWearerGroup(this.wearerGroups);
    }
  }

  async openFilter() {
    const modal = await this.modalCtrl.create({
      component: UnifiedFilterComponent,
      componentProps: {
        groups: this.groups,
      },
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      this.filterService.setSortFilter(data);
    } else if (data) {
      this.groups = data;
    }
  }
}
