<app-unified-header [title]="isAddFacility ? 'Add Facility' : editEnable ? 'Edit Facility Details' : 'Facility Details'"
  (onSave)="!editEnable && !isAddFacility ? enableEditMode() : manageFacility(isAddFacility ? 'post': 'put')"
  defaultHref="/facility-management" [customBack]="this.editEnable || this.isAddFacility" (onCustomBack)="backClick()"
  [saveBtn]="{title: !editEnable && !isAddFacility ? 'Edit' : 'Save', isDisable: !editEnable && !isAddFacility ? false : isSaveDisabled, rule: !isAddFacility ? 'put'+EndPoints.manageFacility : ''}"
  [isRootPage]="isRootPage && !editEnable"></app-unified-header>

<ion-content>
  <div *ngIf="facilityDetailsForm">
    <form [formGroup]="facilityDetailsForm">

      <!-- read mode -->
      <div class="coporate-details-edit" *ngIf="editEnable || isAddFacility">
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityName')">
          <ion-label>Facility Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityPhone')">
          <ion-label>Facility Phone</ion-label><ion-text>{{facilityDetailsForm?.get('facilityPhone')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityEmail')">
          <ion-label>Facility Email</ion-label><ion-text>{{facilityDetailsForm?.get('facilityEmail')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityRepresentativeName')">
          <ion-label>Facility Representative
            Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityRepresentativeTitle')">
          <ion-label>Facility Representative Title
          </ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}</ion-text>
        </ion-item>
        <ion-item>
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'timezone-dropdown'}"
            placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>

      <!-- edit mode -->
      <div class="coporate-details-edit" *ngIf="!editEnable && !isAddFacility">
        <ion-item class="form-item-detail">
          <ion-label>Facility Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Phone</ion-label><ion-text>{{facilityDetailsForm?.get('facilityPhone')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Email</ion-label><ion-text>{{facilityDetailsForm?.get('facilityEmail')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Representative
            Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Representative Title
          </ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}</ion-text>
        </ion-item>
        <ion-item class="disable-click">
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'timezone-dropdown'}"
            placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>

      <div class="wearer-item" *ngIf="!isAddFacility && !editEnable" [nAccess]="EndPoints.getFacilityFloorsList">
        <div>
          <ion-item detail class="first-item-border" (click)="viewFloorList()">
            <ion-label>Facility Floors</ion-label>
          </ion-item>
          <ion-row>
            <p class="notification-info corporatepanel">Optional. Add facility floors and floor plans here to use with
              features such as Location.</p>
          </ion-row>
        </div>
      </div>

      <!-- <div class="delete-wearer" *ngIf="!isAddFacility && !editEnable">
        <ion-button (click)="deleteFacility()" color="danger" [disabled]="true">Delete This Facility</ion-button>
      </div> -->
    </form>
  </div>
</ion-content>