import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-map-multiple',
  templateUrl: './floor-map-multiple.component.html',
  styleUrls: ['../floor-dashboard.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class MapMultiple implements OnInit {
  @Input() locationPin: any = {};
  @Input() uid = '200';

  constructor() {

  }

  ngOnInit(): void {
  }
}