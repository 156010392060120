<ion-header>
  <ion-toolbar mode="ios" class="non-modal-toolbar">
    <ion-buttons slot="start">
    </ion-buttons>
    <ion-title>Caspar</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="loading" class="loader-container">
    <ion-spinner name="crescent"></ion-spinner>
  </div>
  <iframe [src]="safeIframeSrc" width="100%" height="100%" frameborder="0" (load)="onIframeLoad()"></iframe>
</ion-content>