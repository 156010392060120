import { CommonModule } from "@angular/common";
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IonNav, IonicModule } from "@ionic/angular";
import { BaseChartDirective } from "ng2-charts";
import { HeaderService } from "src/app/core/services/header.service";
import { HeaderComponent } from "src/app/shared/components/header/header.component";
import { mockMonthReport, mockWeekReport } from "../mockdata";
import { cloneDeep, merge, mergeWith } from 'lodash-es';
import { Observable, Subscription, forkJoin } from "rxjs";
import { ReportsService } from "../reports.service";
import { UiUtilityService } from "src/app/core/services/ui-utility.service";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toZonedTime } from "date-fns-tz";
import { format, parse, subDays } from "date-fns";
import { defaultTempUnit } from "src/app/shared/config/temp-unit.constant";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  standalone: true,
  selector: 'app-wearer-report-details',
  templateUrl: './wearer-report-details.page.html',
  styleUrls: ['./wearer-report-details.page.scss'],
  imports: [CommonModule, FormsModule, IonicModule, RouterLink, ReactiveFormsModule, BaseChartDirective, HeaderComponent],
})
export class WearerReportDetailsPage implements OnInit, OnDestroy {
  @ViewChild('contentToDownload') content!: ElementRef;
  timeZone = '';
  facilityName = '';
  popoverEvent: any;
  private subscriptions: Subscription[] = [];
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective> | undefined;
  isTemperature = true;
  isBedPosition = true;
  wearerTitle = '';
  reportInputData: any = {};
  reportData: any = {};
  reportChartOptions: any = {};
  private mouseX = null;
  loading = false;
  tempUnitId = defaultTempUnit;
  backAngleScoreInfo = 'The average score for bed back angle compliance for the resident. The score is based on how long the resident\'s Connexio bed was in a Back Angle warning state, and includes each bed the resident was assigned to.';
  bedHeightScoreInfo = 'The average score for bed height compliance for the resident. The score is based on how long each Connexio bed in the facility was in a Bed Height Warning state, and includes each bed the resident was assigned to.';
  tempGraphInfo = 'The bar chart shows the resident\'s hourly average temperature, and the line graph shows the temperature readings. Hover over the chart to see the resident\'s hourly average temperature. Resident must have been wearing a Band to have any data.';
  backAngleGraphInfo = 'The line graph shows the back angle of the bed during the report period, and is color-coded according to the resident\'s bed alert settings (if enabled). Hover over the graph to see the resident\'s bed back angle measurement. Resident must have been assigned to a Connexio bed to have any data.';
  bedHeightGraphInfo = 'The line graph shows the height of the bed during the report period, and is color-coded according to the resident\'s bed alert settings (if enabled). Hover over the graph to see the resident\'s bed height measurement. Resident must have been assigned to a Connexio bed to have any data.';

  barChartPlugins: any = [
    {
      id: 'horizontalLinePlugin',
      afterDatasetsDraw(chart: any) {
        const { ctx, chartArea, scales } = chart;
        const dataset = chart.data.datasets[0];
        const yValue = dataset.threshold; // The Y-axis value for the horizontal line
        const label = dataset.thresholdFormatted; // The label text

        if (chartArea) {
          // Draw the horizontal line
          ctx.save();
          ctx.strokeStyle = '#4d4d4d'; // Line color
          ctx.lineWidth = 2;
          ctx.beginPath();
          const yPixel = scales.y.getPixelForValue(yValue);
          ctx.moveTo(chartArea.left - 8, yPixel);
          ctx.lineTo(chartArea.right, yPixel);
          ctx.stroke();

          // Draw the label
          ctx.fillStyle = '#4d4d4d'; // Label color
          ctx.font = '12px Arial'; // Label font
          ctx.textAlign = 'right'; // Align text to the right
          ctx.textBaseline = 'middle'; // Vertically center the text
          ctx.fillText(label, chartArea.left - 12, yPixel); // Position label slightly left of the chart area
          ctx.restore();
        }
      },
    }
  ];

  barChartPluginsWithLineGraph: any = [
    {
      id: 'horizontalLinePlugin',
      afterDatasetsDraw: (chart: any) => {
        const { ctx, chartArea, scales } = chart;
        const dataset = chart.data.datasets[0];
        if (!dataset) {
          return;
        }
        const yValue = this.tempUnitId === 1 ? 38 : 100.4; // dataset.threshold; // The Y-axis value for the horizontal line
        const label = this.tempUnitId === 1 ? '38°C' : '100.4°F'; // dataset.thresholdFormatted; // The label text

        const yValue1 = this.tempUnitId === 1 ? 40 : 104; // dataset.threshold; // The Y-axis value for the horizontal line
        const label1 = this.tempUnitId === 1 ? '40°C' : '104°F'; // dataset.thresholdFormatted; // The label text

        if (chartArea) {
          // Draw the horizontal line
          ctx.save();
          ctx.strokeStyle = '#E2E2E2'; // Line color
          ctx.lineWidth = 1;
          ctx.beginPath();
          const yPixel = scales.y.getPixelForValue(yValue);
          ctx.moveTo(chartArea.left - 4, yPixel);
          ctx.lineTo(chartArea.right, yPixel);
          ctx.stroke();

          // Draw the label
          ctx.fillStyle = '#808080'; // Label color
          ctx.font = '12px Arial'; // Label font
          ctx.textAlign = 'right'; // Align text to the right
          ctx.textBaseline = 'middle'; // Vertically center the text
          ctx.fillText(label, chartArea.left - 4, yPixel); // Position label slightly left of the chart area
          ctx.restore();

          // Draw the horizontal line
          ctx.save();
          ctx.strokeStyle = '#E2E2E2'; // Line color
          ctx.lineWidth = 1;
          ctx.beginPath();
          const yPixel1 = scales.y.getPixelForValue(yValue1);
          ctx.moveTo(chartArea.left - 4, yPixel1);
          ctx.lineTo(chartArea.right, yPixel1);
          ctx.stroke();

          // Draw the label
          ctx.fillStyle = '#808080'; // Label color
          ctx.font = '12px Arial'; // Label font
          ctx.textAlign = 'right'; // Align text to the right
          ctx.textBaseline = 'middle'; // Vertically center the text
          ctx.fillText(label1, chartArea.left - 4, yPixel1); // Position label slightly left of the chart area
          ctx.restore();
        }
      },
    },
    // {
    //   id: 'LinePlugin',
    //   beforeDraw: (chart: any) => {
    //     const ctx = chart.ctx;
    //     const dataset = chart.data.datasets[1];
    //     if (!dataset) {
    //       return;
    //     }
    //     const data = dataset?.data || [];
    //     const lineColors = dataset?.lineColors || [];
    //     // Loop through each segment of the line
    //     for (let i = 0; i < data.length - 1; i++) {
    //       const startX = chart.scales.x.getPixelForValue(i);
    //       const startY = chart.scales.y.getPixelForValue(data[i]);
    //       const endX = chart.scales.x.getPixelForValue(i + 1);
    //       const endY = chart.scales.y.getPixelForValue(data[i + 1]);

    //       // Calculate the color based on the value
    //       const color = lineColors[i];

    //       // Set the line color dynamically for each segment
    //       ctx.beginPath();
    //       ctx.moveTo(startX, startY);
    //       ctx.lineTo(endX, endY);
    //       ctx.strokeStyle = color;
    //       ctx.lineWidth = 1;
    //       ctx.stroke();
    //     }
    //   }
    // },
    // {
    //   id: 'trackingLineWithTooltip',
    //   afterEvent: (chart: any, args: any) => {
    //     const event = args.event;

    //     if (event.native) {
    //       this.mouseX = event.native.offsetX;
    //       chart.draw();
    //     }
    //   },
    //   afterDatasetsDraw: (chart: any) => {
    //     const { ctx, chartArea, scales } = chart;

    //     if (!chartArea || !ctx || this.mouseX === null) return;

    //     ctx.save();
    //     ctx.beginPath();
    //     ctx.moveTo(this.mouseX, chartArea.top);
    //     ctx.lineTo(this.mouseX, chartArea.bottom);
    //     ctx.lineWidth = 1;
    //     ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
    //     ctx.stroke();
    //     ctx.restore();
    //     const xScale = scales['x'];
    //     const dataIndex = xScale.getValueForPixel(this.mouseX);

    //     if (dataIndex !== undefined && dataIndex >= 0 && dataIndex < chart.data.labels.length) {
    //       const yScale = scales['y'];
    //       const dataset = chart.data.datasets[0];
    //       console.debug(chart.data);
    //       const value = dataset.data[dataIndex];
    //       const y = yScale.getPixelForValue(value);

    //       ctx.save();
    //       ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
    //       ctx.fillRect(this.mouseX + 10, y, 60, 30);
    //       ctx.fillStyle = '#fff';
    //       ctx.font = '12px Arial';
    //       ctx.fillText(`Avg: ${value}`, this.mouseX + 20, y + 18);
    //       ctx.restore();
    //     }
    //   },
    // }
  ];

  constructor(private headerService: HeaderService, private uiUtilityService: UiUtilityService, private reportsService: ReportsService, private nav: IonNav, private authService: AuthService) {

  }

  ngOnInit() {
    this.tempUnitId = this.authService.getLoginData?.tempUnit;
    this.reportData = cloneDeep(this.reportInputData);
    this.formatReportData(this.reportData?.data, true);
    const selectedFacility = this.headerService.getCurrentFacility;
    this.timeZone = selectedFacility.timeZone;
    this.facilityName = selectedFacility.facilityName;
    const reportFilterData = this.reportsService.reportFilterData;
    if (reportFilterData.period) {
      this.didSubmitFilter(reportFilterData);
    }
    this.setData();
    this.wearerTitle = this.uiUtilityService.getEntityDisplayName(this.reportData.data || {});
    this.subscriptions.push(
      this.reportsService.onUpdateFilter.subscribe((data: any) => {
        this.onSubmitFilter(data);
      })
    )
    this.subscriptions.push(this.headerService.getFacility().subscribe(() => {
      this.navigateBack();
    }));
  }

  getMergedData(data?: any): Observable<any[]> {
    const now = new Date();
    const zonedDate = toZonedTime(now, this.timeZone);
    const defaultDate1 = subDays(zonedDate, 1);
    let day = format(defaultDate1, 'MMddyyyy');
    if (data?.startFrom) {
      const parsedDate = parse(data.startFrom, 'yyyy-MM-dd', new Date());
      day = format(parsedDate, 'MMddyyyy');
    }
    const bedReq = this.reportsService.getDailyBedGraph({ day, timeZone: this.timeZone });
    const tempReq = this.reportsService.getTempBedGraph({ day, timeZone: this.timeZone });
    let allReq = [bedReq, tempReq];
    if (!data?.isTemperature && data?.isBedPosition) {
      allReq = [bedReq];
    } else if (data?.isTemperature && !data?.isBedPosition) {
      allReq = [tempReq];
    }
    return forkJoin(allReq);
  }

  formatReportData(reportData: any = {}, isInit = false) {
    if (reportData.tempTrendsGraph?.labels?.length) {
      const count = (reportData.tempTrendsGraph.labels.length - 1) / 4;
      for (let i = 0; i < reportData.tempTrendsGraph.labels.length; i++) {
        if (i % count !== 0) {
          reportData.tempTrendsGraph.labels[i] = '';
        }
      }
      if (reportData.avgTemp && reportData.avgTemp !== 'NaN' && !isInit) {
        reportData.avgTemp = this.tempUnitId === 1 ? reportData.avgTemp : this.uiUtilityService.celsiusToFahrenheit(reportData.avgTemp);
        reportData.avgTempFormatted = this.tempUnitId === 1 ? `${reportData.avgTemp}°C` : `${reportData.avgTemp}°F`;
      }
      if (reportData.tempTrendsGraph?.datasets?.length) {
        const datasets = reportData.tempTrendsGraph.datasets;
        const dataset = datasets.find((item: any) => item.type === 'bar');
        if (dataset?.data?.length) {
          const formattedVal = isInit ? dataset.data : dataset.data.map((item: any) => this.tempUnitId === 1 ? item : this.uiUtilityService.celsiusToFahrenheit(item));
          const repeatVal = formattedVal.flatMap((value: any) => [
            ...Array(30).fill(null),
            ...Array(30).fill(value)
          ]);
          const repeatbg = dataset.backgroundColor.flatMap((value: any) => [
            ...Array(30).fill(''),
            ...Array(30).fill(value)
          ]);
          dataset.data = repeatVal;
          dataset.backgroundColor = repeatbg;
        }
        const datasetLine = datasets.find((item: any) => item.type === 'line');
        if (datasetLine?.data?.length) {
          const formattedVal = datasetLine.data.map((item: any) => null); // this.tempUnitId === 1 ? item : this.uiUtilityService.celsiusToFahrenheit(item));
          datasetLine.data = formattedVal;
        }
      }
    }
    if (reportData.bedHeightGraph?.labels?.length) {
      const count = (reportData.bedHeightGraph.labels.length - 1) / 4;
      for (let i = 0; i < reportData.bedHeightGraph.labels.length; i++) {
        if (i % count !== 0) {
          reportData.bedHeightGraph.labels[i] = '';
        }
      }
    }
    if (reportData.backAngleGraph?.labels?.length) {
      const count = (reportData.backAngleGraph.labels.length - 1) / 4;
      for (let i = 0; i < reportData.backAngleGraph.labels.length; i++) {
        if (i % count !== 0) {
          reportData.backAngleGraph.labels[i] = '';
        }
      }
    }
  }

  getData(data?: any) {
    this.loading = true;
    this.getMergedData(data).subscribe({
      next: (results: any) => {
        this.loading = false;
        let resp = results[0];
        let resp1: any = {};
        if (results.length > 1) {
          resp1 = results[1];
        }
        if (results.length > 1 && resp?.data && resp1?.data) {
          resp = mergeWith({}, resp?.data || {}, resp1?.data || {}, this.reportsService.deepMergeById);
        } else {
          resp = resp?.data;
        }
        const reportData = resp.data.find((item: any) => item.id === this.reportData.data?.id);
        if (reportData) {
          const reportDataClone = cloneDeep(this.reportData);
          if (resp.timeframe) {
            reportDataClone['timeframe'] = resp.timeframe;
          }
          const reportDataClone1 = cloneDeep(reportData);
          this.formatReportData(reportDataClone1);
          reportDataClone['data'] = reportDataClone1;
          this.reportData = reportDataClone;
        } else {
          this.navigateBack();
        }
        this.setData();
      }, error: () => {
        this.loading = false;
      }
    });
  }

  onSubmitFilter(data: any) {
    if (data.period === 'day') {
      this.getData(data);
    } else if (data.period === 'week') {
      this.reportData = cloneDeep(mockWeekReport);
      this.setData();
    } else if (data.period === 'month') {
      this.reportData = cloneDeep(mockMonthReport);
      this.setData();
    }
    this.didSubmitFilter(data);
  }

  didSubmitFilter(data: any) {
    this.isTemperature = data.isTemperature;
    this.isBedPosition = data.isBedPosition;
    this.charts?.forEach((chart) => {
      chart.chart?.resize();
    });
  }

  setData() {
    const firstRecord = this.reportData?.data || {};
    const graphs = ['tempTrendsGraph', 'backAngleGraph', 'bedHeightGraph'];
    for (const tableRow of graphs) {
      this.reportChartOptions[tableRow] = this.getChartOptions(firstRecord[tableRow], tableRow);
    }
    // do only for day
    if (firstRecord['tempTrendsGraph']) {
      const tempGraphData = firstRecord['tempTrendsGraph'].datasets[0];
      if (Array.isArray(tempGraphData.backgroundColor)) {
        const colors: any = [];
        for (const color of tempGraphData.backgroundColor) {
          colors.push(color ? this.uiUtilityService.hexToRGB(color, '1') : '');
        }
        tempGraphData['backgroundColor'] = colors;
      }
    }
    for (const tableRow of graphs) {
      if (tableRow) {
        this.setFormattedData(this.reportData.data[tableRow], tableRow);
      }
    }
    // console.debug(this.reportData);
  }

  setFormattedData(data: any, type: string) {
    if (data?.category === 'bar' && data.datasets?.length && type === 'tempTrendsGraph') {
      for (const data1 of data.datasets) {
        // data1.barThickness = 5;
        data1.barPercentage = 1.0;
        data1.categoryPercentage = 1.0;
      }
    }
    if (data?.category === 'stepped-line' && data.datasets?.length) {
      for (const data1 of data.datasets) {
        data1.fill = false;
        data1.borderColor = 'transparent';
        data1.tension = 0.4;
        data1.stepped = 'after';
      }
    }
  }

  formatYTicks(type: string, value: any) {
    let val = value;
    if (type === 'backAngleGraph') {
      val = `${value}°`;
    } else if (type === 'bedHeightGraph') {
      val = `${value}"`;
    }
    return val;
  }

  getChartOptions(config: any, type: string) {
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0, // Disables animation
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true, // Disable the default tooltip
        }
      },
      scales: {
        x: {
          ticks: {
            beginAtZero: true,
            maxRotation: 0, // Rotate labels up to 45 degrees
            minRotation: 0,
            autoSkip: false,
          },
          grid: {
            display: false, // Hides vertical gridlines
          },
          title: {
            display: false, // Hides the x-axis label
          },
        },
        y: {
          min: 0,
          suggestedMin: 0,
          ticks: {
            beginAtZero: true,
            callback: (value: any) => this.formatYTicks(type, value),
          },
          title: {
            display: false, // Hides the x-axis label
          },
        },
      },
      layout: {
        padding: {
          left: 36, // Adjust this value to leave space on the left
          top: 20
        },
      },
      elements: {
        point: {
          radius: 0, // Hides all points
        }
      },
    };
    if (type === 'tempTrendsGraph') {
      options = merge(options, {
        scales: {
          y: {
            min: this.tempUnitId === 1 ? 32 : 89.6,
            max: this.tempUnitId === 1 ? 40 : 104
          }
        }
      });
    }
    if (type === 'backAngleGraph') {
      options = merge(options, {
        scales: {
          y: {
            max: 70,
            ticks: {
              stepSize: 35,
            }
          }
        }
      });
    }
    if (type === 'bedHeightGraph') {
      options = merge(options, {
        scales: {
          y: {
            max: 32,
            ticks: {
              stepSize: 8,
            }
          }
        }
      });
    }
    if (config?.category === 'bar') {
      options = merge(options, {
        scales: {
          y: {
            grid: {
              display: false, // Hides horizontal gridlines
            },
            ticks: {
              display: false,
            },
          }
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
        }
      });
    } else if (config?.category === 'stacked-bar') {
      options = merge(options, {
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true,
          }
        }
      });
    } else if (config?.category === 'stepped-line') {

    }
    return options;
  }

  getSegmentColor(currentValue: number, breakpoints: any = []) {
    if (breakpoints?.length) {
      const sorted = breakpoints.sort((a: any, b: any) => b.value - a.value);
      let color = sorted.find((item: any) => item.default)?.color || '';
      for (const point of sorted) {
        if (point.aggregator === '==' && currentValue == point.value) {
          color = point.color;
          break;
        } else if (point.aggregator === '<' && currentValue < point.value) {
          color = point.color;
        } else if (point.aggregator === '>' && currentValue > point.value) {
          color = point.color;
          break;
        }
      }
      return color;
    }
  }

  lineChartPlugins = [
    {
      id: 'LinePlugin',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const dataset = chart.data.datasets[0];
        const data = dataset.data;
        const breakpoints = chart.data.breakpoints;

        // Loop through each segment of the stepped line
        for (let i = 0; i < data.length - 1; i++) {
          const startX = chart.scales.x.getPixelForValue(i);
          const startY = chart.scales.y.getPixelForValue(data[i]);
          const endX = chart.scales.x.getPixelForValue(i + 1);
          const endY = chart.scales.y.getPixelForValue(data[i + 1]);

          // Calculate the color based on the value
          const color = this.getSegmentColor(data[i], breakpoints);

          // Draw horizontal line (stepping right)
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(startX, endY);  // Step vertically
          ctx.strokeStyle = color;
          ctx.lineWidth = 3;
          ctx.stroke();

          const hcolor = this.getSegmentColor(data[i + 1], breakpoints);

          // Draw vertical line (stepping up or down)
          ctx.beginPath();
          ctx.moveTo(startX, endY);
          ctx.lineTo(endX, endY);  // Step horizontally
          ctx.strokeStyle = hcolor;
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      }
    },
    // {
    //   id: 'trackingLine', // Unique identifier for the plugin
    //   afterEvent: (chart: Chart, args: { event: any }) => {
    //     const { ctx, chartArea } = chart;
    //     const event = args.event;

    //     // Ensure valid chart area and mouse event
    //     if (!chartArea || !ctx || !event.native) {
    //       return;
    //     }

    //     // Get cursor X-coordinate
    //     const x = event.native.offsetX;

    //     // Clear and redraw chart to avoid duplicate lines
    //     chart.draw();

    //     // Draw the tracking vertical line
    //     ctx.save();
    //     ctx.beginPath();
    //     ctx.moveTo(x, chartArea.top);
    //     ctx.lineTo(x, chartArea.bottom);
    //     ctx.lineWidth = 1; // Line thickness
    //     ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)'; // Light gray line
    //     ctx.stroke();
    //     ctx.restore();
    //   }
    // }
  ];

  navigateBack() {
    this.nav.pop();
  }

  downloadPDF() {
    const div = this.content.nativeElement;

    html2canvas(div).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, mm units, A4 size

      // Dimensions of the A4 page in pixels (for 72 DPI)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasHeight = canvas.height;
      const canvasWidth = canvas.width;

      // Calculate the scale factor to fit the canvas width into the PDF width
      const scaleFactor = pdfWidth / canvasWidth;
      const imgHeight = canvasHeight * scaleFactor;

      let yOffset = 0;

      while (yOffset < imgHeight) {
        // Get the portion of the canvas for the current page
        const pageCanvas = document.createElement('canvas');
        const context = pageCanvas.getContext('2d');

        // Set the dimensions of the page canvas
        pageCanvas.width = canvasWidth;
        pageCanvas.height = pdfHeight / scaleFactor;

        // Draw the corresponding part of the original canvas onto the page canvas
        context?.drawImage(
          canvas,
          0,
          yOffset / scaleFactor,
          canvasWidth,
          pageCanvas.height,
          0,
          0,
          canvasWidth,
          pageCanvas.height
        );

        // Convert the page canvas to an image
        const pageImgData = pageCanvas.toDataURL('image/png');
        pdf.addImage(pageImgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        yOffset += pdfHeight;

        if (yOffset < imgHeight) {
          pdf.addPage(); // Add a new page for the next portion
        }
      }

      // Generate a timestamp for the filename
      const now = new Date();
      const zonedDate = toZonedTime(now, this.timeZone);
      const day = format(zonedDate, 'yyyy-MM-dd HH-mm-ss');

      // Save the PDF
      pdf.save(`${this.facilityName}-${this.wearerTitle}-${day}.pdf`);
    });
  }

  onInfo(msg: string) {
    this.uiUtilityService.showAlert(msg, 'Info');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}