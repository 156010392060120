<svg [ngClass]="[locationPin.className?.loc || '', locationPin.className?.temp || '']" width="181" height="181"
  viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="circle"
    d="M90.458 132.125C113.47 132.125 132.125 113.47 132.125 90.458C132.125 67.446 113.47 48.791 90.458 48.791C67.446 48.791 48.791 67.446 48.791 90.458C48.791 113.47 67.446 132.125 90.458 132.125Z"
    fill="#BBBBBB" />
  <path opacity="0.7"
    d="M90.457 180.914C140.415 180.914 180.914 140.415 180.914 90.457C180.914 40.499 140.415 0 90.457 0C40.499 0 0 40.499 0 90.457C0 140.415 40.499 180.914 90.457 180.914Z"
    [attr.fill]="'url(#paint0_radial_167_16467'+uid+')'" />
  <path opacity="0.7"
    d="M90.4569 154.755C125.968 154.755 154.755 125.968 154.755 90.4571C154.755 54.9463 125.968 26.1591 90.4569 26.1591C54.9461 26.1591 26.1589 54.9463 26.1589 90.4571C26.1589 125.968 54.9461 154.755 90.4569 154.755Z"
    [attr.fill]="'url(#paint1_radial_167_16467'+uid+')'" />
  <path
    d="M111.864 113.012L109.431 104.235C108.833 102.077 107.544 100.174 105.762 98.8185C103.979 97.4629 101.801 96.7288 99.562 96.729H94.5258L90.4558 101.5L86.3869 96.729H81.3518C79.1122 96.7288 76.9345 97.4627 75.1518 98.8184C73.3692 100.174 72.08 102.077 71.4819 104.235L69.05 113.012C68.9648 113.316 68.9511 113.636 69.0102 113.947C69.0693 114.258 69.1995 114.55 69.3906 114.802C69.5817 115.054 69.8286 115.258 70.1118 115.399C70.3949 115.539 70.7068 115.612 71.0229 115.612H109.889C110.205 115.612 110.517 115.539 110.8 115.399C111.084 115.258 111.331 115.054 111.522 114.802C111.713 114.551 111.844 114.258 111.903 113.947C111.962 113.637 111.949 113.317 111.864 113.012Z"
    fill="white" />
  <path
    d="M90.458 93.824C98.333 93.824 104.717 87.4401 104.717 79.565C104.717 71.69 98.333 65.306 90.458 65.306C82.5829 65.306 76.199 71.69 76.199 79.565C76.199 87.4401 82.5829 93.824 90.458 93.824Z"
    fill="white" />
  <path class="circle"
    d="M99.8189 112.857C99.3119 112.35 97.351 110.266 96.363 109.073C95.172 107.885 94.4512 106.307 94.334 104.629C94.3256 104.27 94.3882 103.914 94.5183 103.579C94.6484 103.245 94.8433 102.94 95.0918 102.681C95.3403 102.422 95.6377 102.215 95.9666 102.072C96.2954 101.929 96.6491 101.852 97.0078 101.846C97.6516 101.837 98.2799 102.045 98.792 102.435C99.3041 102.825 99.6707 103.376 99.833 103.999H99.8469C100.006 103.374 100.371 102.821 100.884 102.43C101.397 102.039 102.027 101.833 102.672 101.846C103.031 101.852 103.385 101.929 103.713 102.072C104.042 102.215 104.34 102.422 104.588 102.681C104.837 102.94 105.032 103.245 105.162 103.579C105.292 103.914 105.354 104.27 105.346 104.629C105.228 106.307 104.507 107.886 103.316 109.073C102.329 110.266 100.354 112.35 99.8469 112.857H99.8189Z"
    fill="#BBBBBB" />
  <defs>
    <radialGradient [id]="'paint0_radial_167_16467'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.457 90.457) scale(90.457)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.252" class="rings" stop-color="#BBBBBB" stop-opacity="0.016" />
      <stop offset="0.455" class="rings" stop-color="#BBBBBB" stop-opacity="0.059" />
      <stop offset="0.641" class="rings" stop-color="#BBBBBB" stop-opacity="0.137" />
      <stop offset="0.816" class="rings" stop-color="#BBBBBB" stop-opacity="0.243" />
      <stop offset="0.982" class="rings" stop-color="#BBBBBB" stop-opacity="0.384" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
    <radialGradient [id]="'paint1_radial_167_16467'+uid" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(90.4569 90.4571) scale(64.298)">
      <stop class="rings" stop-color="#BBBBBB" stop-opacity="0" />
      <stop offset="0.461" class="rings" stop-color="#BBBBBB" stop-opacity="0.01" />
      <stop offset="0.628" class="rings" stop-color="#BBBBBB" stop-opacity="0.039" />
      <stop offset="0.746" class="rings" stop-color="#BBBBBB" stop-opacity="0.094" />
      <stop offset="0.842" class="rings" stop-color="#BBBBBB" stop-opacity="0.169" />
      <stop offset="0.923" class="rings" stop-color="#BBBBBB" stop-opacity="0.267" />
      <stop offset="0.994" class="rings" stop-color="#BBBBBB" stop-opacity="0.388" />
      <stop offset="1" class="rings" stop-color="#BBBBBB" stop-opacity="0.4" />
    </radialGradient>
  </defs>
</svg>