<ion-content>
  <div class="container">
    <div class="asset-summary">
      <ion-grid class="ion-no-padding title">
        <ion-row class="ion-justify-content-space-between padding-top">
          <ion-col size="9">
            <p class="asset-summary-header">{{ assetDetails.displayName }}</p>
          </ion-col>
          <ion-col size="3" class="edit-link">
            <!-- <ion-icon class="asset-icon" src="assets/icon/Assets.svg"></ion-icon> -->
            <span class="edit-btn" (click)="navigateToDetailsPage()" [nAccess]="'getAssetDetail'">Edit</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <p class="asset-group-name">Asset</p>
        </ion-row>
        <ion-row class="tag-name-row" [ngClass]="[locationState?.class, 'state', 'ion-align-items-center']"
          *ngIf="locationState?.label">
          {{locationState.label}}
          <ion-icon id="click-locationState" class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
            (click)="showLocStateInfo(locationState)"></ion-icon>
          <!-- <ion-popover trigger="click-locationState" triggerAction="click">
            <ng-template>
              <ion-content class="ion-padding">{{locationState.assetDescription}}</ion-content>
            </ng-template>
          </ion-popover> -->
        </ion-row>
        <ion-row class="tag-name-row" [class]="[bandState.class, 'state', 'ion-align-items-center']"
          *ngIf="bandState?.label">
          {{bandState.label}}
          <ion-icon id="click-bandState" class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
            (click)="showTagStateInfo(bandState)"></ion-icon>
        </ion-row>
      </ion-grid>
      <ion-grid class="ion-no-padding">
        <div class="resident-details-box">
          <ion-row class="room-name-row ion-align-items-center" *ngIf="assetDetails.roomName">
            <ion-col (click)="showRoomSummary()" class="col">
              <ion-icon class="state-icon entity-icon entity-icon-stateless"
                src="assets/icon/room-location.svg"></ion-icon>
              <span class="room-name-col"
                [ngClass]="{ 'route-link': assetDetails.roomId, 'no-link': !isRoomSummaryEnabled }">{{
                assetDetails.roomName
                }}</span>
            </ion-col>
          </ion-row>
          <ion-row class="location-state-row" [ngClass]="['ion-align-items-center']"
            *ngIf="assetDetails.lastTempTime != null">
            <ion-col class="col">
              <ion-icon class="state-icon entity-icon"
                [src]="!assetDetails.location ? 'assets/icon/pin-off.svg' : 'assets/icon/pin.svg'"
                [style.color]="assetDetails.mapPinColor"></ion-icon>
              <!-- [src]="locationState?.svgIcon ? 'assets/icon/'+locationState.svgIcon : 'assets/icon/pin_ok.svg'" -->
              <span class="location-label">Updated {{ lastUpdatedTime.value }}</span>
            </ion-col>
          </ion-row>
          <ion-row class="location-state-row" [ngClass]="['ion-align-items-center']"
            *ngIf="assetDetails.bandState === 4">
            <ion-col class="col">
              <ion-icon class="state-icon entity-icon" src="assets/icon/low-battery.svg"></ion-icon>
              <span class="location-label">
                {{assetDetails.isTag ? 'Tag' : 'Band'}} battery low
              </span>
            </ion-col>
          </ion-row>
        </div>
        <div class="divider"></div>
        <ion-row class="details-row">
          <ion-col>
            <h3 class="notable-event-title">
              Notable Events
            </h3>
            <div *ngIf="assetNotableEvents.length" id="notable-events-content">
              <div class="notable-lines" *ngFor="let reading of assetNotableEvents">
                {{eventStrings.locationLastSeenAtAsset }}
                {{ eventStrings.at }} {{ reading.notableTime }}
              </div>
            </div>
            <p id="notable-events-content" class="no-notable-events" *ngIf="!assetNotableEvents.length">
              No notable events detected
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>