import { Component, OnDestroy, OnInit } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { DashboardWearerSummaryComponent } from '../../dashboard-wearer-summary/dashboard-wearer-summary.component';
import { SettingsService } from '../../settings/services/settings.service';
import { REFRESH_INTERVAL, ROOM_SUMMARY_REFRESH_INTERVAL } from 'src/app/shared/config/report.constant';
import { RouterModule } from '@angular/router';
import { RoomDetailsPage } from '../../settings/room-management/room-details/room-details.page';
import { MODAL_PAGE_TYPE } from 'src/app/shared/config/app.constant';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { BedDetailsPage } from '../../bed-management/pages/bed-details/bed-details.page';
import { notableColors } from 'src/app/shared/enums/color-classes';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
import { BED_IN, BED_OUT, CASPAR_ICON_ORDER } from 'src/app/shared/config/caspar.contant';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  standalone: true,
  selector: 'app-room-summary',
  templateUrl: './room-summary.component.html',
  styleUrls: ['./room-summary.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AccessControlDirective,
  ],
})
export class RoomSummaryComponent implements OnInit, OnDestroy {
  readonly barPadding: number = 3.5;
  data: any = new DashboardData();
  activeSegment: string = 'bed';
  bedData: any = {};
  roomDetails: any = {};
  private subscriptions: Subscription[] = [];
  pollingIntervalTime: number = REFRESH_INTERVAL;
  showSpinner = true;
  commonRoom = true;
  subTitle: string = '';
  dashBoardData: DashboardData[] = [];
  dashBoardDataCopy: DashboardData[] = [];
  beds: any[] = [];
  bedStates: any[] = [
    {
      label: '1',
      id: 1,
      icon: 'assets/svg/bed-state-1',
      pos: '',
      lock: 'backLockOut',
      headRestAngle: 20,
      class: ''
    }, {
      label: '2',
      id: 2,
      icon: 'assets/svg/bed-state-2',
      pos: '',
      lock: 'kneeLockOut',
      footRestAngle: 30,
      centerRestAngle: -25,
      class: 'knee-lock-out'
    }, {
      label: '3',
      id: 3,
      icon: 'assets/svg/bed-state-3',
      pos: '',
      lock: 'hiLowLock',
      class: ''
    }, {
      label: '4',
      id: 4,
      icon: 'assets/svg/bed-state-4',
      pos: 'comfortChairPosition',
      lock: 'comfortChairLock',
      headRestAngle: 55,
      footRestAngle: 30,
      centerRestAngle: -20,
      class: 'comfort-chair'
    }, {
      label: '5',
      id: 5,
      icon: 'assets/svg/bed-state-5',
      pos: 'trendPos',
      posArr: ['gravityAssist'],
      lock: 'trendLockOut',
      headRestAngle: -20,
      footRestAngle: -20,
      centerRestAngle: -21,
      class: 'trend-pos'
    }, {
      label: '6',
      id: 6,
      icon: 'assets/svg/bed-state-6',
      pos: 'rTrendPos',
      lock: 'trendLockOut',
      headRestAngle: 20,
      footRestAngle: 20,
      centerRestAngle: 20,
      class: 'rtrend-pos'
    }];
  headRestAnglePx = 0;
  isBedSummaryEnabled = true;
  isWearerSummaryEnabled = true;
  selectedBed = '';
  casparData: any = this.dashboardService.casparData;
  bedInType = BED_IN;
  bedOutType = BED_OUT;
  alertsIconOrder: { [key: string]: any } = CASPAR_ICON_ORDER;
  loading = false;
  isCasparEnabled = false;

  constructor(
    public modalController: ModalController,
    public dashboardService: DashboardService,
    private navParams: NavParams,
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private authService: AuthService,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    const selectedFacility = this.headerService.getCurrentFacility;
    this.isCasparEnabled = selectedFacility.enableCaspar;
    const { data } = this.navParams;
    this.data = data;
    this.subscriptions.push(interval(ROOM_SUMMARY_REFRESH_INTERVAL).subscribe(() => {
      this.getRoomDetails(true);
    }));
    this.subscriptions.push(
      this.dashboardService.getCasparDataSub.subscribe((data: any) => {
        this.casparData = data;
      })
    );
    this.isBedSummaryEnabled = this.authService.isFeatureEnabled('getBedDetail');
    this.isWearerSummaryEnabled = this.authService.isFeatureEnabled('getWearerDetail');
  }

  ionViewWillEnter() {
    this.getRoomDetails();
  }

  getData(data: DashboardData[]) {
    this.dashBoardDataCopy = this.dashboardService.dashboardData;
    this.dashBoardData = data;
    this.showSpinner = false;
  }

  dismiss() {
    this.uiUtilityService.dismissOverlays();
  }

  async showWearerSummary(resident: any, event: any) {
    if (!this.isWearerSummaryEnabled) {
      return;
    }
    this.dismiss();
    const wearerData = this.dashboardService.dashboardData.find(
      (wearer) => wearer.wearerId === resident.residentId
    );
    if (wearerData && wearerData.staticWearer) return;
    const summaryPopup = await this.modalController.create({
      component: DashboardWearerSummaryComponent,
      componentProps: wearerData,
      cssClass: 'dashboard-summary-modals',
      mode: 'ios',
      showBackdrop: true,
      animated: false,
    });

    await summaryPopup.present();

    if (summaryPopup) {
      await summaryPopup.onDidDismiss().then((dismissed: any) => {
        this.dashboardService.currentWearer.next(null);
      });
    }
  }

  pinFormatter(value: number) {
    return `${value}%`;
  }

  setEntityProps(roomDetails: any) {
    if (roomDetails.residents?.length) {
      for (const resident of roomDetails.residents) {
        const wearerData = this.dashboardService.dashboardData.find(
          (wearer) => wearer.wearerId === resident.residentId
        );
        resident.entityIconColor = wearerData?.entityIconColor || notableColors.offline;
        resident.displayName = wearerData?.displayName || '';
      }
    }
  }

  isBedError(bed: any) {
    return bed?.bedMetaData?.errorState && bed?.bedMetaData?.errorCode === 'bridgeOffline';
  }

  getRoomDetails(isRefresh = false) {
    const roomID = this.data.roomId;
    const type = 'Bed Room';
    this.loading = isRefresh ? false : true;
    this.settingsService.getRoomDetails(roomID, { bedInfo: true }).subscribe({
      next: (res) => {
        this.loading = false;
        if (res.data) {
          this.roomDetails = res.data;
          this.setEntityProps(this.roomDetails);
          if (this.roomDetails?.beds?.length > 0) {
            for (const bed of this.roomDetails.beds) {
              if (bed.bedMetaData) {
                const powerState = bed.bedMetaData?.powerState;
                switch (powerState) {
                  case 'OnBattery':
                    bed.bedMetaData['batteryStateIcon'] = 'assets/svg/bed-on-battery.svg';
                    break;
                  case 'BatteryNotPresent':
                    bed.bedMetaData['batteryStateIcon'] = 'assets/svg/bed-battery-not-present.svg';
                    break;
                  case 'BeingCharged':
                    bed.bedMetaData['batteryStateIcon'] = 'assets/svg/bed-battery-being-charged.svg';
                    break;
                  default:
                    bed.bedMetaData['batteryStateIcon'] = 'assets/svg/bed-battery-unknown.svg';
                }
                const residentF = this.roomDetails.residents?.find((item: any) => item.bedId === bed.id);
                if (residentF) {
                  residentF.bedInfo = bed;
                  bed.appResidentInBed = true;
                } else {
                  bed.appResidentInBed = false;
                }
              }
            }
            this.beds = this.roomDetails.beds;
          }
          if (isRefresh && this.bedData?.id) {
            if (!this.isBedError(this.bedData)) {
              this.getBedPosition(this.bedData);
            }
          } else if (this.beds?.length) {
            this.selectedBed = this.beds[0].id;
            if (!this.isBedError(this.beds[0])) {
              this.getBedPosition(this.beds[0]);
            } else {
              this.bedData = this.beds[0];
            }
          }
          if (this.roomDetails?.roomDetail?.roomTypeName == type.toString()) {
            this.commonRoom = false;
            this.subTitle = 'Bed Room';
          } else {
            this.commonRoom = true;
            this.subTitle = 'Common Room';
            if (this.roomDetails?.roomDetail?.subTypeName) {
              this.subTitle = this.subTitle + ' - ' + this.roomDetails?.roomDetail?.subTypeName;
            }
          }
        }
      }, error: () => {
        this.loading = false;
      }
    });
  }

  getBedPosition(bed: any) {
    this.bedData = bed;
    // this.headRestAnglePx = 0;
    this.dashboardService.getBedPosition(bed.id).subscribe((res) => {
      if (res.data?.bedPositionMetaData) {
        res.data['bedPositionAngle'] = { footRestAngle: 0, centerRestAngle: 0, anglePos: '' };
        res.data.bedPositionAngle.headRestAngle = res.data.bedPositionMetaData.headRestAngle || 0;
        if (res.data.bedPositionMetaData.headRestAngle === -1) {
          res.data.bedPositionAngle.headRestAngle = 0;
        }
        if (res.data.bedPositionMetaData.headRestAngle === -2) {
          res.data.bedPositionAngle.headRestAngle = 0;
        }
        if (res.data.bedPositionMetaData.kneeBentHeadFlat) {
          res.data.bedPositionAngle['footRestAngle'] = 35;
          res.data.bedPositionAngle['centerRestAngle'] = -18;
          res.data.bedPositionAngle['anglePos'] = 'knee-bent-head-flat';
        }
        const headRestAngle = res.data.bedPositionAngle.headRestAngle;
        if (res.data.bedPositionMetaData.headLiftedKneeBent) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || 30;
          res.data.bedPositionAngle['footRestAngle'] = 35;
          res.data.bedPositionAngle['centerRestAngle'] = -18;
          res.data.bedPositionAngle['anglePos'] = 'head-lift-knee-bent';
        }
        if (res.data.bedPositionMetaData.headDown) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || -20;
          res.data.bedPositionAngle['footRestAngle'] = -20;
          res.data.bedPositionAngle['centerRestAngle'] = -21;
          res.data.bedPositionAngle['anglePos'] = 'head-down';
        }
        if (res.data.bedPositionMetaData.headFlatKneeNotBent) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || 20;
          res.data.bedPositionAngle['footRestAngle'] = 20;
          res.data.bedPositionAngle['centerRestAngle'] = 20;
          res.data.bedPositionAngle['anglePos'] = 'head-flat-knee-not-bent';
        }
        if (res.data.bedPositionMetaData.feetDown && res.data.bedPositionMetaData.backFlatKneeFlat) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || 20;
          res.data.bedPositionAngle['footRestAngle'] = 20;
          res.data.bedPositionAngle['centerRestAngle'] = 20;
          res.data.bedPositionAngle['anglePos'] = 'back-flat-knee-flat';
        }
        if (res.data.bedPositionMetaData.feetDown && res.data.bedPositionMetaData.backFlatKneeBent) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || 28;
          res.data.bedPositionAngle['footRestAngle'] = 30;
          res.data.bedPositionAngle['centerRestAngle'] = -18;
          res.data.bedPositionAngle['anglePos'] = 'back-flat-knee-bent';
        }
        if (res.data.bedPositionMetaData.headDownKneeBent) {
          res.data.bedPositionAngle['headRestAngle'] = headRestAngle || -14;
          res.data.bedPositionAngle['footRestAngle'] = 10;
          res.data.bedPositionAngle['centerRestAngle'] = -35;
          res.data.bedPositionAngle['anglePos'] = 'head-down-knee-bent';
        }
        for (const bedState of this.bedStates) {
          let pos = res.data.bedPositionMetaData[bedState.pos];
          const lock = res.data.bedPositionMetaData[bedState.lock]
          if (!pos && bedState.posArr?.length) {
            for (const bedPos of bedState.posArr) {
              if (res.data.bedPositionMetaData[bedPos]) {
                pos = true;
              }
            }
          }
          let state = '';
          if (pos && lock) {
            state = '_pos_on_lock_on';
          } else if (pos && !lock) {
            state = '_pos_on_lock_off';
          } else if (!pos && lock) {
            state = '_pos_off_lock_on';
          } else {
            state = '';
          }
          res.data.bedPositionMetaData[`state_${bedState.id}`] = state;
          if (pos) {
            if (res.data.bedPositionMetaData.headRestAngle === -1) {
              res.data.bedPositionAngle.headRestAngle = bedState.headRestAngle || 0;
            }
            if (res.data.bedPositionMetaData.headRestAngle === -2) {
              res.data.bedPositionAngle.headRestAngle = bedState.headRestAngle || 0;
            }
            res.data.bedPositionAngle['footRestAngle'] = bedState.footRestAngle || 0;
            res.data.bedPositionAngle['centerRestAngle'] = bedState.centerRestAngle || 0;
            res.data.bedPositionAngle['anglePos'] = bedState.class || '';
          }
        }
        if (res.data.bedPositionAngle?.headRestAngle && !isNaN(res.data.bedPositionAngle.headRestAngle)) {
          this.headRestAnglePx = res.data.bedPositionAngle.headRestAngle * 1.5;
        } else {
          this.headRestAnglePx = 0;
        }
        this.bedData = { ...this.bedData, ...res.data };
      } else {
        this.headRestAnglePx = 0;
      }
      // else {
      //   this.bedData = this.defaultBedPositionModal;
      // }
    });
  }

  navigateToRoomDetailsPage() {
    this.dismiss();
    // const roomID = this.data.roomId;
    // this.router.navigate([RouterEnum.roomDetails, roomID]);
    const menuItem = { component: RoomDetailsPage, id: 'room-list', confirm: false, pageType: MODAL_PAGE_TYPE, modalData: { roomId: this.data.roomId, isRootPage: true } };
    this.uiUtilityService.menuClicked.next(menuItem);
  }

  handleChange(event: any) {
    this.bedData = {};
    const bed = this.beds.find((item: any) => item.id === event.detail.value);
    if (!this.isBedError(bed)) {
      this.getBedPosition(bed);
    } else {
      this.bedData = bed;
    }
  }

  navigateToBedDetailsPage(beds: any) {
    if (!this.isBedSummaryEnabled) {
      return;
    }
    this.dismiss();
    // let bedID = beds.id;
    // this.router.navigate([RouterEnum.bedDetails, bedID, 'detail']);
    const menuItem = { component: BedDetailsPage, id: 'bed-list', confirm: false, pageType: MODAL_PAGE_TYPE, modalData: { bedId: beds.id, isRootPage: true } };
    this.uiUtilityService.menuClicked.next(menuItem);
  }

  refresh() {
    this.getRoomDetails(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
