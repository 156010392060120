import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule, PopoverController } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class ContextMenuComponent {
  @Input() list: any[] = [];

  constructor(private popoverCtrl: PopoverController) {
  }

  onMenuItem(menuItem: any) {
    this.popoverCtrl.dismiss(menuItem);
  }
}