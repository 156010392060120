import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { IonicModule } from "@ionic/angular";

@Component({
  standalone: true,
  selector: 'app-map-asset',
  templateUrl: './floor-map-asset.component.html',
  styleUrls: ['../floor-dashboard.component.scss'],
  imports: [IonicModule, CommonModule],
})
export class MapAsset implements OnInit {
  @Input() locationPin: any = {};
  @Input() uid = '300';

  constructor() {

  }

  ngOnInit(): void {
  }
}