<ion-grid class="ion-no-padding wearer-group-filter">
  <ion-row class="ion-align-items-center">
    <ion-col class="ion-no-margin filters">
      <div class="filter-container">
        <div class="filter-box">
          <div class="filter-elem" *ngFor="let wgroup of wearerGroups"
            [ngClass]="{'active': wgroup.value === selectedGroup}" (click)="applyFilter(wgroup.value)">
            {{ wgroup.name }}</div>
        </div>
      </div>
      <ion-button class="filter-btn" (click)="openFilter()">
        <ion-icon slot="icon-only" src="assets/svg/filter.svg" color="secondary"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>