import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HammerModule, Title } from '@angular/platform-browser';
import {
  AnimationController,
  IonicModule,
  MenuController,
  ModalController
} from '@ionic/angular';
import { TitleEnum } from 'src/app/shared/enums/title-enum';
import { FloorDashboardComponent } from './floor-dashboard/floor-dashboard.component';
import { PopulationSummaryComponent } from './population-summary/population-summary.component';
import { WearerGroupFilterComponent } from './wearer-group-filter/wearer-group-filter.component';
import { WearerListComponent } from './wearer-list/wearer-list.component';
import { IonContent } from '@ionic/angular';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { DashboardService } from './services/dashboard.service';
import { Subscription, debounceTime, fromEvent } from 'rxjs';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { MOBILE_BREAKPOINT_WIDTH } from 'src/app/shared/config/app.constant';
import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'hammerjs';
import { animate, group, query, style, transition, trigger } from '@angular/animations';

const left = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
      optional: true,
    }),
  ]),
];

const right = [
  query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
      optional: true,
    }),
  ]),
];

@Component({
  standalone: true,
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    FloorDashboardComponent,
    PopulationSummaryComponent,
    WearerGroupFilterComponent,
    WearerListComponent,
    HeaderComponent,
    HammerModule
  ],
  animations: [
    trigger('animSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
  ]
})
export class DashboardPage implements OnInit, OnDestroy {
  drawerExpanded: boolean = false;
  propertyIcon: boolean = true;
  unionIcon: boolean = false;
  public loadWearerComponent = true;
  public loadFloorComponent = false;
  @ViewChild(IonContent)
  content!: IonContent;
  isSettingModalOpen: boolean = false;
  isMenuOpen: boolean = false;
  private subscriptions: Subscription[] = [];
  isMobile = this.uiUtilityService.isMobileBreakpoint();
  counter = 0;

  constructor(
    private title: Title,
    private dashboardService: DashboardService,
    private modalCtr: ModalController,
    private animationCtrl: AnimationController,
    private uiUtilityService: UiUtilityService,
    private headerService: HeaderService,
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.uiUtilityService.isPageModalOpen.subscribe((isOpen: boolean) => {
        this.isSettingModalOpen = isOpen;
      })
    );
    this.subscriptions.push(
      this.uiUtilityService.isMenuOpen.subscribe((isOpen: boolean) => {
        this.isMenuOpen = isOpen;
      })
    );
    this.subscriptions.push(this.headerService.getFacility().subscribe(() => {
      const facilityName = this.headerService.getFacilityName();
      this.title.setTitle(`${TitleEnum.dashboard}${facilityName ? ` | ${facilityName}` : TitleEnum.main}`);
    }));

    this.subscriptions.push(this.dashboardService.onDashboardTabsSwitch.subscribe((loadMap: boolean) => {
      if (loadMap) {
        this.loadWearer();
      } else {
        this.loadFloor();
      }
    }));

    this.subscriptions.push(
      fromEvent(window, 'resize')
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (window.innerWidth > MOBILE_BREAKPOINT_WIDTH) {
            this.loadWearerComponent = true;
          }
          this.isMobile = this.uiUtilityService.isMobileBreakpoint();
        })
    );

    setTimeout(() => {
      window.location.reload();
    }, 4 * 60 * 60 * 1000);
  }

  togglePopulationSummary(): void {
    if (!this.drawerExpanded) {
      this.openPopulationSummaryModal();
      this.drawerExpanded = !this.drawerExpanded;
    } else {
      this.uiUtilityService.dismissOverlays();
    }
  }

  onSwipeFloorDashboard(evt: any) {
    if (this.dashboardService.isFloorPlanZoomIn) {
      return;
    }
    if (evt.offsetDirection !== DIRECTION_LEFT && evt.offsetDirection !== DIRECTION_RIGHT) {
      return;
    }
    if (this.uiUtilityService.isDesktopBreakpoint()) {
      return;
    }
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'left' && this.uiUtilityService.isTabletBreakpoint()) {
      this.togglePopulationSummary();
    } else if (x === 'right' && this.uiUtilityService.isMobileBreakpoint()) {
      this.loadFloor();
      this.counter--;
    }
  }

  onSwipeWearerList(evt: any) {
    if (evt.offsetDirection !== DIRECTION_LEFT && evt.offsetDirection !== DIRECTION_RIGHT) {
      return;
    }
    evt.srcEvent.stopPropagation();
    evt.srcEvent.preventDefault();
    if (this.uiUtilityService.isDesktopBreakpoint()) {
      return;
    }
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    if (x === 'left' && this.uiUtilityService.isMobileBreakpoint()) {
      this.loadWearer();
      this.counter++;
    } else if (x === 'right') {
      this.menuCtrl.open();
    }
  }

  async openPopulationSummaryModal() {
    const enterAnimation = (baseEl: any) => {
      const root = baseEl.shadowRoot;
      const backdropAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'translateX(100%)' },
          { offset: 1, opacity: '0.99', transform: 'translateX(0)' },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(500)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: HTMLElement) => {
      return enterAnimation(baseEl).direction('reverse');
    };
    const modal = await this.modalCtr.create({
      component: PopulationSummaryComponent,
      cssClass: 'population-summary-side-modal',
      mode: 'ios',
      enterAnimation,
      leaveAnimation,
    });
    modal.present();
    modal.onDidDismiss().then(() => {
      this.drawerExpanded = false;
    });
    this.scrollToTop();
    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // this.name = data;
      console.log('yup, Modal open!');
    }
  }

  loadFloor() {
    if (this.propertyIcon) {
      return;
    }
    this.propertyIcon = true;
    this.unionIcon = false;
    this.loadFloorComponent = false;
    this.loadWearerComponent = true;
    this.isClick = !this.isClick;
    this.scrollToTop();
  }

  loadWearer() {
    if (!this.propertyIcon) {
      return;
    }
    this.unionIcon = true;
    this.propertyIcon = false;
    this.loadFloorComponent = true;
    this.loadWearerComponent = false;
    this.isClick = !this.isClick;
    this.scrollToTop();
    this.dashboardService.plotFloorDashboard.next(true);
  }

  scrollToTop() {
    this.content.scrollToTop(300);
  }
  ionViewDidEnter() {
    this.scrollToTop();
  }
  isClick: boolean = false;
  isClicked() {
    return this.isClick;
  }

  handleRefresh(event: any) {
    this.dashboardService.refreshDashboardWithData.next({
      event,
      isFloorCmp: !this.loadWearerComponent
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
