import { Injectable } from '@angular/core';
import { cloneDeep, merge } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/services/api/data.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  reportsPageChange = new Subject<any>();
  onUpdateFilter = new Subject<any>();
  reportFilterData: any = {};

  constructor(
    private dataService: DataService<any>
  ) { }

  getDailyBedGraph(params: any): Observable<any> {
    const url = `${environment.apiUrl.graph}dailyBedGraph/${FACILITY_ID_URL}`;
    return this.dataService.getData(url, params);
  }

  getTempBedGraph(params: any): Observable<any> {
    const url = `${environment.apiUrl.graph}dailyTemperatureGraph/${FACILITY_ID_URL}`;
    return this.dataService.getData(url, params);
  }

  deepMergeById(objValue: any, srcValue: any) {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      // Create a map for quick access
      const mergedArray = cloneDeep(objValue);

      srcValue.forEach(srcItem => {
        const existingItem = mergedArray.find((item: any) => item.id === srcItem.id);
        if (existingItem) {
          // Deep merge the matched objects
          merge(existingItem, srcItem);
        } else {
          // Add new objects to the array
          mergedArray.push(srcItem);
        }
      });

      return mergedArray;
    }
    return undefined; // Let Lodash handle other cases
  };
}
