export const mockDayReport = {
  generatedOn: '6:20 PM, Oct 20, 2024 (US/Pacific)',
  name: 'Montclair communities',
  timeframe: 'from Oct 30, 2024 12 AM to Oct 31, 2024 12 AM',
  overallBackAngleScore: '87%',
  overallBackAngleScoreColor: '#00A980',
  overallBackAngleDiff: '6%',
  overallBackAngleDiffState: 'OK',
  overallBackAngleDiffColor: '#00A980',
  overallBedHeightScore: '76%',
  overallBedHeightScoreColor: 'orange',
  overallBedHeightDiff: '11%',
  overallBedHeightDiffState: 'WARNING',
  overallBedHeightDiffColor: 'red',
  data: [
    {
      localId: 40408, lastName: 'Alice', firstName: 'Bob', avgTemp: 102.7, avgTempFormatted: '102.7 F', avgTempColor: 'red', avgTempState: 'WARNING', backAngleScore: 90, backAngleScoreFormatted: '90%', backAngleScoreColor: '#00A980', backAngleState: 'OK', backAngleScoreDiff: '+14%', backAngleScoreDiffColor: '#00A980', backAngleScoreDiffState: 'OK', bedHeightScore: 93, bedHeightScoreFormatted: '93%', bedHeightScoreColor: '#00A980', bedHeightState: 'OK', bedHeightScoreDiff: '+0%', bedHeightScoreDiffColor: '', bedHeightScoreDiffState: 'OK', tempTrendsGraph: {
        type: 'bar',
        category: 'bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 20, 23, 34], label: '', backgroundColor: ["#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626"], threshold: 65, thresholdFormatted: '61 F', type: 'bar' },
          {
            type: 'line',
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 20, 23, 34],
            breakpoints: [{
              value: 20,
              color: 'red',
              aggregator: '<'
            }, {
              value: 40,
              color: 'orange',
              aggregator: '>'
            }, {
              color: 'green',
              default: true
            }],
            fill: false,
            borderColor: 'transparent',
            borderWidth: 2,
            pointRadius: 0,
            label: ''
          }
        ]
      },
      backAngleGraph: {
        type: 'line',
        category: 'stepped-line',
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [23, 10, 18, 34, 50, 14, 20, 24, 30],
            label: 'Sample Data'
          }
        ],
        breakpoints: [{
          value: 20,
          color: 'red',
          aggregator: '<'
        }, {
          value: 40,
          color: 'yellow',
          aggregator: '>'
        }, {
          color: 'green',
          default: true
        }]
      },
      bedHeightGraph: {
        type: 'line',
        category: 'stepped-line',
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [23, 10, 18, 34, 50, 14, 20, 24, 30],
            label: 'Sample Data'
          }
        ],
        breakpoints: [{
          value: 20,
          color: 'red',
          aggregator: '<'
        }, {
          value: 40,
          color: 'yellow',
          aggregator: '>'
        }, {
          color: 'green',
          default: true
        }]
      }
    },
    {
      localId: 40409, lastName: 'Charlie', firstName: 'Diana', avgTemp: 99.1, avgTempFormatted: '99.1 F', avgTempColor: '#00A980', avgTempState: 'OK', backAngleScore: 65, backAngleScoreFormatted: '65%', backAngleScoreColor: 'orange', backAngleState: 'CAUTION', backAngleScoreDiff: '-22%', backAngleScoreDiffColor: 'red', backAngleScoreDiffState: 'WARNING', bedHeightScore: 43, bedHeightScoreFormatted: '43%', bedHeightScoreColor: 'red', bedHeightState: 'WARNING', bedHeightScoreDiff: '+2%', bedHeightScoreDiffColor: '#00A980', bedHeightScoreDiffState: 'OK', tempTrendsGraph: {
        type: 'bar',
        category: 'bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 20, 23, 34], label: '', backgroundColor: ["#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626", "#00A980", "#FFAA00", "#D34626"] }
        ]
      },
      backAngleGraph: {
        type: 'line',
        category: 'stepped-line',
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [23, 10, 18, 34, 50, 14, 20, 24, 30],
            label: 'Sample Data',
          }
        ],
        breakpoints: [{
          value: 20,
          color: 'red',
          aggregator: '<'
        }, {
          value: 40,
          color: 'yellow',
          aggregator: '>'
        }, {
          color: 'green',
          default: true
        }]
      },
      bedHeightGraph: {
        type: 'line',
        category: 'stepped-line',
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [23, 10, 18, 34, 50, 14, 20, 24, 30],
            label: 'Sample Data'
          }
        ],
        breakpoints: [{
          value: 20,
          color: 'red',
          aggregator: '<'
        }, {
          value: 40,
          color: 'yellow',
          aggregator: '>'
        }, {
          color: 'green',
          default: true
        }]
      }
    }
  ]
};

export const mockWeekReport = {
  generatedOn: '6:20 PM, Oct 20, 2024 (US/Pacific)',
  name: 'Montclair communities',
  timeframe: 'from Oct 30, 2024 12 AM to Oct 31, 2024 12 AM',
  overallBackAngleScore: '87%',
  overallBackAngleScoreColor: '#00A980',
  overallBackAngleDiff: '6%',
  overallBackAngleDiffState: 'OK',
  overallBackAngleDiffColor: '#00A980',
  overallBedHeightScore: '76%',
  overallBedHeightScoreColor: 'orange',
  overallBedHeightDiff: '11%',
  overallBedHeightDiffState: 'WARNING',
  overallBedHeightDiffColor: 'red',
  data: [
    {
      localId: 40408, lastName: 'Alice', firstName: 'Bob', avgTemp: 102.7, avgTempFormatted: '102.7 F', avgTempColor: 'red', avgTempState: 'WARNING', backAngleScore: 90, backAngleScoreFormatted: '90%', backAngleScoreColor: '#00A980', backAngleState: 'OK', backAngleScoreDiff: '+14%', backAngleScoreDiffColor: '#00A980', bedHeightScore: 93, bedHeightScoreFormatted: '93%', bedHeightScoreColor: '#00A980', bedHeightState: 'OK', bedHeightScoreDiff: '+0%', bedHeightScoreDiffColor: '', tempTrendsGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      backAngleGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      bedHeightGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      }
    },
    {
      localId: 40409, lastName: 'Charlie', firstName: 'Diana', avgTemp: 99.1, avgTempFormatted: '99.1 F', avgTempColor: '#00A980', avgTempState: 'OK', backAngleScore: 65, backAngleScoreFormatted: '65%', backAngleScoreColor: 'orange', backAngleState: 'CAUTION', backAngleScoreDiff: '-22%', backAngleScoreDiffColor: 'red', bedHeightScore: 43, bedHeightScoreFormatted: '43%', bedHeightScoreColor: 'red', bedHeightState: 'WARNING', bedHeightScoreDiff: '+2%', bedHeightScoreDiffColor: '#00A980', tempTrendsGraph: {
        type: 'bar',
        category: 'bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      backAngleGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      bedHeightGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      }
    }
  ]
};

export const mockMonthReport = {
  generatedOn: '6:20 PM, Oct 20, 2024 (US/Pacific)',
  name: 'Montclair communities',
  timeframe: 'from Oct 30, 2024 12 AM to Oct 31, 2024 12 AM',
  overallBackAngleScore: '87%',
  overallBackAngleScoreColor: '#00A980',
  overallBackAngleDiff: '6%',
  overallBackAngleDiffState: 'OK',
  overallBackAngleDiffColor: '#00A980',
  overallBedHeightScore: '76%',
  overallBedHeightScoreColor: 'orange',
  overallBedHeightDiff: '11%',
  overallBedHeightDiffState: 'WARNING',
  overallBedHeightDiffColor: 'red',
  data: [
    {
      localId: 40408, lastName: 'Alice', firstName: 'Bob', avgTemp: 102.7, avgTempFormatted: '102.7 F', avgTempColor: 'red', avgTempState: 'WARNING', backAngleScore: 90, backAngleScoreFormatted: '90%', backAngleScoreColor: '#00A980', backAngleState: 'OK', backAngleScoreDiff: '+14%', backAngleScoreDiffColor: '#00A980', bedHeightScore: 93, bedHeightScoreFormatted: '93%', bedHeightScoreColor: '#00A980', bedHeightState: 'OK', bedHeightScoreDiff: '+0%', bedHeightScoreDiffColor: '', tempTrendsGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      backAngleGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      bedHeightGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      }
    },
    {
      localId: 40409, lastName: 'Charlie', firstName: 'Diana', avgTemp: 99.1, avgTempFormatted: '99.1 F', avgTempColor: '#00A980', avgTempState: 'OK', backAngleScore: 65, backAngleScoreFormatted: '65%', backAngleScoreColor: 'orange', backAngleState: 'CAUTION', backAngleScoreDiff: '-22%', backAngleScoreDiffColor: 'red', bedHeightScore: 43, bedHeightScoreFormatted: '43%', bedHeightScoreColor: 'red', bedHeightState: 'WARNING', bedHeightScoreDiff: '+2%', bedHeightScoreDiffColor: '#00A980', tempTrendsGraph: {
        type: 'bar',
        category: 'bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      },
      backAngleGraph: {
        errorTitle: 'Alerts not enabled.',
        error: true
      },
      bedHeightGraph: {
        type: 'bar',
        category: 'stacked-bar',
        labels: ['1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7', '1', '2', '3', '4', '5', '6', '7'],
        datasets: [
          { data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: ["#00A980"] },
          { data: [55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40, 55, 49, 80, 71, 46, 35, 40], label: '', backgroundColor: ["#FFAA00"] },
          { data: [45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40, 45, 39, 80, 61, 36, 45, 40], label: '', backgroundColor: ["#D34626"] }
        ]
      }
    }
  ]
};